import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function OtherHearingProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Hearing Problems',
      [Language.SPANISH]: 'Otros Problemas Auditivos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscHearingProblems}
        label={{
          [Language.ENGLISH]: 'Do you currently have any other hearing or ear problem that wasn\'t covered in the questions above?',
          [Language.SPANISH]: '¿Tiene actualmente algún otro problema de audición o de oído que no se haya tratado en las preguntas anteriores?',
        }}
        onChange={setResponse('miscHearingProblems')}
        options={YES_NO_OPTIONS}
        value={responses.miscHearingProblems}
      />
      {reqs.miscHearingProblemsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscHearingProblemsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your hearing problem(s):',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su(s) problema(s) auditivo(s):',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscHearingProblemsDescription"
            onChange={setResponse('miscHearingProblemsDescription')}
            value={responses.miscHearingProblemsDescription}
          />
        </div>
      )}
    </Card>
  );
}
