import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function LungCancerBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Lung Cancer',
      [Language.SPANISH]: 'Cáncer de Pulmón',
    }}>
      <InlineRadioField<YesNo>
        error={errors.lungCancer}
        label={{
          [Language.ENGLISH]: 'Have you ever had lung cancer?',
          [Language.SPANISH]: '¿Ha padecido alguna vez cáncer de pulmón?',
        }}
        onChange={setResponse('lungCancer')}
        options={YES_NO_OPTIONS}
        value={responses.lungCancer}
      />
      {reqs.lungCancerInRemission && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.lungCancerInRemission}
            label={{
              [Language.ENGLISH]: 'Are you currently in remission?',
              [Language.SPANISH]: '¿Está actualmente en remisión?',
            }}
            onChange={setResponse('lungCancerInRemission')}
            options={YES_NO_OPTIONS}
            value={responses.lungCancerInRemission}
          />
        </div>
      )}
      {reqs.lungCancerInRemissionAtLeastFiveYears && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.lungCancerInRemissionAtLeastFiveYears}
            label={{
              [Language.ENGLISH]: 'Have you been in remission for five (5) years or more?',
              [Language.SPANISH]: '¿Ha estado en remisión durante cinco (5) años o más?',
            }}
            onChange={setResponse('lungCancerInRemissionAtLeastFiveYears')}
            options={YES_NO_OPTIONS}
            value={responses.lungCancerInRemissionAtLeastFiveYears}
          />
        </div>
      )}
      {reqs.lungCancerUsedSarAtWorkSinceRemission && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.lungCancerUsedSarAtWorkSinceRemission}
            label={{
              [Language.ENGLISH]: 'Have you used a supplied-air respirator (SAR) or an SCBA at work since your remission?',
              [Language.SPANISH]: '¿Ha utilizado un respirador con suministro de aire (SAR, por sus siglas en inglés) o un ERA en el trabajo desde su remisión?',
            }}
            onChange={setResponse('lungCancerUsedSarAtWorkSinceRemission')}
            options={YES_NO_OPTIONS}
            value={responses.lungCancerUsedSarAtWorkSinceRemission}
          />
        </div>
      )}
      {reqs.lungCancerProblemsWithSar && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.lungCancerProblemsWithSar}
            label={{
              [Language.ENGLISH]: 'Did you have any problems, especially with breathing, when you used the supplied-air respirator (SAR) or SCBA?',
              [Language.SPANISH]: '¿Tuvo algún problema, especialmente con la respiración, cuando utilizó el respirador con suministro de aire (SAR) o el ERA?',
            }}
            onChange={setResponse('lungCancerProblemsWithSar')}
            options={YES_NO_OPTIONS}
            value={responses.lungCancerProblemsWithSar}
          />
        </div>
      )}
      {reqs.lungCancerWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.lungCancerWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations because of your lung cancer history?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica debido a sus antecedentes de cáncer de pulmón?',
            }}
            onChange={setResponse('lungCancerWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.lungCancerWorkAccommodations}
          />
        </div>
      )}
      {reqs.lungCancerCurrentProblemsMakeRespiratorUseDifficult && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.lungCancerCurrentProblemsMakeRespiratorUseDifficult}
            label={{
              [Language.ENGLISH]: 'Do you have any lasting lung problems that might make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Tiene algún problema pulmonar duradero que pueda dificultarle el uso de un respirador?',
            }}
            onChange={setResponse('lungCancerCurrentProblemsMakeRespiratorUseDifficult')}
            options={YES_NO_OPTIONS}
            value={responses.lungCancerCurrentProblemsMakeRespiratorUseDifficult}
          />
        </div>
      )}
    </Card>
  );
}
