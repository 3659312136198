import { Translation } from '../../components/Translation';
import { Language } from '../../lib/general';

export function InProgress() {
  return (
    <>
      <h2 className="text-3xl font-semibold text-center text-orange-600 mb-8">
        <Translation data={{
          [Language.ENGLISH]: 'IN PROGRESS',
          [Language.SPANISH]: 'EN CURSO',
        }} />
      </h2>
      <p className="mb-4">
        <Translation data={{
          [Language.ENGLISH]: `
            Your answers are being reviewed by our Occupational Health team.
            Please allow up to one (1) business day for a result. We will email
            you at the email address you provided on this form. If you have
            questions, please contact
          `,
          [Language.SPANISH]: `
            Sus respuestas están siendo revisadas por nuestro equipo de Salud
            Laboral. Por favor, espere hasta un (1) día laborable para conocer
            el resultado. Le enviaremos un correo electrónico a la dirección que
            nos ha facilitado en este formulario. Si tiene alguna pregunta,
            póngase en contacto con
          `,
        }} />
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        <Translation data={{
          [Language.ENGLISH]: '.',
          [Language.SPANISH]: '.',
        }} />
      </p>
    </>
  );
}
