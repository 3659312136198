import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function OtherChestInjuriesSurgeriesBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Chest Injuries & Surgeries',
      [Language.SPANISH]: 'Otras Lesiones y Cirugías Torácicas',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscChestInjuriesOrSurgeries}
        label={{
          [Language.ENGLISH]: 'Have you ever had any other chest injuries or surgeries that were not covered in the questions above?',
          [Language.SPANISH]: '¿Ha tenido alguna otra lesión o cirugía torácica no contemplada en las preguntas anteriores?',
        }}
        onChange={setResponse('miscChestInjuriesOrSurgeries')}
        options={YES_NO_OPTIONS}
        value={responses.miscChestInjuriesOrSurgeries}
      />
      {reqs.miscChestInjuriesOrSurgeriesResolved && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.miscChestInjuriesOrSurgeriesResolved}
            label={{
              [Language.ENGLISH]: 'Are you completely better now from your chest injuries or surgeries?',
              [Language.SPANISH]: '¿Está ya completamente mejor de sus lesiones o cirugías torácicas?',
            }}
            onChange={setResponse('miscChestInjuriesOrSurgeriesResolved')}
            options={YES_NO_OPTIONS}
            value={responses.miscChestInjuriesOrSurgeriesResolved}
          />
        </div>
      )}
      {reqs.miscChestInjuriesOrSurgeriesCanExerciseWithoutSob && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.miscChestInjuriesOrSurgeriesCanExerciseWithoutSob}
            label={{
              [Language.ENGLISH]: 'Can you do moderately intense exercise without getting out of breath?',
              [Language.SPANISH]: '¿Puede hacer ejercicio moderadamente intenso sin quedarse sin aliento?',
            }}
            onChange={setResponse('miscChestInjuriesOrSurgeriesCanExerciseWithoutSob')}
            options={YES_NO_OPTIONS}
            value={responses.miscChestInjuriesOrSurgeriesCanExerciseWithoutSob}
          />
        </div>
      )}
      {reqs.miscChestInjuriesOrSurgeriesCanPerformWorkFully && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.miscChestInjuriesOrSurgeriesCanPerformWorkFully}
            label={{
              [Language.ENGLISH]: 'Do you think you can do your job without any problems or limitations caused by your chest injuries or surgeries?',
              [Language.SPANISH]: '¿Cree que puede realizar su trabajo sin problemas ni limitaciones causadas por sus lesiones o cirugías torácicas?',
            }}
            onChange={setResponse('miscChestInjuriesOrSurgeriesCanPerformWorkFully')}
            options={YES_NO_OPTIONS}
            value={responses.miscChestInjuriesOrSurgeriesCanPerformWorkFully}
          />
        </div>
      )}
    </Card>
  );
}
