import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function RangeOfMotionBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Range of Motion',
      [Language.SPANISH]: 'Rango de Movimiento',
    }}>
      <InlineRadioField<YesNo>
        error={errors.difficultyMovingExtremities}
        label={{
          [Language.ENGLISH]: 'Do you currently have difficulty fully moving your arms and legs?',
          [Language.SPANISH]: '¿Tiene actualmente dificultades para mover completamente los brazos y las piernas?',
        }}
        onChange={setResponse('difficultyMovingExtremities')}
        options={YES_NO_OPTIONS}
        value={responses.difficultyMovingExtremities}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.painStiffnessBendingWaist}
          label={{
            [Language.ENGLISH]: 'Do you currently have pain and stiffness when you lean forward or backward at the waist?',
            [Language.SPANISH]: '¿Tiene actualmente dolor y rigidez cuando se inclina hacia delante o hacia atrás por la cintura?',
          }}
          onChange={setResponse('painStiffnessBendingWaist')}
          options={YES_NO_OPTIONS}
          value={responses.painStiffnessBendingWaist}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.difficultyMovingHeadVertically}
          label={{
            [Language.ENGLISH]: 'Do you currently have difficulty fully moving your head up or down?',
            [Language.SPANISH]: '¿Tiene actualmente dificultades para mover completamente la cabeza hacia arriba o hacia abajo?',
          }}
          onChange={setResponse('difficultyMovingHeadVertically')}
          options={YES_NO_OPTIONS}
          value={responses.difficultyMovingHeadVertically}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.difficultyMovingHeadHorizontally}
          label={{
            [Language.ENGLISH]: 'Do you currently have difficulty fully moving your head side to side?',
            [Language.SPANISH]: '¿Tiene actualmente dificultades para mover completamente la cabeza de un lado a otro?',
          }}
          onChange={setResponse('difficultyMovingHeadHorizontally')}
          options={YES_NO_OPTIONS}
          value={responses.difficultyMovingHeadHorizontally}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.difficultyBendingKnees}
          label={{
            [Language.ENGLISH]: 'Do you currently have difficulty bending at your knees?',
            [Language.SPANISH]: '¿Tiene actualmente dificultades para doblar las rodillas?',
          }}
          onChange={setResponse('difficultyBendingKnees')}
          options={YES_NO_OPTIONS}
          value={responses.difficultyBendingKnees}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.difficultySquatting}
          label={{
            [Language.ENGLISH]: 'Do you currently have difficulty squatting to the ground?',
            [Language.SPANISH]: '¿Tiene actualmente dificultades para agacharse hasta el suelo?',
          }}
          onChange={setResponse('difficultySquatting')}
          options={YES_NO_OPTIONS}
          value={responses.difficultySquatting}
        />
      </div>
      {reqs.difficultyMovingPredictsRespiratorWillExacerbate && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.difficultyMovingPredictsRespiratorWillExacerbate}
            label={{
              [Language.ENGLISH]: 'Do you think the weight of a respirator would make any difficulties you have moving worse?',
              [Language.SPANISH]: 'Cree que el peso de un respirador empeoraría las dificultades que tiene para moverse?',
            }}
            onChange={setResponse('difficultyMovingPredictsRespiratorWillExacerbate')}
            options={YES_NO_OPTIONS}
            value={responses.difficultyMovingPredictsRespiratorWillExacerbate}
          />
        </div>
      )}
      {reqs.difficultyMovingSeverity && (
        <div className="mt-8">
          <StackedRadioField<Severity>
            error={errors.difficultyMovingSeverity}
            label={{
              [Language.ENGLISH]: 'How bad is your trouble with moving?',
              [Language.SPANISH]: '¿Hasta qué punto le cuesta moverse?',
            }}
            onChange={setResponse('difficultyMovingSeverity')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Mild: I have a little bit of difficulty moving, like feeling stiff when I get up, but it doesn\'t really stop me from doing everyday things.',
                  [Language.SPANISH]: 'Leve: Tengo un poco de dificultad para moverme, como sensación de rigidez al levantarme, pero en realidad no me impide hacer las cosas cotidianas.',
                },
                value: Severity.MILD,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Moderate: I have noticeable trouble moving, like needing more time to do things or avoiding activities that are hard for me, but I can still manage most of my daily tasks.',
                  [Language.SPANISH]: 'Moderada: Tengo problemas notables para moverme, como necesitar más tiempo para hacer las cosas o evitar actividades que me resultan difíciles, pero aún puedo manejar la mayoría de mis tareas diarias.',
                },
                value: Severity.MODERATE,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Severe: I have a lot of difficulty moving, which really limits what I can do. I might need help with some activities or can\'t do certain things at all because of my movement problems.',
                  [Language.SPANISH]: 'Grave: Tengo muchas dificultades para moverme, lo que limita mucho lo que puedo hacer. Puede que necesite ayuda con algunas actividades o que no pueda hacer ciertas cosas en absoluto debido a mis problemas de movimiento.',
                },
                value: Severity.SEVERE,
              },
            ]}
            value={responses.difficultyMovingSeverity}
          />
        </div>
      )}
    </Card>
  );
}
