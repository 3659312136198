import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { NyhaFunctionalClassification, Language, YesNo } from '../../lib/general';
import { no, yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HeartFailureBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Heart Failure',
      [Language.SPANISH]: 'Insuficiencia Cardíaca',
    }}>
      <InlineRadioField<YesNo>
        error={errors.heartFailure}
        label={{
          [Language.ENGLISH]: 'Have you ever had heart failure?',
          [Language.SPANISH]: '¿Ha tenido alguna vez insuficiencia cardíaca?',
        }}
        onChange={setResponse('heartFailure')}
        options={YES_NO_OPTIONS}
        value={responses.heartFailure}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.peripheralEdema}
          label={{
            [Language.ENGLISH]: 'Have you ever had swelling in your legs or feet (not caused by walking)?',
            [Language.SPANISH]: '¿Ha tenido alguna vez hinchazón en las piernas o los pies (no causada por caminar)?',
          }}
          onChange={setResponse('peripheralEdema')}
          options={YES_NO_OPTIONS}
          value={responses.peripheralEdema}
        />
      </div>
      {reqs.peripheralEdemaCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.peripheralEdemaCurrent}
            label={{
              [Language.ENGLISH]: 'Do your legs or feet still get swollen (edema)?',
              [Language.SPANISH]: '¿Se le siguen hinchando las piernas o los pies (edema)?',
            }}
            onChange={setResponse('peripheralEdemaCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.peripheralEdemaCurrent}
          />
        </div>
      )}
      {reqs.heartFailureFunctionalClassification && (
        <div className="mt-8">
          <StackedRadioField<NyhaFunctionalClassification>
            error={errors.heartFailureFunctionalClassification}
            label={{
              [Language.ENGLISH]: 'Which one of these options describes you best?',
              [Language.SPANISH]: '¿Cuál de estas opciones lo describe mejor?',
            }}
            onChange={setResponse('heartFailureFunctionalClassification')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t feel tired, short of breath, or have chest pain when doing normal activities like carrying an 80lb weight, working outside, playing basketball, or jogging at 5mph. I don\'t have any limits doing these things.',
                  [Language.SPANISH]: 'No siento cansancio, falta de aliento ni dolor en el pecho al realizar actividades normales como cargar un peso de 80 libras, trabajar al aire libre, jugar al baloncesto o trotar a 8 km/h. No tengo ningún límite al hacer estas cosas.',
                },
                value: NyhaFunctionalClassification.CLASS_I,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I have a little trouble with some physical activities, but I feel fine and have no symptoms when I\'m resting. I can garden, dance, and walk fast without any problems.',
                  [Language.SPANISH]: 'Tengo algunos problemas con algunas actividades físicas, pero me siento bien y no tengo síntomas cuando descanso. Puedo trabajar en el jardín, bailar y caminar rápido sin problemas.',
                },
                value: NyhaFunctionalClassification.CLASS_II,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I can\'t do some physical activities, but I\'m OK when I\'m resting. I can take a shower, do housework, and walk slowly without any trouble.',
                  [Language.SPANISH]: 'No puedo realizar algunas actividades físicas, pero estoy bien cuando descanso. Puedo ducharme, hacer las tareas domésticas y caminar despacio sin problemas.',
                },
                value: NyhaFunctionalClassification.CLASS_III,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I can\'t do any of the activities mentioned above without feeling bad, and I have symptoms even when I\'m resting.',
                  [Language.SPANISH]: 'No puedo realizar ninguna de las actividades mencionadas sin sentirme mal, y tengo síntomas incluso cuando estoy descansando.',
                },
                value: NyhaFunctionalClassification.CLASS_IV,
              },
            ]}
            value={responses.heartFailureFunctionalClassification}
          />
        </div>
      )}
      {reqs.anginaRescueMedUseLastYear && !yes(responses.heartAttack)
          && !no(responses.anginaAssessedByDoctor) && !yes(responses.anginaCardiac) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaRescueMedUseLastYear}
            label={{
              [Language.ENGLISH]: 'Have you had to take medicine for sudden chest pain, like nitroglycerin or beta blockers, in the last year?',
              [Language.SPANISH]: '¿Ha tenido que tomar medicamentos para el dolor repentino en el pecho, como nitroglicerina o betabloqueantes, en el último año?',
            }}
            onChange={setResponse('anginaRescueMedUseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.anginaRescueMedUseLastYear}
          />
        </div>
      )}
      {reqs.heartConditionAdditionalSxLast2Years && !yes(responses.heartAttack)
          && !no(responses.anginaAssessedByDoctor) && !yes(responses.anginaCardiac) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionAdditionalSxLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you ever felt chest pain, had trouble breathing or felt really tired while working?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido alguna vez dolor en el pecho, problemas para respirar o se ha sentido muy cansado mientras trabajaba?',
            }}
            onChange={setResponse('heartConditionAdditionalSxLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionAdditionalSxLast2Years}
          />
        </div>
      )}
      {reqs.heartCondition150MinutesActivityPerWeek && !yes(responses.heartAttack)
          && !no(responses.anginaAssessedByDoctor) && !yes(responses.anginaCardiac)
          && (yes(responses.heartFailure) || yes(responses.peripheralEdemaCurrent)) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartCondition150MinutesActivityPerWeek}
            label={{
              [Language.ENGLISH]: 'Do you get at least 150 minutes of physical activity per week?',
              [Language.SPANISH]: '¿Realiza al menos 150 minutos de actividad física a la semana?',
            }}
            onChange={setResponse('heartCondition150MinutesActivityPerWeek')}
            options={YES_NO_OPTIONS}
            value={responses.heartCondition150MinutesActivityPerWeek}
          />
        </div>
      )}
      {reqs.heartConditionHighCholesterol && !yes(responses.heartAttack)
          && !no(responses.anginaAssessedByDoctor) && !yes(responses.anginaCardiac)
          && (yes(responses.heartFailure) || yes(responses.peripheralEdemaCurrent)) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionHighCholesterol}
            label={{
              [Language.ENGLISH]: 'Do you have high cholesterol?',
              [Language.SPANISH]: '¿Tiene el colesterol alto?',
            }}
            onChange={setResponse('heartConditionHighCholesterol')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionHighCholesterol}
          />
        </div>
      )}
      {reqs.heartFailureWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartFailureWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your heart failure?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su insuficiencia cardíaca?',
            }}
            onChange={setResponse('heartFailureWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.heartFailureWorkAccommodations}
          />
        </div>
      )}
      {reqs.heartConditionRespiratorUseExpectedOk && !yes(responses.heartAttack)
          && !yes(responses.anginaCardiac) && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.heartConditionRespiratorUseExpectedOk}
            label={{
              [Language.ENGLISH]: 'Do you think you can use a respirator, or will it be too difficult because it makes breathing harder and you have a heart condition?',
              [Language.SPANISH]: '¿Cree que puede usar un respirador, o le resultará demasiado difícil porque le dificulta la respiración y tiene una afección cardiaca?',
            }}
            onChange={setResponse('heartConditionRespiratorUseExpectedOk')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I think I can use a respirator',
                  [Language.SPANISH]: 'Sí, creo que puedo usar un respirador',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I think using a respirator will be too difficult',
                  [Language.SPANISH]: 'No, creo que usar un respirador será demasiado difícil',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.heartConditionRespiratorUseExpectedOk}
          />
        </div>
      )}
    </Card>
  );
}
