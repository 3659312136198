import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HeartMurmurBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Heart Murmur',
      [Language.SPANISH]: 'Soplo Cardíaco',
    }}>
      <InlineRadioField<YesNo>
        error={errors.heartMurmur}
        label={{
          [Language.ENGLISH]: 'Do you have a heart murmur?',
          [Language.SPANISH]: '¿Tiene un soplo cardíaco?',
        }}
        onChange={setResponse('heartMurmur')}
        options={YES_NO_OPTIONS}
        value={responses.heartMurmur}
      />
      {reqs.heartMurmurCheckedByDoctor && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartMurmurCheckedByDoctor}
            label={{
              [Language.ENGLISH]: 'Has a doctor checked your heart murmur?',
              [Language.SPANISH]: '¿Le ha examinado un médico su soplo cardíaco?',
            }}
            onChange={setResponse('heartMurmurCheckedByDoctor')}
            options={YES_NO_OPTIONS}
            value={responses.heartMurmurCheckedByDoctor}
          />
        </div>
      )}
      {reqs.heartMurmurClearedByDoctor && (
        <div className="mt-8">
          <StackedRadioField<YesNoUnknown>
            error={errors.heartMurmurClearedByDoctor}
            label={{
              [Language.ENGLISH]: 'Did the doctor tell you whether your heart murmur was harmless (innocent)?',
              [Language.SPANISH]: '¿Le dijo el médico si su soplo cardíaco era inofensivo (inocente)?',
            }}
            onChange={setResponse('heartMurmurClearedByDoctor')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'The doctor said my heart murmur was harmless and it doesn\'t need more check-ups',
                  [Language.SPANISH]: 'El médico dijo que mi soplo cardíaco era inofensivo y que no necesita más revisiones',
                },
                value: YesNoUnknown.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'The doctor said my heart murmur was serious or it needs more check-ups',
                  [Language.SPANISH]: 'El médico dijo que mi soplo cardíaco era grave o que necesitaba más revisiones',
                },
                value: YesNoUnknown.NO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Neither of these / I don\'t remember',
                  [Language.SPANISH]: 'Ninguno de los dos / No me acuerdo',
                },
                value: YesNoUnknown.UNKNOWN,
              },
            ]}
            value={responses.heartMurmurClearedByDoctor}
          />
        </div>
      )}
      {reqs.heartMurmurAdditionalSx && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartMurmurAdditionalSx}
            label={{
              [Language.ENGLISH]: 'Does your heart murmur cause any other heart symptoms, like chest pain, heart fluttering, trouble breathing, or feeling like your heart misses beats?',
              [Language.SPANISH]: '¿Causa su soplo cardíaco algún otro síntoma cardíaco, como dolor en el pecho, aleteo cardíaco, dificultad para respirar o sensación de que su corazón pierde latidos?',
            }}
            onChange={setResponse('heartMurmurAdditionalSx')}
            options={YES_NO_OPTIONS}
            value={responses.heartMurmurAdditionalSx}
          />
        </div>
      )}
    </Card>
  );
}
