import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function OtherMusculoskeletalProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Musculoskeletal Problems',
      [Language.SPANISH]: 'Otros Problemas Musculoesqueléticos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscMusculoskeletalProblems}
        label={{
          [Language.ENGLISH]: 'Do you currently have any other muscle or skeletal problem that would interfere with using a respirator?',
          [Language.SPANISH]: '¿Tiene actualmente algún otro problema muscular o esquelético que interfiera con el uso de un respirador?',
        }}
        onChange={setResponse('miscMusculoskeletalProblems')}
        options={YES_NO_OPTIONS}
        value={responses.miscMusculoskeletalProblems}
      />
      {reqs.miscMusculoskeletalProblemsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscMusculoskeletalProblemsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your muscle or skeletal problem(s):',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su(s) problema(s) muscular(es) o esquelético(s):',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscMusculoskeletalProblemsDescription"
            onChange={setResponse('miscMusculoskeletalProblemsDescription')}
            value={responses.miscMusculoskeletalProblemsDescription}
          />
        </div>
      )}
    </Card>
  );
}
