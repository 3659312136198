import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function OtherVisionProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Vision Problems',
      [Language.SPANISH]: 'Otros Problemas de Visión',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscVisionProblems}
        label={{
          [Language.ENGLISH]: 'Do you currently have any other eye or vision problem that hasn\'t been covered already?',
          [Language.SPANISH]: '¿Tiene actualmente algún otro problema ocular o de visión que no se haya cubierto ya?',
        }}
        onChange={setResponse('miscVisionProblems')}
        options={YES_NO_OPTIONS}
        value={responses.miscVisionProblems}
      />
      {reqs.miscVisionProblemsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscVisionProblemsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your eye or vision problem(s):',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su(s) problema(s) ocular(es) o de visión:',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscVisionProblemsDescription"
            onChange={setResponse('miscVisionProblemsDescription')}
            value={responses.miscVisionProblemsDescription}
          />
        </div>
      )}
    </Card>
  );
}
