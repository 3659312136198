import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function VisionCorrectionBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Vision Correction',
      [Language.SPANISH]: 'Corrección Visual',
    }}>
      <InlineRadioField<YesNo>
        error={errors.visionCorrection}
        label={{
          [Language.ENGLISH]: 'Do you currently wear glasses or contact lenses?',
          [Language.SPANISH]: '¿Usa actualmente lentes de ver o lentes de contacto?',
        }}
        onChange={setResponse('visionCorrection')}
        options={YES_NO_OPTIONS}
        value={responses.visionCorrection}
      />
      {reqs.visionCorrectionCanWearAndSeeAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.visionCorrectionCanWearAndSeeAtWork}
            label={{
              [Language.ENGLISH]: 'Can you see well enough to do your job and if needed are you able to wear your glasses or contact lenses at work?',
              [Language.SPANISH]: '¿Puede ver lo suficientemente bien como para realizar su trabajo y, si es necesario, puede llevar sus lentes de ver o lentes de contacto en el trabajo?',
            }}
            onChange={setResponse('visionCorrectionCanWearAndSeeAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.visionCorrectionCanWearAndSeeAtWork}
          />
        </div>
      )}
    </Card>
  );
}
