import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function ChronicBronchitisBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Chronic Bronchitis',
      [Language.SPANISH]: 'Bronquitis Crónica',
    }}>
      <InlineRadioField<YesNo>
        error={errors.chronicBronchitis}
        label={{
          [Language.ENGLISH]: 'Have you ever had chronic bronchitis?',
          [Language.SPANISH]: '¿Ha padecido alguna vez bronquitis crónica?',
        }}
        onChange={setResponse('chronicBronchitis')}
        options={YES_NO_OPTIONS}
        value={responses.chronicBronchitis}
      />
      {reqs.chronicBronchitisSxDuringAdl && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisSxDuringAdl}
            label={{
              [Language.ENGLISH]: 'Does your chronic bronchitis bother you when you do everyday things like chores, exercising, or walking up stairs?',
              [Language.SPANISH]: '¿Le molesta la bronquitis crónica cuando hace cosas cotidianas como tareas domésticas, hacer ejercicio o subir escaleras?',
            }}
            onChange={setResponse('chronicBronchitisSxDuringAdl')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisSxDuringAdl}
          />
        </div>
      )}
      {reqs.chronicBronchitisWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your chronic bronchitis gotten worse in the last year?',
              [Language.SPANISH]: '¿Ha empeorado su bronquitis crónica en el último año?',
            }}
            onChange={setResponse('chronicBronchitisWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisWorseLastYear}
          />
        </div>
      )}
      {reqs.chronicBronchitisSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.chronicBronchitisSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your chronic bronchitis is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es su bronquitis crónica?',
            }}
            onChange={setResponse('chronicBronchitisSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.chronicBronchitisSeverity}
          />
        </div>
      )}
      {reqs.chronicBronchitisPriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisPriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your chronic bronchitis ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez su bronquitis crónica le ha dificultado el uso de un respirador?',
            }}
            onChange={setResponse('chronicBronchitisPriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisPriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.chronicBronchitisFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your chronic bronchitis will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su bronquitis crónica le dificultará el uso de un respirador?',
            }}
            onChange={setResponse('chronicBronchitisFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.chronicBronchitisWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your chronic bronchitis?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su bronquitis crónica?',
            }}
            onChange={setResponse('chronicBronchitisWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisWorkAccommodations}
          />
        </div>
      )}
      {reqs.chronicBronchitisMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('chronicBronchitisMedications')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisMedications}
          />
        </div>
      )}
      {reqs.chronicBronchitisMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('chronicBronchitisMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.chronicBronchitisHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your chronic bronchitis?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de su bronquitis crónica?',
            }}
            onChange={setResponse('chronicBronchitisHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.chronicBronchitisImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.chronicBronchitisImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your chronic bronchitis?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de su bronquitis crónica?',
            }}
            onChange={setResponse('chronicBronchitisImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.chronicBronchitisImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
