import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import { yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HeartAttackBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Heart Attacks',
      [Language.SPANISH]: 'Infartos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.heartAttack}
        label={{
          [Language.ENGLISH]: 'Have you ever had a heart attack?',
          [Language.SPANISH]: '¿Alguna vez ha tenido un infarto (ataque del corazón)?',
        }}
        onChange={setResponse('heartAttack')}
        options={YES_NO_OPTIONS}
        value={responses.heartAttack}
      />
      {reqs.heartAttackLessThan3YearsAgo && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartAttackLessThan3YearsAgo}
            label={{
              [Language.ENGLISH]: 'Was your most recent heart attack less than three (3) years ago?',
              [Language.SPANISH]: '¿Su infarto más reciente se produjo hace menos de tres (3) años?',
            }}
            onChange={setResponse('heartAttackLessThan3YearsAgo')}
            options={YES_NO_OPTIONS}
            value={responses.heartAttackLessThan3YearsAgo}
          />
        </div>
      )}
      {reqs.heartAttackCardiacHospitalVisitLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartAttackCardiacHospitalVisitLastYear}
            label={{
              [Language.ENGLISH]: 'Have you gone to the hospital or had to stay there because of heart problems in the last year?',
              [Language.SPANISH]: '¿Ha ido al hospital o ha tenido que permanecer en él por problemas cardíacos en el último año?',
            }}
            onChange={setResponse('heartAttackCardiacHospitalVisitLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.heartAttackCardiacHospitalVisitLastYear}
          />
        </div>
      )}
      {reqs.anginaRescueMedUseLastYear && yes(responses.heartAttack) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaRescueMedUseLastYear}
            label={{
              [Language.ENGLISH]: 'Have you had to take medicine for sudden chest pain, like nitroglycerin or beta blockers, in the last year?',
              [Language.SPANISH]: '¿Ha tenido que tomar medicamentos para el dolor repentino en el pecho, como nitroglicerina o betabloqueantes, en el último año?',
            }}
            onChange={setResponse('anginaRescueMedUseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.anginaRescueMedUseLastYear}
          />
        </div>
      )}
      {reqs.heartConditionAdditionalSxLast2Years && yes(responses.heartAttack) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionAdditionalSxLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you ever felt chest pain, had trouble breathing, or felt really tired while working?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido alguna vez dolor en el pecho, problemas para respirar o se ha sentido muy cansado mientras trabajaba?',
            }}
            onChange={setResponse('heartConditionAdditionalSxLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionAdditionalSxLast2Years}
          />
        </div>
      )}
      {reqs.heartCondition150MinutesActivityPerWeek && yes(responses.heartAttack) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartCondition150MinutesActivityPerWeek}
            label={{
              [Language.ENGLISH]: 'Do you get at least 150 minutes of physical activity per week?',
              [Language.SPANISH]: '¿Realiza al menos 150 minutos de actividad física a la semana?',
            }}
            onChange={setResponse('heartCondition150MinutesActivityPerWeek')}
            options={YES_NO_OPTIONS}
            value={responses.heartCondition150MinutesActivityPerWeek}
          />
        </div>
      )}
      {reqs.heartConditionHighCholesterol && yes(responses.heartAttack) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionHighCholesterol}
            label={{
              [Language.ENGLISH]: 'Do you have high cholesterol?',
              [Language.SPANISH]: '¿Tiene el colesterol alto?',
            }}
            onChange={setResponse('heartConditionHighCholesterol')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionHighCholesterol}
          />
        </div>
      )}
      {reqs.heartAttackCoronaryArteryProcedureLast6Months && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartAttackCoronaryArteryProcedureLast6Months}
            label={{
              [Language.ENGLISH]: 'Have you had any coronary artery bypass graft (CABG) or percutaneous transluminal coronary angioplasty (PTCA) in the last six (6) months?',
              [Language.SPANISH]: '¿Se ha sometido a un bypass coronario (CABG, por sus siglas en inglés) o a una angioplastia coronaria transluminal percutánea (PTCA, por sus siglas en inglés) en los últimos seis (6) meses?',
            }}
            onChange={setResponse('heartAttackCoronaryArteryProcedureLast6Months')}
            options={YES_NO_OPTIONS}
            value={responses.heartAttackCoronaryArteryProcedureLast6Months}
          />
        </div>
      )}
      {reqs.heartAttackRestrictionsRemoved && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartAttackRestrictionsRemoved}
            label={{
              [Language.ENGLISH]: 'Has your doctor told you that you\'re cleared to do all kinds of physical activity and return to work without restrictions?',
              [Language.SPANISH]: '¿Le ha dicho su médico que puede realizar todo tipo de actividad física y volver al trabajo sin restricciones?',
            }}
            onChange={setResponse('heartAttackRestrictionsRemoved')}
            options={YES_NO_OPTIONS}
            value={responses.heartAttackRestrictionsRemoved}
          />
        </div>
      )}
      {reqs.heartAttackWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartAttackWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your past heart attack(s)?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su(s) infarto(s) pasado(s)?',
            }}
            onChange={setResponse('heartAttackWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.heartAttackWorkAccommodations}
          />
        </div>
      )}
      {reqs.heartAttackHadStressTest && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartAttackHadStressTest}
            label={{
              [Language.ENGLISH]: 'Since your heart attack, have you had an exercise (treadmill or bike) stress test?',
              [Language.SPANISH]: 'Desde su infarto, ¿se ha sometido a una prueba de esfuerzo (cinta rodante o bicicleta)?',
            }}
            onChange={setResponse('heartAttackHadStressTest')}
            options={YES_NO_OPTIONS}
            value={responses.heartAttackHadStressTest}
          />
        </div>
      )}
      {reqs.heartAttackStressTestFailed && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.heartAttackStressTestFailed}
            label={{
              [Language.ENGLISH]: 'During your exercise stress test, did they have to stop it early because you felt really tired, had chest pain, or had any heart problems?',
              [Language.SPANISH]: 'Durante su prueba de esfuerzo, ¿tuvieron que pararla antes de tiempo porque se sentía muy cansaoa, tenía dolor en el pecho o algún problema cardíaco?',
            }}
            onChange={setResponse('heartAttackStressTestFailed')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I had to stop the test early',
                  [Language.SPANISH]: 'Sí, tuve que detener la prueba antes de tiempo',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I completed the whole test',
                  [Language.SPANISH]: 'No, completé toda la prueba',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.heartAttackStressTestFailed}
          />
        </div>
      )}
      {reqs.heartAttackStressTestShowedArrhythmiaOrIschemia && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.heartAttackStressTestShowedArrhythmiaOrIschemia}
            label={{
              [Language.ENGLISH]: 'Did your exercise stress test show signs of arrhythmia or ischemia (not enough blood flow to the heart)?',
              [Language.SPANISH]: '¿Su prueba de esfuerzo mostró signos de arritmia o isquemia (insuficiente flujo sanguíneo al corazón)?',
            }}
            onChange={setResponse('heartAttackStressTestShowedArrhythmiaOrIschemia')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, my stress test showed signs of arrhythmia or ischemia',
                  [Language.SPANISH]: 'Sí, mi prueba de esfuerzo mostró signos de arritmia o isquemia',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, my stress test was normal',
                  [Language.SPANISH]: 'No, mi prueba de esfuerzo fue normal',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.heartAttackStressTestShowedArrhythmiaOrIschemia}
          />
        </div>
      )}
      {reqs.heartConditionRespiratorUseExpectedOk && yes(responses.heartAttack) && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.heartConditionRespiratorUseExpectedOk}
            label={{
              [Language.ENGLISH]: 'Do you think you can use a respirator, or will it be too difficult because it makes breathing harder and you have a heart condition?',
              [Language.SPANISH]: '¿Cree que puede usar un respirador, o le resultará demasiado difícil porque le dificulta la respiración y tiene una afección cardiaca?',
            }}
            onChange={setResponse('heartConditionRespiratorUseExpectedOk')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I think I can use a respirator',
                  [Language.SPANISH]: 'Sí, creo que puedo usar un respirador',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I think using a respirator will be too difficult',
                  [Language.SPANISH]: 'No, creo que usar un respirador será demasiado difícil',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.heartConditionRespiratorUseExpectedOk}
          />
        </div>
      )}
    </Card>
  );
}
