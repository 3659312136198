import { createContext } from 'react';

import { Language, QuestionnaireStage } from '../lib/general';
import { AppInitializer, RespiratorMedicalEvaluationResult } from '../types/api';
import { HealthHistoryStageResponses } from '../types/general';

type AppContext = {
  accessCode: string;
  drawerContent: 'attestation' | 'tou' | 'privacy' | null;
  initializer: AppInitializer;
  language: Language;
  questionnairePrn: string;
  responses: HealthHistoryStageResponses;
  result: RespiratorMedicalEvaluationResult | null;
  setDrawerContent: (_: 'attestation' | 'tou' | 'privacy' | null) => void;
  setLanguage: (_: Language) => void;
  setQuestionnairePrn: (_: string) => void;
  setResponses: (_: HealthHistoryStageResponses) => void;
  setResult: (_: RespiratorMedicalEvaluationResult) => void;
  setStage: (_: QuestionnaireStage) => void;
  setSubmitting: (_: boolean) => void;
  stage: QuestionnaireStage;
  submitting: boolean;
};

export const appContext = createContext<AppContext>({
  accessCode: '',
  drawerContent: null,
  initializer: {} as AppInitializer,
  language: Language.ENGLISH,
  questionnairePrn: '',
  responses: {} as HealthHistoryStageResponses,
  result: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerContent() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLanguage() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setQuestionnairePrn() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setResponses() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setResult() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStage() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSubmitting() {},
  stage: QuestionnaireStage.START,
  submitting: false,
});
