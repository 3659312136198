import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, PriorRespiratorUseProblemCause, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS, YES_NO_UNKNOWN_OPTIONS } from './options';

export function PriorRespiratorUseSkinProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Skin Problems',
      [Language.SPANISH]: 'Problemas de Piel',
    }}>
      <InlineRadioField<YesNo>
        error={errors.priorRespiratorUseSkinProblems}
        label={{
          [Language.ENGLISH]: 'Has using a respirator ever caused you skin rashes or allergies?',
          [Language.SPANISH]: '¿El uso de un respirador le ha provocado alguna vez erupciones cutáneas o alergias?',
        }}
        onChange={setResponse('priorRespiratorUseSkinProblems')}
        options={YES_NO_OPTIONS}
        value={responses.priorRespiratorUseSkinProblems}
      />
      {reqs.priorRespiratorUseSkinProblemsCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseSkinProblemsCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still get a rash when you use a respirator?',
              [Language.SPANISH]: '¿Le siguen saliendo erupciones cutáneas cuando utiliza un respirador?',
            }}
            onChange={setResponse('priorRespiratorUseSkinProblemsCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseSkinProblemsCurrent}
          />
        </div>
      )}
      {reqs.priorRespiratorUseSkinProblemsCause && (
        <div className="mt-8">
          <StackedRadioField<PriorRespiratorUseProblemCause>
            error={errors.priorRespiratorUseSkinProblemsCause}
            label={{
              [Language.ENGLISH]: 'What was the cause of your rash or allergy?',
              [Language.SPANISH]: '¿Cuál fue la causa de sus erupciones cutáneas o alergias?',
            }}
            onChange={setResponse('priorRespiratorUseSkinProblemsCause')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'The respirator itself',
                  [Language.SPANISH]: 'El propio respirador',
                },
                value: PriorRespiratorUseProblemCause.RESPIRATOR,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Something at my work',
                  [Language.SPANISH]: 'Algo en mi trabajo',
                },
                value: PriorRespiratorUseProblemCause.WORK_ENVIRONMENT,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t know',
                  [Language.SPANISH]: 'No sé',
                },
                value: PriorRespiratorUseProblemCause.UNKNOWN,
              },
            ]}
            value={responses.priorRespiratorUseSkinProblemsCause}
          />
        </div>
      )}
      {reqs.priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator && (
        <div className="mt-8">
          <StackedRadioField<YesNoUnknown>
            error={errors.priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator}
            label={{
              [Language.ENGLISH]: 'In your current job, are you able to use a style of respirator that does not cause you any skin problems?',
              [Language.SPANISH]: 'En su trabajo actual, ¿puede utilizar un estilo de respirador que no le cause ningunos problemas de piel?',
            }}
            onChange={setResponse('priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I can use a respirator that is comfortable and does not cause skin problems',
                  [Language.SPANISH]: 'Sí, puedo utilizar un respirador que me resulte cómodo y no me cause problemas de piel',
                },
                value: YesNoUnknown.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, the only respirator I can use gives me skin problems',
                  [Language.SPANISH]: 'No, el único respirador que puedo utilizar me causa problemas de piel',
                },
                value: YesNoUnknown.NO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t know if the respirators I will use at my job will cause me skin problems',
                  [Language.SPANISH]: 'No sé si los respiradores que utilizaré en mi trabajo me causarán problemas de piel',
                },
                value: YesNoUnknown.UNKNOWN,
              },
            ]}
            value={responses.priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator}
          />
        </div>
      )}
      {reqs.priorRespiratorUseSkinProblemsAggravatorsAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.priorRespiratorUseSkinProblemsAggravatorsAtWork}
            label={{
              [Language.ENGLISH]: 'Will you still be working around the thing that causes your rash or allergy?',
              [Language.SPANISH]: '¿Seguirá trabajando alrededor de la cosa que le causa erupciones cutáneas o alergias?',
            }}
            onChange={setResponse('priorRespiratorUseSkinProblemsAggravatorsAtWork')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.priorRespiratorUseSkinProblemsAggravatorsAtWork}
          />
        </div>
      )}
      {reqs.priorRespiratorUseSkinProblemsCanUsePpe && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.priorRespiratorUseSkinProblemsCanUsePpe}
            label={{
              [Language.ENGLISH]: 'At your job, are you able to prevent your skin rashes by wearing protective gear like gloves or coveralls?',
              [Language.SPANISH]: 'En su trabajo, ¿puede prevenir las erupciones cutáneas utilizando equipos de protección como guantes o cubre todos?',
            }}
            onChange={setResponse('priorRespiratorUseSkinProblemsCanUsePpe')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.priorRespiratorUseSkinProblemsCanUsePpe}
          />
        </div>
      )}
    </Card>
  );
}
