import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function SilicosisBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Silicosis',
      [Language.SPANISH]: 'Silicosis',
    }}>
      <InlineRadioField<YesNo>
        error={errors.silicosis}
        label={{
          [Language.ENGLISH]: 'Have you ever had silicosis (the lung disease caused by silica)?',
          [Language.SPANISH]: '¿Ha padecido alguna vez silicosis (la enfermedad pulmonar causada por la sílice)?',
        }}
        onChange={setResponse('silicosis')}
        options={YES_NO_OPTIONS}
        value={responses.silicosis}
      />
      {reqs.silicosisSxDuringAdl && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisSxDuringAdl}
            label={{
              [Language.ENGLISH]: 'Does your silicosis bother you when you do everyday things like chores, exercising, or walking up stairs?',
              [Language.SPANISH]: '¿Le molesta la silicosis cuando hace cosas cotidianas como tareas domésticas, hacer ejercicio o subir escaleras?',
            }}
            onChange={setResponse('silicosisSxDuringAdl')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisSxDuringAdl}
          />
        </div>
      )}
      {reqs.silicosisWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your silicosis gotten worse in the last year?',
              [Language.SPANISH]: '¿Ha empeorado su silicosis en el último año?',
            }}
            onChange={setResponse('silicosisWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisWorseLastYear}
          />
        </div>
      )}
      {reqs.silicosisSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.silicosisSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your silicosis is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es su silicosis?',
            }}
            onChange={setResponse('silicosisSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.silicosisSeverity}
          />
        </div>
      )}
      {reqs.silicosisPriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisPriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your silicosis ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez su silicosis le ha dificultado el uso de un respirador?',
            }}
            onChange={setResponse('silicosisPriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisPriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.silicosisFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your silicosis will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su silicosis le dificultará el uso de un respirador?',
            }}
            onChange={setResponse('silicosisFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.silicosisWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your silicosis?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su silicosis?',
            }}
            onChange={setResponse('silicosisWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisWorkAccommodations}
          />
        </div>
      )}
      {reqs.silicosisMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('silicosisMedications')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisMedications}
          />
        </div>
      )}
      {reqs.silicosisMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('silicosisMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.silicosisHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your silicosis?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de su silicosis?',
            }}
            onChange={setResponse('silicosisHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.silicosisImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.silicosisImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your silicosis?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de su silicosis?',
            }}
            onChange={setResponse('silicosisImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.silicosisImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
