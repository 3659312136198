import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function ColorBlindnessBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Color Blindness',
      [Language.SPANISH]: 'Daltonismo',
    }}>
      <InlineRadioField<YesNo>
        error={errors.colorBlindness}
        label={{
          [Language.ENGLISH]: 'Are you color blind?',
          [Language.SPANISH]: '¿Es usted daltónico?',
        }}
        onChange={setResponse('colorBlindness')}
        options={YES_NO_OPTIONS}
        value={responses.colorBlindness}
      />
      {reqs.colorBlindnessHasTroubleSeeingWarnings && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.colorBlindnessHasTroubleSeeingWarnings}
            label={{
              [Language.ENGLISH]: 'Does being color blind make it hard for you to see dangers or warning signs?',
              [Language.SPANISH]: '¿Ser daltónico le dificulta ver peligros o señales de advertencia?',
            }}
            onChange={setResponse('colorBlindnessHasTroubleSeeingWarnings')}
            options={YES_NO_OPTIONS}
            value={responses.colorBlindnessHasTroubleSeeingWarnings}
          />
        </div>
      )}
      {reqs.colorBlindnessWillHaveHelpSelectingColorCodedParts && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.colorBlindnessWillHaveHelpSelectingColorCodedParts}
            label={{
              [Language.ENGLISH]: 'If you need help picking the right color-coded parts for your respirator (like colored cartridges) at work, would there be someone there to help you?',
              [Language.SPANISH]: 'Si necesita ayuda para elegir las piezas correctas codificadas por colores para su respirador (como cartuchos de colores) en el trabajo, ¿habría alguien allí para ayudarle?',
            }}
            onChange={setResponse('colorBlindnessWillHaveHelpSelectingColorCodedParts')}
            options={YES_NO_OPTIONS}
            value={responses.colorBlindnessWillHaveHelpSelectingColorCodedParts}
          />
        </div>
      )}
    </Card>
  );
}
