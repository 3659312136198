import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function AsbestosisBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Asbestosis',
      [Language.SPANISH]: 'Asbestosis',
    }}>
      <InlineRadioField<YesNo>
        error={errors.asbestosis}
        label={{
          [Language.ENGLISH]: 'Have you ever had asbestosis (the lung disease caused by asbestos fibers)?',
          [Language.SPANISH]: '¿Ha padecido alguna vez asbestosis (la enfermedad pulmonar causada por las fibras de amianto)?',
        }}
        onChange={setResponse('asbestosis')}
        options={YES_NO_OPTIONS}
        value={responses.asbestosis}
      />
      {reqs.asbestosisSxDuringAdl && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisSxDuringAdl}
            label={{
              [Language.ENGLISH]: 'Does your asbestosis bother you when you do everyday things like chores, exercising, or walking up stairs?',
              [Language.SPANISH]: '¿Le molesta la asbestosis cuando hace cosas cotidianas como tareas domésticas, hacer ejercicio o subir escaleras?',
            }}
            onChange={setResponse('asbestosisSxDuringAdl')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisSxDuringAdl}
          />
        </div>
      )}
      {reqs.asbestosisWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your asbestosis gotten worse in the last year?',
              [Language.SPANISH]: '¿Ha empeorado su asbestosis en el último año?',
            }}
            onChange={setResponse('asbestosisWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisWorseLastYear}
          />
        </div>
      )}
      {reqs.asbestosisSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.asbestosisSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your asbestosis is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es su asbestosis?',
            }}
            onChange={setResponse('asbestosisSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.asbestosisSeverity}
          />
        </div>
      )}
      {reqs.asbestosisPriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisPriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your asbestosis ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez su asbestosis le ha dificultado el uso de un respirador?',
            }}
            onChange={setResponse('asbestosisPriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisPriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.asbestosisFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your asbestosis will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su asbestosis le dificultará el uso de un respirador?',
            }}
            onChange={setResponse('asbestosisFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.asbestosisWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your asbestosis?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su asbestosis?',
            }}
            onChange={setResponse('asbestosisWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisWorkAccommodations}
          />
        </div>
      )}
      {reqs.asbestosisMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('asbestosisMedications')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisMedications}
          />
        </div>
      )}
      {reqs.asbestosisMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('asbestosisMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.asbestosisHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your asbestosis?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de su asbestosis?',
            }}
            onChange={setResponse('asbestosisHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.asbestosisImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asbestosisImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your asbestosis?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de su asbestosis?',
            }}
            onChange={setResponse('asbestosisImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.asbestosisImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
