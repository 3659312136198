import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function OtherHeartProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Heart Problems',
      [Language.SPANISH]: 'Otros Problemas Cardiacos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscHeartProblems}
        label={{
          [Language.ENGLISH]: 'Have you ever had any other heart problems that you\'ve been told about that were not covered in the questions above?',
          [Language.SPANISH]: '¿Ha tenido algún otro problema cardíaco del que le hayan hablado y que no se haya incluido en las preguntas anteriores?',
        }}
        onChange={setResponse('miscHeartProblems')}
        options={YES_NO_OPTIONS}
        value={responses.miscHeartProblems}
      />
      {reqs.miscHeartProblemsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscHeartProblemsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your heart problem(s):',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su(s) problema(s) cardiaco(s):',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscHeartProblemsDescription"
            onChange={setResponse('miscHeartProblemsDescription')}
            value={responses.miscHeartProblemsDescription}
          />
        </div>
      )}
    </Card>
  );
}
