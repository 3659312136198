import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

import { Card } from '../../components/Card';
import { Translation } from '../../components/Translation';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import { yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function SpeakToProviderBlock({
  errors,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Contact Us',
      [Language.SPANISH]: 'Contáctenos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.speakToProvider}
        label={{
          [Language.ENGLISH]: 'Would you like to talk to the health care professional who will review this questionnaire about your answers?',
          [Language.SPANISH]: '¿Le gustaría hablar con el profesional médico que revisará este cuestionario sobre sus respuestas?',
        }}
        onChange={setResponse('speakToProvider')}
        options={YES_NO_OPTIONS}
        value={responses.speakToProvider}
      />
      {yes(responses.speakToProvider) && (
        <div className="mt-8 flex flex-row items-start gap-4">
          <div className="w-6">
            <ChevronDoubleRightIcon className="h-6 w-6" />
          </div>
          
          <div>
            <Translation data={{
              [Language.ENGLISH]: 'Please contact ',
              [Language.SPANISH]: 'Póngase en contacto con ',
            }} />
            <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
            <Translation data={{
              [Language.ENGLISH]: ' with any questions or to arrange an interview with a health care provider.',
              [Language.SPANISH]: ' si tiene alguna pregunta o para concertar una entrevista con un proveedor de atención médica.',
            }} />
          </div>
        </div>
      )}
    </Card>
  );
}
