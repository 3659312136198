import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function DiabetesBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Diabetes',
      [Language.SPANISH]: 'Diabetes',
    }}>
      <InlineRadioField<YesNo>
        error={errors.diabetes}
        label={{
          [Language.ENGLISH]: 'Have you ever had diabetes?',
          [Language.SPANISH]: '¿Ha padecido alguna vez diabetes?',
        }}
        onChange={setResponse('diabetes')}
        options={YES_NO_OPTIONS}
        value={responses.diabetes}
      />
      {reqs.diabetesCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.diabetesCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still have diabetes?',
              [Language.SPANISH]: '¿Sigues teniendo diabetes?',
            }}
            onChange={setResponse('diabetesCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.diabetesCurrent}
          />
        </div>
      )}
      {reqs.diabetesWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.diabetesWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your diabetes?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su diabetes?',
            }}
            onChange={setResponse('diabetesWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.diabetesWorkAccommodations}
          />
        </div>
      )}
      {reqs.diabetesMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.diabetesMedications}
            label={{
              [Language.ENGLISH]: 'Do you take insulin or other medicines that can lower your blood sugar?',
              [Language.SPANISH]: '¿Toma insulina u otros medicamentos que puedan reducir su nivel de azúcar en la sangre?',
            }}
            onChange={setResponse('diabetesMedications')}
            options={YES_NO_OPTIONS}
            value={responses.diabetesMedications}
          />
        </div>
      )}
      {reqs.diabetesSyncopeAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.diabetesSyncopeAtWork}
            label={{
              [Language.ENGLISH]: 'Have you ever felt faint, really confused, or passed out at work because your blood sugar was too low?',
              [Language.SPANISH]: '¿Alguna vez se ha sentido débil, muy confuso o se ha desmayado en el trabajo porque su nivel de azúcar en la sangre era demasiado bajo?',
            }}
            onChange={setResponse('diabetesSyncopeAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.diabetesSyncopeAtWork}
          />
        </div>
      )}
      {reqs.diabetesWarningSigns && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.diabetesWarningSigns}
            label={{
              [Language.ENGLISH]: 'Can you tell when your blood sugar is getting low and get yourself to a safe place to eat before you feel faint or confused?',
              [Language.SPANISH]: '¿Puede darse cuenta de cuándo le está bajando el azúcar en la sangre y trasladarse a un lugar seguro para comer antes de sentirse débil o confuso?',
            }}
            onChange={setResponse('diabetesWarningSigns')}
            options={YES_NO_OPTIONS}
            value={responses.diabetesWarningSigns}
          />
        </div>
      )}
    </Card>
  );
}
