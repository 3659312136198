import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, TimeFrame, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function SeizuresBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Seizures',
      [Language.SPANISH]: 'Convulsiones',
    }}>
      <InlineRadioField<YesNo>
        error={errors.seizures}
        label={{
          [Language.ENGLISH]: 'Have you ever had a seizure?',
          [Language.SPANISH]: '¿Ha tenido alguna vez un convulsión epiléptica (ataque)?',
        }}
        onChange={setResponse('seizures')}
        options={YES_NO_OPTIONS}
        value={responses.seizures}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.seizuresMedications}
          label={{
            [Language.ENGLISH]: 'Are you currently taking any medications for seizures?',
            [Language.SPANISH]: '¿Toma algún medicamento para convulciones epilépticas (ataques)?',
          }}
          onChange={setResponse('seizuresMedications')}
          options={YES_NO_OPTIONS}
          value={responses.seizuresMedications}
        />
      </div>
      {reqs.seizuresMedicationsAdverseEffects && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresMedicationsAdverseEffects}
            label={{
              [Language.ENGLISH]: 'Do your seizure medicines make you feel sleepy, mixed up, tired, or not able to think, or stop you from doing your job safely?',
              [Language.SPANISH]: '¿Sus medicamentos anticonvulsivos lo hacen sentir somnoliento, confuso, cansado o incapaz de pensar, o le impiden realizar su trabajo con seguridad?',
            }}
            onChange={setResponse('seizuresMedicationsAdverseEffects')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresMedicationsAdverseEffects}
          />
        </div>
      )}
      {reqs.seizuresWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your seizures?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por sus convulciones?',
            }}
            onChange={setResponse('seizuresWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresWorkAccommodations}
          />
        </div>
      )}
      {reqs.seizuresMostRecent && (
        <div className="mt-8">
          <StackedRadioField<TimeFrame>
            error={errors.seizuresMostRecent}
            label={{
              [Language.ENGLISH]: 'How long ago was your last seizure?',
              [Language.SPANISH]: '¿Cuánto hace de su último convulsión?',
            }}
            onChange={setResponse('seizuresMostRecent')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Less than 5 years ago',
                  [Language.SPANISH]: 'Hace menos de 5 años',
                },
                value: TimeFrame.LESS_THAN_FIVE_YEARS_AGO,
              },
              {
                label: {
                  [Language.ENGLISH]: '5 to 10 years ago',
                  [Language.SPANISH]: 'Hace de 5 a 10 años',
                },
                value: TimeFrame.FIVE_TO_TEN_YEARS_AGO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'More than 10 years ago',
                  [Language.SPANISH]: 'Hace más de 10 años',
                },
                value: TimeFrame.MORE_THAN_TEN_YEARS_AGO,
              },
            ]}
            value={responses.seizuresMostRecent}
          />
        </div>
      )}
      {reqs.seizuresMultiple && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresMultiple}
            label={{
              [Language.ENGLISH]: 'Have you had more than one (1) seizure in your life?',
              [Language.SPANISH]: '¿Ha tenido más de una (1) convulsión en su vida?',
            }}
            onChange={setResponse('seizuresMultiple')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresMultiple}
          />
        </div>
      )}
      {reqs.seizuresHadAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresHadAtWork}
            label={{
              [Language.ENGLISH]: 'Have you ever had a seizure at work?',
              [Language.SPANISH]: '¿Ha tenido alguna vez una convulsión en el trabajo?',
            }}
            onChange={setResponse('seizuresHadAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresHadAtWork}
          />
        </div>
      )}
      {reqs.seizuresWarningSigns && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresWarningSigns}
            label={{
              [Language.ENGLISH]: 'Do you get any warnings or signs before a seizure that let you know it\'s coming?',
              [Language.SPANISH]: '¿Recibe alguna advertencia o señal antes de una convulsión que le haga saber que se acerca?',
            }}
            onChange={setResponse('seizuresWarningSigns')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresWarningSigns}
          />
        </div>
      )}
      {reqs.seizuresWorksAlone && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresWorksAlone}
            label={{
              [Language.ENGLISH]: 'Will you be working by yourself (meaning, if you had a seizure, no one would be there to help)?',
              [Language.SPANISH]: '¿Estaría usted trabajando solo (es decir, si tuviera una convulsión, nadie estaría allí para ayudarle)?',
            }}
            onChange={setResponse('seizuresWorksAlone')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresWorksAlone}
          />
        </div>
      )}
      {reqs.seizuresControlled && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.seizuresControlled}
            label={{
              [Language.ENGLISH]: 'Do you and your doctors feel your seizures are controlled?',
              [Language.SPANISH]: '¿Usted y sus médicos consideran que sus convulsiones están controladas?',
            }}
            onChange={setResponse('seizuresControlled')}
            options={YES_NO_OPTIONS}
            value={responses.seizuresControlled}
          />
        </div>
      )}
    </Card>
  );
}
