import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function BackProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Back Problems',
      [Language.SPANISH]: 'Problemas de Espalda',
    }}>
      <InlineRadioField<YesNo>
        error={errors.backInjury}
        label={{
          [Language.ENGLISH]: 'Have you ever had a back injury?',
          [Language.SPANISH]: '¿Ha tenido alguna vez una lesión de espalda?',
        }}
        onChange={setResponse('backInjury')}
        options={YES_NO_OPTIONS}
        value={responses.backInjury}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.backPain}
          label={{
            [Language.ENGLISH]: 'Do you have back pain now or do you get it often?',
            [Language.SPANISH]: '¿Tiene dolor de espalda ahora o lo padece a menudo?',
          }}
          onChange={setResponse('backPain')}
          options={YES_NO_OPTIONS}
          value={responses.backPain}
        />
      </div>
      {reqs.backInjuryResolved && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.backInjuryResolved}
            label={{
              [Language.ENGLISH]: 'Are you totally better from your back injury, or do you still occasionally have some pain or trouble doing things?',
              [Language.SPANISH]: '¿Está totalmente mejor de su lesión de espalda, o sigue teniendo ocasionalmente algún dolor o problemas para hacer cosas?',
            }}
            onChange={setResponse('backInjuryResolved')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I\'m totally better from my back injury',
                  [Language.SPANISH]: 'Sí, estoy totalmente mejor de mi lesión de espalda',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I still have some pain or problems from my back injury',
                  [Language.SPANISH]: 'No, todavía tengo algunos dolores o problemas de mi lesión de espalda',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.backInjuryResolved}
          />
        </div>
      )}
      {reqs.backPainPredictsRespiratorWillExacerbate && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.backPainPredictsRespiratorWillExacerbate}
            label={{
              [Language.ENGLISH]: 'Do you think the weight of a respirator would make your back pain worse?',
              [Language.SPANISH]: '¿Cree que el peso de un respirador empeoraría su dolor de espalda?',
            }}
            onChange={setResponse('backPainPredictsRespiratorWillExacerbate')}
            options={YES_NO_OPTIONS}
            value={responses.backPainPredictsRespiratorWillExacerbate}
          />
        </div>
      )}
    </Card>
  );
}
