import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS, YES_NO_UNKNOWN_OPTIONS } from './options';

export function AllergiesBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Allergies',
      [Language.SPANISH]: 'Alergias',
    }}>
      <InlineRadioField<YesNo>
        error={errors.allergies}
        label={{
          [Language.ENGLISH]: 'Have you ever had an allergic reaction that interfered with your breathing?',
          [Language.SPANISH]: '¿Ha tenido alguna vez una reacción alérgica que interfiriera con su respiración?',
        }}
        onChange={setResponse('allergies')}
        options={YES_NO_OPTIONS}
        value={responses.allergies}
      />
      {reqs.allergiesAnaphylaxis && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesAnaphylaxis}
            label={{
              [Language.ENGLISH]: 'Have you ever had a really bad allergic reaction called anaphylaxis, or gone to the hospital because you had trouble breathing from an allergy?',
              [Language.SPANISH]: '¿Ha tenido alguna vez una reacción alérgica realmente grave, llamada anafilaxia, o ha ido al hospital porque tenía problemas para respirar a causa de una alergia?',
            }}
            onChange={setResponse('allergiesAnaphylaxis')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesAnaphylaxis}
          />
        </div>
      )}
      {reqs.allergiesMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any medicines for your allergies?',
              [Language.SPANISH]: '¿Toma algún medicamento para sus alergias?',
            }}
            onChange={setResponse('allergiesMedications')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesMedications}
          />
        </div>
      )}
      {reqs.allergiesMedicationsAdverseEffects && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesMedicationsAdverseEffects}
            label={{
              [Language.ENGLISH]: 'Do your allergy medicines make you feel sleepy, mixed up, tired, or not able to think clearly?',
              [Language.SPANISH]: '¿Sus medicamentos para la alergia lo hacen sentir somnoliento, confuso, cansado o no puede pensar con claridad?',
            }}
            onChange={setResponse('allergiesMedicationsAdverseEffects')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesMedicationsAdverseEffects}
          />
        </div>
      )}
      {reqs.allergiesPrescribedEpipen && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesPrescribedEpipen}
            label={{
              [Language.ENGLISH]: 'Have you been given a prescription for an EpiPen®?',
              [Language.SPANISH]: '¿Le han recetado un EpiPen®?',
            }}
            onChange={setResponse('allergiesPrescribedEpipen')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesPrescribedEpipen}
          />
        </div>
      )}
      {reqs.allergiesKnowsTriggers && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesKnowsTriggers}
            label={{
              [Language.ENGLISH]: 'Do you know what you are allergic to that causes your bad allergic reactions or breathing problems?',
              [Language.SPANISH]: '¿Sabe a qué es alérgico que le provoca sus malas reacciones alérgicas o problemas respiratorios?',
            }}
            onChange={setResponse('allergiesKnowsTriggers')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesKnowsTriggers}
          />
        </div>
      )}
      {reqs.allergiesHadAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesHadAtWork}
            label={{
              [Language.ENGLISH]: 'Have you ever had an allergic reaction at work?',
              [Language.SPANISH]: '¿Ha tenido alguna vez una reacción alérgica en el trabajo?',
            }}
            onChange={setResponse('allergiesHadAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesHadAtWork}
          />
        </div>
      )}
      {reqs.allergiesTriggersAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.allergiesTriggersAtWork}
            label={{
              [Language.ENGLISH]: 'In your current job, will you work around the cause of your allergic reaction?',
              [Language.SPANISH]: 'En su trabajo actual, ¿trabajará alrededor de la causa de su reacción alérgica?',
            }}
            onChange={setResponse('allergiesTriggersAtWork')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.allergiesTriggersAtWork}
          />
        </div>
      )}
      {reqs.allergiesAtWorkEasilyTreatable && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.allergiesAtWorkEasilyTreatable}
            label={{
              [Language.ENGLISH]: `If you had an allergic reaction at work, could you easily get away and use your medicine${responses.allergiesPrescribedEpipen === YesNo.YES ? ', like your EpiPen®' : ''}?`,
              [Language.SPANISH]: `Si tuviera una reacción alérgica en el trabajo, ¿podría salir de allí con facilidad y usar su medicamento${responses.allergiesPrescribedEpipen === YesNo.YES ? ', como su EpiPen®' : ''}?`,
            }}
            onChange={setResponse('allergiesAtWorkEasilyTreatable')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.allergiesAtWorkEasilyTreatable}
          />
        </div>
      )}
      {reqs.allergiesMakesRespiratorUseUnsafe && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.allergiesMakesRespiratorUseUnsafe}
            label={{
              [Language.ENGLISH]: 'Do you think your allergies will make it hard for you to use a respirator safely?',
              [Language.SPANISH]: '¿Cree que sus alergias le dificultarán el uso seguro de un respirador?',
            }}
            onChange={setResponse('allergiesMakesRespiratorUseUnsafe')}
            options={YES_NO_OPTIONS}
            value={responses.allergiesMakesRespiratorUseUnsafe}
          />
        </div>
      )}
    </Card>
  );
}
