import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HearingAidsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Hearing Aids',
      [Language.SPANISH]: 'Prótesis Auditivas',
    }}>
      <InlineRadioField<YesNo>
        error={errors.hearingAids}
        label={{
          [Language.ENGLISH]: 'Do you currently wear a hearing aid?',
          [Language.SPANISH]: '¿Lleva actualmente una prótesis auditiva?',
        }}
        onChange={setResponse('hearingAids')}
        options={YES_NO_OPTIONS}
        value={responses.hearingAids}
      />
      {reqs.hearingAidsNeedsForWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hearingAidsNeedsForWork}
            label={{
              [Language.ENGLISH]: 'Do you need a hearing aid to hear well enough for your job, to speak with coworkers, and to hear sounds that signal danger?',
              [Language.SPANISH]: '¿Necesita una prótesis auditiva para oír lo suficientemente bien para su trabajo, para hablar con sus compañeros y para oír los sonidos que indican peligro?',
            }}
            onChange={setResponse('hearingAidsNeedsForWork')}
            options={YES_NO_OPTIONS}
            value={responses.hearingAidsNeedsForWork}
          />
        </div>
      )}
      {reqs.hearingAidsCanUseAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hearingAidsCanUseAtWork}
            label={{
              [Language.ENGLISH]: 'Are you allowed to wear your hearing aid(s) at work?',
              [Language.SPANISH]: '¿Se le permite llevar su(s) prótesis auditiva(s) en el trabajo?',
            }}
            onChange={setResponse('hearingAidsCanUseAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.hearingAidsCanUseAtWork}
          />
        </div>
      )}
    </Card>
  );
}
