import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function PneumoniaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Pneumonia',
      [Language.SPANISH]: 'Neumonía',
    }}>
      <InlineRadioField<YesNo>
        error={errors.pneumonia}
        label={{
          [Language.ENGLISH]: 'Have you ever had pneumonia?',
          [Language.SPANISH]: '¿Ha padecido alguna vez neumonía?',
        }}
        onChange={setResponse('pneumonia')}
        options={YES_NO_OPTIONS}
        value={responses.pneumonia}
      />
      {reqs.pneumoniaResolved && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.pneumoniaResolved}
            label={{
              [Language.ENGLISH]: 'Is your pneumonia completely gone?',
              [Language.SPANISH]: '¿Ha desaparecido por completo su neumonía?',
            }}
            onChange={setResponse('pneumoniaResolved')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, my pneumonia is completely gone and I have no symptoms',
                  [Language.SPANISH]: 'Sí, mi neumonía ha desaparecido por completo y no tengo síntomas',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I still have some symptoms from my pneumonia',
                  [Language.SPANISH]: 'No, todavía tengo algunos síntomas de mi neumonía',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.pneumoniaResolved}
          />
        </div>
      )}
    </Card>
  );
}
