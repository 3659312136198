import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import { yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function SobBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Shortness of Breath',
      [Language.SPANISH]: 'Falta de Aliento',
    }}>
      <InlineRadioField<YesNo>
        error={errors.sob}
        label={{
          [Language.ENGLISH]: 'Do you ever get short of breath?',
          [Language.SPANISH]: '¿Alguna vez le falta el aire?',
        }}
        onChange={setResponse('sob')}
        options={YES_NO_OPTIONS}
        value={responses.sob}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.sobWalkingFastOrIncline}
          label={{
            [Language.ENGLISH]: 'Do you get short of breath when walking fast on level ground or walking up a slight hill or incline?',
            [Language.SPANISH]: '¿Le falta el aliento cuando camina rápido por terreno llano o cuando sube una ligera colina o pendiente?',
          }}
          onChange={setResponse('sobWalkingFastOrIncline')}
          options={YES_NO_OPTIONS}
          value={responses.sobWalkingFastOrIncline}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.sobWalkingWithOthers}
          label={{
            [Language.ENGLISH]: 'Do you get short of breath when walking with other people at an ordinary pace on level ground?',
            [Language.SPANISH]: '¿Le falta el aliento cuando camina con otras personas a paso normal por terreno llano?',
          }}
          onChange={setResponse('sobWalkingWithOthers')}
          options={YES_NO_OPTIONS}
          value={responses.sobWalkingWithOthers}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.sobWalkingOwnPaceLevel}
          label={{
            [Language.ENGLISH]: 'Do you get short of breath when walking at your own pace on level ground?',
            [Language.SPANISH]: '¿Le falta el aliento cuando camina a su propio ritmo por terreno llano?',
          }}
          onChange={setResponse('sobWalkingOwnPaceLevel')}
          options={YES_NO_OPTIONS}
          value={responses.sobWalkingOwnPaceLevel}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.sobWashingOrDressing}
          label={{
            [Language.ENGLISH]: 'Do you get short of breath when showering or dressing yourself?',
            [Language.SPANISH]: '¿Le falta el aire al ducharse o vestirse?',
          }}
          onChange={setResponse('sobWashingOrDressing')}
          options={YES_NO_OPTIONS}
          value={responses.sobWashingOrDressing}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.sobInterferingWithWork}
          label={{
            [Language.ENGLISH]: 'Do you sometimes get so short of breath that it\'s hard to do your job?',
            [Language.SPANISH]: '¿Le falta a veces tanto el aliento que le resulta difícil hacer su trabajo?',
          }}
          onChange={setResponse('sobInterferingWithWork')}
          options={YES_NO_OPTIONS}
          value={responses.sobInterferingWithWork}
        />
      </div>
      {reqs.sobWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your shortness of breath gotten worse in the last year?',
              [Language.SPANISH]: '¿Ha empeorado su falta de aliento en el último año?',
            }}
            onChange={setResponse('sobWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.sobWorseLastYear}
          />
        </div>
      )}
      {reqs.sobSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.sobSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your shortness of breath is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es su falta de aliento?',
            }}
            onChange={setResponse('sobSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.sobSeverity}
          />
        </div>
      )}
      {reqs.sobPriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobPriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your shortness of breath ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez su falta de aliento le ha dificultado el uso de un respirador?',
            }}
            onChange={setResponse('sobPriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.sobPriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.sobFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your shortness of breath will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su falta de aliento le dificultará el uso de un respirador?',
            }}
            onChange={setResponse('sobFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.sobFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.sobWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your shortness of breath?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su falta de aliento?',
            }}
            onChange={setResponse('sobWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.sobWorkAccommodations}
          />
        </div>
      )}
      {reqs.sobMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('sobMedications')}
            options={YES_NO_OPTIONS}
            value={responses.sobMedications}
          />
        </div>
      )}
      {reqs.rescueInhalerUseAtLeast3PerWeek && !yes(responses.asthmaMedications) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.rescueInhalerUseAtLeast3PerWeek}
            label={{
              [Language.ENGLISH]: 'Do you use your rescue inhaler three (3) or more times per week?',
              [Language.SPANISH]: '¿Utiliza su inhalador de rescate tres (3) o más veces por semana?',
            }}
            onChange={setResponse('rescueInhalerUseAtLeast3PerWeek')}
            options={YES_NO_OPTIONS}
            value={responses.rescueInhalerUseAtLeast3PerWeek}
          />
        </div>
      )}
      {reqs.sobMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('sobMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.sobMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.sobHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your shortness of breath?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de su falta de aliento?',
            }}
            onChange={setResponse('sobHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.sobHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.sobImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.sobImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your shortness of breath?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de su falta de aliento?',
            }}
            onChange={setResponse('sobImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.sobImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
