import { InitializeQuestionnaireResponse } from '../../types/api';
import { PiiStageResponses } from '../../types/general';

export async function initializeQuestionnaire({
  accessCode,
  responses,
}: {
  accessCode: string;
  responses: PiiStageResponses;
}): Promise<InitializeQuestionnaireResponse> {
  const {
    location,
    ...employeeFields
  } = responses;

  const result = await fetch(
    `${import.meta.env.VITE_API_BASE_URL}/questionnaires/initialize`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        employee: employeeFields,
        accessCode: { accessCode },
        location,
      }),
    },
  );

  return await result.json() as InitializeQuestionnaireResponse;
}
