import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function TuberculosisBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Tuberculosis',
      [Language.SPANISH]: 'Tuberculosis',
    }}>
      <InlineRadioField<YesNo>
        error={errors.tuberculosis}
        label={{
          [Language.ENGLISH]: 'Have you ever had tuberculosis?',
          [Language.SPANISH]: '¿Ha padecido alguna vez tuberculosis?',
        }}
        onChange={setResponse('tuberculosis')}
        options={YES_NO_OPTIONS}
        value={responses.tuberculosis}
      />
      {reqs.tuberculosisResolved && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.tuberculosisResolved}
            label={{
              [Language.ENGLISH]: 'Is your tuberculosis resolved and are you feeling OK without any symptoms?',
              [Language.SPANISH]: '¿Ha desaparecido por completo tu tuberculosis y se siente bien sin ningún síntoma?',
            }}
            onChange={setResponse('tuberculosisResolved')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, my tuberculosis is resolved and I have no symptoms',
                  [Language.SPANISH]: 'Sí, mi tuberculosis ha desaparecido por completo y no tengo síntomas',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, my tuberculosis is not resolved and/or I still have symptoms',
                  [Language.SPANISH]: 'No, mi tuberculosis no ha desaparecido y/o todavía tengo algunos síntomas',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.tuberculosisResolved}
          />
        </div>
      )}
      {reqs.tuberculosisCurrentMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.tuberculosisCurrentMedications}
            label={{
              [Language.ENGLISH]: 'Are you still taking any medicines for your tuberculosis?',
              [Language.SPANISH]: '¿Sigue tomando algún medicamento para la tuberculosis?',
            }}
            onChange={setResponse('tuberculosisCurrentMedications')}
            options={YES_NO_OPTIONS}
            value={responses.tuberculosisCurrentMedications}
          />
        </div>
      )}
      {reqs.tuberculosisCurrentMedicationsForLatentTbOnly && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.tuberculosisCurrentMedicationsForLatentTbOnly}
            label={{
              [Language.ENGLISH]: 'Is the medicine you\'re taking only for inactive tuberculosis (latent TB)?',
              [Language.SPANISH]: '¿El medicamento que está tomando es sólo para la tuberculosis inactiva (tuberculosis latente)?',
            }}
            onChange={setResponse('tuberculosisCurrentMedicationsForLatentTbOnly')}
            options={YES_NO_OPTIONS}
            value={responses.tuberculosisCurrentMedicationsForLatentTbOnly}
          />
        </div>
      )}
    </Card>
  );
}
