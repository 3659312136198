import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function BrokenRibsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Broken Ribs',
      [Language.SPANISH]: 'Costillas Rotas',
    }}>
      <InlineRadioField<YesNo>
        error={errors.brokenRibs}
        label={{
          [Language.ENGLISH]: 'Have you ever had a broken rib?',
          [Language.SPANISH]: '¿Alguna vez se ha roto una costilla?',
        }}
        onChange={setResponse('brokenRibs')}
        options={YES_NO_OPTIONS}
        value={responses.brokenRibs}
      />
      {reqs.brokenRibsResolved && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.brokenRibsResolved}
            label={{
              [Language.ENGLISH]: 'Are you completely better now from your broken rib(s)?',
              [Language.SPANISH]: '¿Está ya completamente mejor de su(s) costilla(s) rota(s)?',
            }}
            onChange={setResponse('brokenRibsResolved')}
            options={YES_NO_OPTIONS}
            value={responses.brokenRibsResolved}
          />
        </div>
      )}
      {reqs.brokenRibsCanExerciseWithoutSob && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.brokenRibsCanExerciseWithoutSob}
            label={{
              [Language.ENGLISH]: 'Can you do moderately intense exercise without getting out of breath?',
              [Language.SPANISH]: '¿Puede hacer ejercicio moderadamente intenso sin quedarse sin aliento?',
            }}
            onChange={setResponse('brokenRibsCanExerciseWithoutSob')}
            options={YES_NO_OPTIONS}
            value={responses.brokenRibsCanExerciseWithoutSob}
          />
        </div>
      )}
      {reqs.brokenRibsCanPerformWorkFully && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.brokenRibsCanPerformWorkFully}
            label={{
              [Language.ENGLISH]: 'Do you think you can do your job without any problems or limitations caused by your broken rib(s)?',
              [Language.SPANISH]: '¿Cree que puede realizar su trabajo sin ningún problema o limitación causada por su(s) costilla(s) rota(s)?',
            }}
            onChange={setResponse('brokenRibsCanPerformWorkFully')}
            options={YES_NO_OPTIONS}
            value={responses.brokenRibsCanPerformWorkFully}
          />
        </div>
      )}
    </Card>
  );
}
