import { TranslationData } from '../types/general';

import { Translation } from './Translation';

export function FormLabel({
  hasError,
  id,
  label,
}: {
  hasError: boolean;
  id?: string;
  label: TranslationData;
}) {
  return (
    <label htmlFor={id} className={`
      ${hasError ? 'text-red-600 has-error' : 'text-gray-900'}
      block mb-2 font-medium leading-6
    `}>
      <Translation data={label} />
    </label>
  );
}
