import { Card } from '../../components/Card';
import { FormLabel } from '../../components/FormLabel';
import { Translation } from '../../components/Translation';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { SelectField } from '../../components/inputs/SelectField';
import { Language, YesNo } from '../../lib/general';
import { no, yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HypertensionBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Hypertension',
      [Language.SPANISH]: 'Hipertensión',
    }}>
      <InlineRadioField<YesNo>
        error={errors.hypertension}
        label={{
          [Language.ENGLISH]: 'Have you ever had high blood pressure (hypertension)?',
          [Language.SPANISH]: '¿Ha tenido alguna vez la tensión arterial alta (hipertensión)?',
        }}
        onChange={setResponse('hypertension')}
        options={YES_NO_OPTIONS}
        value={responses.hypertension}
      />
      {reqs.hypertensionCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still have high blood pressure (hypertension)?',
              [Language.SPANISH]: '¿Sigue teniendo la hipertensión?',
            }}
            onChange={setResponse('hypertensionCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionCurrent}
          />
        </div>
      )}
      {reqs.hypertensionMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionMedications}
            label={{
              [Language.ENGLISH]: 'Are you taking any medicines for high blood pressure?',
              [Language.SPANISH]: '¿Está tomando algún medicamento para la hipertensión?',
            }}
            onChange={setResponse('hypertensionMedications')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionMedications}
          />
        </div>
      )}
      {reqs.hypertensionMedicationsAdverseEffects && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionMedicationsAdverseEffects}
            label={{
              [Language.ENGLISH]: 'Do your medicines make you feel dizzy, faint, sleepy, or confused, or stop you from doing your job safely?',
              [Language.SPANISH]: '¿Sus medicamentos lo hacen sentirse mareado, débil, somnoliento o confuso, o le impiden realizar su trabajo con seguridad?',
            }}
            onChange={setResponse('hypertensionMedicationsAdverseEffects')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionMedicationsAdverseEffects}
          />
        </div>
      )}
      {(reqs.hypertensionTypicalBpSystolic || reqs.hypertensionTypicalBpDiastolic) && (
        <div className="mt-8">
          <FormLabel
            hasError={errors.hypertensionTypicalBpSystolic !== undefined || errors.hypertensionTypicalBpDiastolic !== undefined}
            label={{
              [Language.ENGLISH]: 'What are your usual blood pressure numbers?',
              [Language.SPANISH]: '¿Cuáles son sus cifras habituales de tensión arterial?',
            }}
          />
          <div className="text-gray-500 text-sm mb-2">
            <Translation data={{
              [Language.ENGLISH]: 'Enter your best understanding of a general average of your blood pressure numbers when you take them at home or a doctor\'s office. For example, if your blood pressure is usually about 145/95, enter 145 for "Systolic" and 95 for "Diastolic."',
              [Language.SPANISH]: 'Introduzca la media general de sus cifras de tensión arterial cuando se las toma en casa o en la consulta del médico. Por ejemplo, si su tensión arterial suele ser de unos 145/95, introduzca 145 para "Sistólica" y 95 para "Diastólica".',
            }} />
          </div>
          <div className="flex flex-row gap-x-3">
              <SelectField
                className="min-w-24"
                error={errors.hypertensionTypicalBpSystolic}
                onChange={setResponse('hypertensionTypicalBpSystolic')}
                options={[
                  {
                    label: {
                      [Language.ENGLISH]: '90',
                      [Language.SPANISH]: '90',
                    },
                    value: 90,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '95',
                      [Language.SPANISH]: '95',
                    },
                    value: 95,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '100',
                      [Language.SPANISH]: '100',
                    },
                    value: 100,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '105',
                      [Language.SPANISH]: '105',
                    },
                    value: 105,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '110',
                      [Language.SPANISH]: '110',
                    },
                    value: 110,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '115',
                      [Language.SPANISH]: '115',
                    },
                    value: 115,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '120',
                      [Language.SPANISH]: '120',
                    },
                    value: 120,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '125',
                      [Language.SPANISH]: '125',
                    },
                    value: 125,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '130',
                      [Language.SPANISH]: '130',
                    },
                    value: 130,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '135',
                      [Language.SPANISH]: '135',
                    },
                    value: 135,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '140',
                      [Language.SPANISH]: '140',
                    },
                    value: 140,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '145',
                      [Language.SPANISH]: '145',
                    },
                    value: 145,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '150',
                      [Language.SPANISH]: '150',
                    },
                    value: 150,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '155',
                      [Language.SPANISH]: '155',
                    },
                    value: 155,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '160',
                      [Language.SPANISH]: '160',
                    },
                    value: 160,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '165',
                      [Language.SPANISH]: '165',
                    },
                    value: 165,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '170',
                      [Language.SPANISH]: '170',
                    },
                    value: 170,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '175',
                      [Language.SPANISH]: '175',
                    },
                    value: 175,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '180',
                      [Language.SPANISH]: '180',
                    },
                    value: 180,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '185',
                      [Language.SPANISH]: '185',
                    },
                    value: 185,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '190',
                      [Language.SPANISH]: '190',
                    },
                    value: 190,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '195',
                      [Language.SPANISH]: '195',
                    },
                    value: 195,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '200',
                      [Language.SPANISH]: '200',
                    },
                    value: 200,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '205',
                      [Language.SPANISH]: '205',
                    },
                    value: 205,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '210',
                      [Language.SPANISH]: '210',
                    },
                    value: 210,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '215',
                      [Language.SPANISH]: '215',
                    },
                    value: 215,
                  },
                  {
                    label: {
                      [Language.ENGLISH]: '220',
                      [Language.SPANISH]: '220',
                    },
                    value: 220,
                  },
                ]}
                sublabel={{
                  [Language.ENGLISH]: 'Systolic',
                  [Language.SPANISH]: 'Sistólica',
                }}
                value={responses.hypertensionTypicalBpSystolic}
              />
            <span className="text-2xl">/</span>
            <SelectField
              className="min-w-24"
              error={errors.hypertensionTypicalBpDiastolic}
              onChange={setResponse('hypertensionTypicalBpDiastolic')}
              options={[
                {
                  label: {
                    [Language.ENGLISH]: '60',
                    [Language.SPANISH]: '60',
                  },
                  value: 60,
                },
                {
                  label: {
                    [Language.ENGLISH]: '65',
                    [Language.SPANISH]: '65',
                  },
                  value: 65,
                },
                {
                  label: {
                    [Language.ENGLISH]: '70',
                    [Language.SPANISH]: '70',
                  },
                  value: 70,
                },
                {
                  label: {
                    [Language.ENGLISH]: '75',
                    [Language.SPANISH]: '75',
                  },
                  value: 75,
                },
                {
                  label: {
                    [Language.ENGLISH]: '80',
                    [Language.SPANISH]: '80',
                  },
                  value: 80,
                },
                {
                  label: {
                    [Language.ENGLISH]: '85',
                    [Language.SPANISH]: '85',
                  },
                  value: 85,
                },
                {
                  label: {
                    [Language.ENGLISH]: '90',
                    [Language.SPANISH]: '90',
                  },
                  value: 90,
                },
                {
                  label: {
                    [Language.ENGLISH]: '95',
                    [Language.SPANISH]: '95',
                  },
                  value: 95,
                },
                {
                  label: {
                    [Language.ENGLISH]: '100',
                    [Language.SPANISH]: '100',
                  },
                  value: 100,
                },
                {
                  label: {
                    [Language.ENGLISH]: '105',
                    [Language.SPANISH]: '105',
                  },
                  value: 105,
                },
                {
                  label: {
                    [Language.ENGLISH]: '110',
                    [Language.SPANISH]: '110',
                  },
                  value: 110,
                },
                {
                  label: {
                    [Language.ENGLISH]: '115',
                    [Language.SPANISH]: '115',
                  },
                  value: 115,
                },
                {
                  label: {
                    [Language.ENGLISH]: '120',
                    [Language.SPANISH]: '120',
                  },
                  value: 120,
                },
                {
                  label: {
                    [Language.ENGLISH]: '125',
                    [Language.SPANISH]: '125',
                  },
                  value: 125,
                },
                {
                  label: {
                    [Language.ENGLISH]: '130',
                    [Language.SPANISH]: '130',
                  },
                  value: 130,
                },
                {
                  label: {
                    [Language.ENGLISH]: '135',
                    [Language.SPANISH]: '135',
                  },
                  value: 135,
                },
                {
                  label: {
                    [Language.ENGLISH]: '140',
                    [Language.SPANISH]: '140',
                  },
                  value: 140,
                },
              ]}
              sublabel={{
                [Language.ENGLISH]: 'Diastolic',
                [Language.SPANISH]: 'Diastólica',
              }}
              value={responses.hypertensionTypicalBpDiastolic}
            />
          </div>
        </div>
      )}
      {reqs.hypertensionDoctorVisitLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionDoctorVisitLastYear}
            label={{
              [Language.ENGLISH]: 'Have you visited your heart doctor (cardiologist) or regular doctor about high blood pressure in the last year?',
              [Language.SPANISH]: '¿Ha visitado a su médico del corazón (cardiólogo) o a su médico habitual por hipertensión en el último año?',
            }}
            onChange={setResponse('hypertensionDoctorVisitLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionDoctorVisitLastYear}
          />
        </div>
      )}
      {reqs.hypertensionCausedProblemsAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionCausedProblemsAtWork}
            label={{
              [Language.ENGLISH]: 'Has your blood pressure ever caused problems at work, like making you feel chest pain or dizzy, and led to unsafe situations?',
              [Language.SPANISH]: '¿Alguna vez su tensión arterial le ha causado problemas en el trabajo, como hacerle sentir dolor en el pecho o mareos, y lo ha llevado a situaciones inseguras?',
            }}
            onChange={setResponse('hypertensionCausedProblemsAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionCausedProblemsAtWork}
          />
        </div>
      )}
      {reqs.hypertensionRestrictions && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionRestrictions}
            label={{
              [Language.ENGLISH]: 'Has a doctor told you to avoid intense physical activity because of your high blood pressure or heart problems?',
              [Language.SPANISH]: '¿Le ha dicho un médico que evite la actividad física intensa debido a su hipertensión o a problemas cardíacos?',
            }}
            onChange={setResponse('hypertensionRestrictions')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionRestrictions}
          />
        </div>
      )}
      {reqs.hypertensionWorriesAboutWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hypertensionWorriesAboutWork}
            label={{
              [Language.ENGLISH]: 'Do you worry about doing the physical parts of your job because of your high blood pressure?',
              [Language.SPANISH]: '¿Le preocupa realizar las partes físicas de su trabajo debido a su hipertensión?',
            }}
            onChange={setResponse('hypertensionWorriesAboutWork')}
            options={YES_NO_OPTIONS}
            value={responses.hypertensionWorriesAboutWork}
          />
        </div>
      )}
      {reqs.heartCondition150MinutesActivityPerWeek && !yes(responses.heartAttack)
          && !no(responses.anginaAssessedByDoctor) && !yes(responses.anginaCardiac)
          && !yes(responses.heartFailure) && !yes(responses.peripheralEdemaCurrent) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartCondition150MinutesActivityPerWeek}
            label={{
              [Language.ENGLISH]: 'Do you get at least 150 minutes of physical activity per week?',
              [Language.SPANISH]: '¿Realiza al menos 150 minutos de actividad física a la semana?',
            }}
            onChange={setResponse('heartCondition150MinutesActivityPerWeek')}
            options={YES_NO_OPTIONS}
            value={responses.heartCondition150MinutesActivityPerWeek}
          />
        </div>
      )}
      {reqs.heartConditionHighCholesterol && !yes(responses.heartAttack)
          && !no(responses.anginaAssessedByDoctor) && !yes(responses.anginaCardiac)
          && !yes(responses.heartFailure) && !yes(responses.peripheralEdemaCurrent) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionHighCholesterol}
            label={{
              [Language.ENGLISH]: 'Do you have high cholesterol?',
              [Language.SPANISH]: '¿Tiene el colesterol alto?',
            }}
            onChange={setResponse('heartConditionHighCholesterol')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionHighCholesterol}
          />
        </div>
      )}
    </Card>
  );
}
