import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, PriorRespiratorUseProblemCause, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS, YES_NO_UNKNOWN_OPTIONS } from './options';

export function PriorRespiratorUseAnxietyBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Anxiety',
      [Language.SPANISH]: 'Ansiedad',
    }}>
      <InlineRadioField<YesNo>
        error={errors.priorRespiratorUseAnxiety}
        label={{
          [Language.ENGLISH]: 'Has using a respirator ever caused you anxiety?',
          [Language.SPANISH]: '¿El uso de un respirador le ha causado alguna vez ansiedad?',
        }}
        onChange={setResponse('priorRespiratorUseAnxiety')}
        options={YES_NO_OPTIONS}
        value={responses.priorRespiratorUseAnxiety}
      />
      {reqs.priorRespiratorUseAnxietyCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseAnxietyCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still have anxiety while using a respirator?',
              [Language.SPANISH]: '¿Sigue teniendo ansiedad mientras utiliza un respirador?',
            }}
            onChange={setResponse('priorRespiratorUseAnxietyCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseAnxietyCurrent}
          />
        </div>
      )}
      {reqs.priorRespiratorUseAnxietyTookOffRespiratorUnsafely && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseAnxietyTookOffRespiratorUnsafely}
            label={{
              [Language.ENGLISH]: 'Has your anxiety ever made you take off your respirator too soon or in a way that wasn\'t safe?',
              [Language.SPANISH]: '¿Alguna vez su ansiedad le ha hecho quitarse el respirador demasiado pronto o de una forma que no fuera segura?',
            }}
            onChange={setResponse('priorRespiratorUseAnxietyTookOffRespiratorUnsafely')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseAnxietyTookOffRespiratorUnsafely}
          />
        </div>
      )}
      {reqs.priorRespiratorUseAnxietyCause && (
        <div className="mt-8">
          <StackedRadioField<PriorRespiratorUseProblemCause>
            error={errors.priorRespiratorUseAnxietyCause}
            label={{
              [Language.ENGLISH]: 'What was the cause of your anxiety?',
              [Language.SPANISH]: '¿Cuál fue la causa de su ansiedad?',
            }}
            onChange={setResponse('priorRespiratorUseAnxietyCause')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'The respirator itself',
                  [Language.SPANISH]: 'El propio respirador',
                },
                value: PriorRespiratorUseProblemCause.RESPIRATOR,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Something at my work',
                  [Language.SPANISH]: 'Algo en mi trabajo',
                },
                value: PriorRespiratorUseProblemCause.WORK_ENVIRONMENT,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t know',
                  [Language.SPANISH]: 'No sé',
                },
                value: PriorRespiratorUseProblemCause.UNKNOWN,
              },
            ]}
            value={responses.priorRespiratorUseAnxietyCause}
          />
        </div>
      )}
      {reqs.priorRespiratorUseAnxietyCurrentlyWorksWithCause && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.priorRespiratorUseAnxietyCurrentlyWorksWithCause}
            label={{
              [Language.ENGLISH]: 'Will you still be working around the thing that causes your anxiety?',
              [Language.SPANISH]: '¿Seguirá trabajando alrededor de la cosa que le provoca ansiedad?',
            }}
            onChange={setResponse('priorRespiratorUseAnxietyCurrentlyWorksWithCause')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.priorRespiratorUseAnxietyCurrentlyWorksWithCause}
          />
        </div>
      )}
      {reqs.priorRespiratorUseAnxietyAbleToUseAlternateRespirator && (
        <div className="mt-8">
        <StackedRadioField<YesNoUnknown>
          error={errors.priorRespiratorUseAnxietyAbleToUseAlternateRespirator}
          label={{
            [Language.ENGLISH]: 'In your current job, are you able to use a style of respirator that does not cause you any anxiety?',
            [Language.SPANISH]: 'En su trabajo actual, ¿puede utilizar un estilo de respirador que no le cause ansiedad?',
          }}
          onChange={setResponse('priorRespiratorUseAnxietyAbleToUseAlternateRespirator')}
          options={[
            {
              label: {
                [Language.ENGLISH]: 'Yes, I can use a respirator that is comfortable and does not cause me anxiety',
                [Language.SPANISH]: 'Sí, puedo utilizar un respirador que me resulte cómodo y no me cause ansiedad',
              },
              value: YesNoUnknown.YES,
            },
            {
              label: {
                [Language.ENGLISH]: 'No, the only respirator I can use causes me anxiety',
                [Language.SPANISH]: 'No, el único respirador que puedo utilizar me provoca ansiedad',
              },
              value: YesNoUnknown.NO,
            },
            {
              label: {
                [Language.ENGLISH]: 'I don\'t know if the respirators I will use at my job will cause me anxiety',
                [Language.SPANISH]: 'No sé si los respiradores que utilizaré en mi trabajo me causarán ansiedad',
              },
              value: YesNoUnknown.UNKNOWN,
            },
          ]}
          value={responses.priorRespiratorUseAnxietyAbleToUseAlternateRespirator}
        />
        </div>
      )}
    </Card>
  );
}
