import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { ImplantableCardiacDeviceType, Language, YesNo } from '../../lib/general';
import { yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function ImplantableCardiacDevicesBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Implantable Cardiac Devices',
      [Language.SPANISH]: 'Dispositivos Cardíacos Implantables',
    }}>
      <InlineRadioField<YesNo>
        error={errors.implantableCardiacDevice}
        label={{
          [Language.ENGLISH]: 'Do you have an implantable cardiac device such as a pacemaker or automated implantable cardioverter-defibrillator (AICD)?',
          [Language.SPANISH]: '¿Tiene un dispositivo cardiaco implantable, como un marcapasos o un desfibrilador-cardioversor automático implantable (DAI, o AICD por sus siglas en inglés)?',
        }}
        onChange={setResponse('implantableCardiacDevice')}
        options={YES_NO_OPTIONS}
        value={responses.implantableCardiacDevice}
      />
      {reqs.implantableCardiacDeviceType && (
        <div className="mt-8">
          <StackedRadioField<ImplantableCardiacDeviceType>
            error={errors.implantableCardiacDeviceType}
            label={{
              [Language.ENGLISH]: 'Select the type of implantable cardiac device you have:',
              [Language.SPANISH]: 'Seleccione el tipo de dispositivo cardiaco implantable que tiene:',
            }}
            onChange={setResponse('implantableCardiacDeviceType')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Pacemaker',
                  [Language.SPANISH]: 'Marcapasos',
                },
                value: ImplantableCardiacDeviceType.PACEMAKER,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Automated Implantable Cardioverter-Defibrillator (AICD)',
                  [Language.SPANISH]: 'Desfibrilador-cardioversor Automático Implantable (DAI, o AICD por sus siglas en inglés)',
                },
                value: ImplantableCardiacDeviceType.AICD,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Other',
                  [Language.SPANISH]: 'Otro',
                },
                value: ImplantableCardiacDeviceType.OTHER,
              },
            ]}
            value={responses.implantableCardiacDeviceType}
          />
        </div>
      )}
      {reqs.implantableCardiacDeviceWorksAroundStrongMagneticFields && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.implantableCardiacDeviceWorksAroundStrongMagneticFields}
            label={{
              [Language.ENGLISH]: 'At your job, are you around strong magnetic fields?',
              [Language.SPANISH]: 'En su trabajo, ¿está cerca de fuertes campos magnéticos?',
            }}
            onChange={setResponse('implantableCardiacDeviceWorksAroundStrongMagneticFields')}
            options={YES_NO_OPTIONS}
            value={responses.implantableCardiacDeviceWorksAroundStrongMagneticFields}
          />
        </div>
      )}
      {reqs.implantableCardiacDeviceWorksWithPossibilityOfCollisions && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.implantableCardiacDeviceWorksWithPossibilityOfCollisions}
            label={{
              [Language.ENGLISH]: 'At your job, is there a chance of having collisions where people or objects can bump into you hard?',
              [Language.SPANISH]: 'En su trabajo, ¿existe la posibilidad de tener colisiones en las que personas u objetos puedan chocar fuertemente contra usted?',
            }}
            onChange={setResponse('implantableCardiacDeviceWorksWithPossibilityOfCollisions')}
            options={YES_NO_OPTIONS}
            value={responses.implantableCardiacDeviceWorksWithPossibilityOfCollisions}
          />
        </div>
      )}
      {reqs.implantableCardiacDeviceFixedArrhythmia && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.implantableCardiacDeviceFixedArrhythmia}
            label={{
              [Language.ENGLISH]: `Did your ${responses.implantableCardiacDeviceType === ImplantableCardiacDeviceType.AICD ? 'automated implantable cardioverter-defibrillator' : responses.implantableCardiacDeviceType === ImplantableCardiacDeviceType.PACEMAKER ? 'pacemaker' : 'implantable cardiac device'} fix your ${yes(responses.arrhythmia) ? 'arrhythmia' : 'irregular heartbeat'}?`,
              [Language.SPANISH]: `¿Su ${responses.implantableCardiacDeviceType === ImplantableCardiacDeviceType.AICD ? 'desfibrilador-cardioversor automático implantable' : responses.implantableCardiacDeviceType === ImplantableCardiacDeviceType.PACEMAKER ? 'marcapasos' : 'dispositivo cardíaco implantable'} solucionó ${yes(responses.arrhythmia) ? 'su arritmia' : 'sus latidos irregulares'}?`,
            }}
            onChange={setResponse('implantableCardiacDeviceFixedArrhythmia')}
            options={YES_NO_OPTIONS}
            value={responses.implantableCardiacDeviceFixedArrhythmia}
          />
        </div>
      )}
    </Card>
  );
}
