import { useId } from 'react';

import { TranslationData } from '../../types/general';

import { InputWrapper } from './InputWrapper';

export function TextAreaField({
  error,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  tabIndex,
  value,
}: {
  error?: TranslationData;
  label?: TranslationData;
  maxLength?: number;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  tabIndex?: number;
  value: string;
}) {
  const id = useId();

  return (
    <InputWrapper error={error} id={id} label={label}>
      <textarea
        className={`
          ${error
            ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
            : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary'
          }
          block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset resize-none
          focus:ring-2 focus:ring-inset
        `}
        maxLength={maxLength}
        name={name}
        onChange={(evt) => {
          onChange(evt.target.value);
        }}
        placeholder={placeholder}
        rows={5}
        tabIndex={tabIndex}
        value={value}
      />
    </InputWrapper>
  );
}
