import { useContext } from 'react';

import { appContext } from '../../contexts/appContext';
import { Language, RespiratorMedicalEvaluationStatus } from '../../lib/general';

import { Card } from '../../components/Card';
import { Layout } from '../../components/Layout';
import { Translation } from '../../components/Translation';
import { Title } from '../../components/Title';

import { Approval } from './Approval';
import { InProgress } from './InProgress';

export function ResultPage() {
  const { result } = useContext(appContext);

  return (
    <Layout>
      <Title>
        <Translation data={{
          [Language.ENGLISH]: 'Your Result',
          [Language.SPANISH]: 'Su Resultado',
        }} />
      </Title>
      <Card>
        {result?.status === RespiratorMedicalEvaluationStatus.APPROVED
          ? <Approval />
          : result?.status === RespiratorMedicalEvaluationStatus.PENDING_REVIEW
            ? <InProgress />
            : null}
      </Card>
    </Layout>
  );
}
