import { YesNo, YesNoUnknown } from './general';

export function gte(val: number | null, num: number): boolean {
  return val !== null && val >= num;
}

export function lte(val: number | null, num: number): boolean {
  return val !== null && val <= num;
}

export function isValidDate({
  date,
  max,
  min,
}: {
  date: string;
  max?: string;
  min?: string;
}) {
  if (!(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date))) {
    return false;
  }

  const [yearString, monthString, dayString] = date.split('-');
  const year = parseInt(yearString);
  const day = parseInt(dayString);

  switch (monthString) {
    case '01':
    case '03':
    case '05':
    case '07':
    case '08':
    case '10':
    case '12':
      if (day > 31) {
        return false;
      }

      break;
    case '04':
    case '06':
    case '09':
    case '11':
      if (day > 30) {
        return false;
      }

      break;
    case '02':
      if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        if (day > 29) {
          return false;
        }
      } else {
        if (day > 28) {
          return false;
        }
      }

      break;
    default:
      return false;
  }

  if (max !== undefined && date > max) {
    return false;
  }

  if (min !== undefined && date < min) {
    return false;
  }

  return true;
}

export function no(val: YesNo | YesNoUnknown | null): boolean {
  return (val as YesNo) === YesNo.NO;
}

export function unknown(val: YesNoUnknown | null): boolean {
  return val === YesNoUnknown.UNKNOWN;
}

export function yes(val: YesNo | YesNoUnknown | null): boolean {
  return (val as YesNo) === YesNo.YES;
}
