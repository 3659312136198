export function ErrorPage() {
  return (
    <div className="h-screen w-full grid items-center justify-items-center">
      <div className="max-w-lg p-4">
        <div className="text-2xl font-bold font-display mb-2 text-[#e501ff]">Uh oh.</div>
        <div className="mb-2">An error has occurred that is preventing this page from loading properly. Please double check the link you received and try again. You may contact <a className="text-indigo-500 hover:underline cursor-pointer" href="mailto:support@proximawork.com">support@proximawork.com</a> if the problem persists.</div>
        <div className="mb-2 text-sm text-gray-500">Se ha producido un error que impide que esta página se cargue correctamente. Verifique nuevamente el enlace que recibió e inténtelo nuevamente. Puede comunicarse con <a className="text-indigo-500 hover:underline cursor-pointer" href="mailto:support@proximawork.com">support@proximawork.com</a> si el problema persiste.</div>
      </div>
    </div>
  );
}
