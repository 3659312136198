import { useContext, useState } from 'react';

import { Button } from '../../components/Button';
import { Layout } from '../../components/Layout';
import { Title } from '../../components/Title';
import { Translation } from '../../components/Translation';
import { appContext } from '../../contexts/appContext';
import { Language, YesNo } from '../../lib/general';
import { schema } from '../../schema';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';
import { Schema } from '../../types/schema';

import { AllergiesBlock } from './AllergiesBlock';
import { AnginaBlock } from './AnginaBlock';
import { ArrhythmiaBlock } from './ArrhythmiaBlock';
import { AsbestosisBlock } from './AsbestosisBlock';
import { AsthmaBlock } from './AsthmaBlock';
import { BackProblemsBlock } from './BackProblemsBlock';
import { BrokenRibsBlock } from './BrokenRibsBlock';
import { ChestPainBlock } from './ChestPainBlock';
import { ChronicBronchitisBlock } from './ChronicBronchitisBlock';
import { ClaustrophobiaBlock } from './ClaustrophobiaBlock';
import { ColorBlindnessBlock } from './ColorBlindnessBlock';
import { CoughBlock } from './CoughBlock';
import { DiabetesBlock } from './DiabetesBlock';
import { EarInjuriesBlock } from './EarInjuriesBlock';
import { EmphysemaBlock } from './EmphysemaBlock';
import { HearingAidsBlock } from './HearingAidsBlock';
import { HearingLossBlock } from './HearingLossBlock';
import { HeartAttackBlock } from './HeartAttackBlock';
import { HeartFailureBlock } from './HeartFailureBlock';
import { HeartMurmurBlock } from './HeartMurmurBlock';
import { HeartburnBlock } from './HeartburnBlock';
import { HeightWeightBlock } from './HeightWeightBlock';
import { HypertensionBlock } from './HypertensionBlock';
import { HyposmiaBlock } from './HyposmiaBlock';
import { ImplantableCardiacDevicesBlock } from './ImplantableCardiacDevicesBlock';
import { LungCancerBlock } from './LungCancerBlock';
import { MedicationsBlock } from './MedicationsBlock';
import { OtherChestInjuriesSurgeriesBlock } from './OtherChestInjuriesSurgeriesBlock';
import { OtherHearingProblemsBlock } from './OtherHearingProblemsBlock';
import { OtherHeartProblemsBlock } from './OtherHeartProblemsBlock';
import { OtherHeartSymptomsBlock } from './OtherHeartSymptomsBlock';
import { OtherLungProblemsBlock } from './OtherLungProblemsBlock';
import { OtherLungSymptomsBlock } from './OtherLungSymptomsBlock';
import { OtherMusculoskeletalProblemsBlock } from './OtherMusculoskeletalProblemsBlock';
import { OtherVisionProblemsBlock } from './OtherVisionProblemsBlock';
import { PneumoniaBlock } from './PneumoniaBlock';
import { PneumothoraxBlock } from './PneumothoraxBlock';
import { PriorRespiratorUseAnxietyBlock } from './PriorRespiratorUseAnxietyBlock';
import { PriorRespiratorUseBlock } from './PriorRespiratorUseBlock';
import { PriorRespiratorUseEyeIrritationBlock } from './PriorRespiratorUseEyeIrritationBlock';
import { PriorRespiratorUseFatigueBlock } from './PriorRespiratorUseFatigueBlock';
import { PriorRespiratorUseOtherProblemsBlock } from './PriorRespiratorUseOtherProblemsBlock';
import { PriorRespiratorUseSkinProblemsBlock } from './PriorRespiratorUseSkinProblemsBlock';
import { RangeOfMotionBlock } from './RangeOfMotionBlock';
import { SeizuresBlock } from './SeizuresBlock';
import { SilicosisBlock } from './SilicosisBlock';
import { SmokingHistoryBlock } from './SmokingHistoryBlock';
import { SobBlock } from './SobBlock';
import { SpeakToProviderBlock } from './SpeakToProviderBlock';
import { StrokeBlock } from './StrokeBlock';
import { TuberculosisBlock } from './TuberculosisBlock';
import { VisionCorrectionBlock } from './VisionCorrectionBlock';
import { VisionLossBlock } from './VisionLossBlock';
import { WeaknessBlock } from './WeaknessBlock';
import { WheezeBlock } from './WheezeBlock';
import { JobRole } from '../../types/api';

function getRequirements(
  schema: Schema,
  responses: HealthHistoryStageResponses,
  jobRole: JobRole,
): HealthHistoryStageRequirements {
  return Object.entries(schema.questions).reduce((reqs, [k, def]) => ({
    ...reqs,
    [k as keyof HealthHistoryStageResponses]:
      def.required === undefined || def.required(responses, jobRole),
  }), {}) as HealthHistoryStageRequirements;
}

const REQUIRED_ERROR = {
  [Language.ENGLISH]: 'This field is required.',
  [Language.SPANISH]: 'Este campo es obligatorio.',
};

const INVALID_ERROR = {
  [Language.ENGLISH]: 'This field is invalid.',
  [Language.SPANISH]: 'Este campo no es válido.',
};

function getErrors(
  schema: Schema,
  responses: HealthHistoryStageResponses,
  jobRole: JobRole,
): HealthHistoryStageErrors {
  return Object.entries(schema.questions).reduce((errors, [k, def]) => {
    const key = k as keyof HealthHistoryStageResponses;

    const required = def.required === undefined || def.required(responses, jobRole);

    let missing = false;

    switch (def.type) {
      case 'ARRAY':
        missing = (responses[key] as string[] | number[]).length === 0;
        break;
      case 'ENUM':
      case 'NUMBER':
        missing = responses[key] === null;
        break;
      case 'STRING':
        missing = (responses[key] as string).trim().length === 0;
        break;
    }

    if (missing) {
      if (required) {
        return {
          ...errors,
          [key]: REQUIRED_ERROR,
        };
      } else {
        return errors;
      }
    } else {
      if (def.type === 'NUMBER') {
        if (isNaN(responses[key] as number)) {
          return {
            ...errors,
            [key]: INVALID_ERROR,
          };
        }
      }
    }

    return errors;
  }, {});
}

function isDeepEqual(r1: HealthHistoryStageResponses, r2: HealthHistoryStageResponses): boolean {
  return Object.keys(r1).every((k) => {
    const key = k as keyof HealthHistoryStageResponses;

    const v1 = r1[key];
    const v2 = r2[key];

    if (Array.isArray(v1)) {
      const a1 = v1 as string[] | number[];
      const a2 = v2 as string[] | number[];
      return a1.length === a2.length && a1.every((item, i) => item === a2[i]);
    } else if (typeof v1 === 'number' && typeof v2 === 'number' && isNaN(v1) && isNaN(v2)) {
      return true;
    } else {
      return v1 === v2;
    }
  });
}

function pruneResponses(
  schema: Schema,
  responses: HealthHistoryStageResponses,
  jobRole: JobRole,
): HealthHistoryStageResponses {
  return Object.entries(schema.questions).reduce((prunedResponses, [k, def]) => {
    const key = k as keyof HealthHistoryStageResponses;

    return {
      ...prunedResponses,
      [key]: (
          (def.required !== undefined ? def.required(responses, jobRole) : true)
          // TODO | This is a hack to keep this field from being erased because
          // TODO | it is not required; this can be removed once 'include' and
          // TODO | 'required' are not the same thing.
          || key === 'genderIdentity'
        )
        ? responses[key]
        : def.type === 'ARRAY' ? [] : def.type === 'STRING' ? '' : null,
    };
  }, {}) as HealthHistoryStageResponses;
}

export function HealthHistoryPage() {
  const {
    initializer,
    responses,
    setDrawerContent,
    setResponses,
  } = useContext(appContext);

  const [errors, setErrors] = useState<HealthHistoryStageErrors>({});

  const reqs = getRequirements(schema, responses, initializer.jobRole);

  function setResponse(
    key: keyof HealthHistoryStageResponses,
  ): (value: string | string[] | number | null) => void {
    return function setResponse(value: string | string[] | number | null): void {
      let newResponses = {
        ...responses,
        [key]: value,
      };

      let counter = 0;

      do {
        counter++;

        if (counter > 10) {
          throw new Error();
        }

        const nextNewResponses = pruneResponses(schema, newResponses, initializer.jobRole);

        if (isDeepEqual(newResponses, nextNewResponses)) {
          break;
        }

        newResponses = nextNewResponses;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
      } while (true);

      setResponses(newResponses);
    };
  }

  function trySubmit() {
    const _errors = getErrors(schema, responses, initializer.jobRole);

    // Systolic must be higher than diastolic
    if (responses.hypertension === YesNo.YES) {
      if (responses.hypertensionTypicalBpSystolic !== null
          && responses.hypertensionTypicalBpDiastolic !== null) {
        if (responses.hypertensionTypicalBpDiastolic >= responses.hypertensionTypicalBpSystolic) {
          _errors.hypertensionTypicalBpSystolic = INVALID_ERROR;
          _errors.hypertensionTypicalBpDiastolic = INVALID_ERROR;
        }
      }
    }

    setErrors(_errors);

    if (Object.keys(_errors).length === 0) {
      setDrawerContent('attestation');
    } else {
      setTimeout(() => {
        const errors = document.getElementsByClassName('has-error');

        if (errors.length > 0) {
          const firstError = errors[0] as HTMLElement;

          scrollTo({
            behavior: 'smooth',
            top: firstError.offsetTop - 74,
          });
        }
      }, 50);
    }
  }

  return (
    <Layout>
      <Title>
        <Translation data={{
          [Language.ENGLISH]: 'Your Health History',
          [Language.SPANISH]: 'Su Historial Médico',
        }} />
      </Title>
      <div className="mb-8 px-4 sm:px-0">
        <Translation data={{
          [Language.ENGLISH]: `
            To ensure worker safety, the Occupational Safety and Health
            Administration (OSHA) requires employees who use a respirator at
            work to complete a health history questionnaire. Your answers will
            be reviewed by a Proxima Physician or Licensed Healthcare Provider.
            If you have any questions, please contact your supervisor or Proxima
            support at
          `,
          [Language.SPANISH]: `
            Para garantizar la seguridad de los trabajadores, la Administración
            de Seguridad y Salud Ocupacional (OSHA) requiere que los empleados
            que utilizan un respirador en el trabajo completen un cuestionario
            de historial de salud. Sus respuestas serán revisadas por un médico
            o proveedor de atención médica con licencia de Proxima. Si tiene
            alguna pregunta, póngase en contacto con su supervisor o con el
            servicio de asistencia de Proxima enviando un correo electrónico a
          `,
        }} />
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        <Translation data={{
          [Language.ENGLISH]: '.',
          [Language.SPANISH]: '.',
        }} />
      </div>
      <HeightWeightBlock
        errors={errors}
        reqs={reqs}
        responses={responses}
        setResponse={setResponse}
      />
      {reqs.priorRespiratorUse && (
        <PriorRespiratorUseBlock
          errors={errors}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.priorRespiratorUseEyeIrritation && (
        <PriorRespiratorUseEyeIrritationBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.priorRespiratorUseSkinProblems && (
        <PriorRespiratorUseSkinProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.priorRespiratorUseAnxiety && (
        <PriorRespiratorUseAnxietyBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.priorRespiratorUseFatigue && (
        <PriorRespiratorUseFatigueBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.priorRespiratorUseMiscProblems && (
        <PriorRespiratorUseOtherProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.smoker && (
        <SmokingHistoryBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.seizures || reqs.seizuresMedications) && (
        <SeizuresBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.diabetes && (
        <DiabetesBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.allergies && (
        <AllergiesBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.claustrophobia && (
        <ClaustrophobiaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.hyposmia && (
        <HyposmiaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.asbestosis && (
        <AsbestosisBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.asthma && (
        <AsthmaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.chronicBronchitis && (
        <ChronicBronchitisBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.emphysema && (
        <EmphysemaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.pneumonia && (
        <PneumoniaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.tuberculosis && (
        <TuberculosisBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.silicosis && (
        <SilicosisBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.pneumothorax && (
        <PneumothoraxBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.lungCancer && (
        <LungCancerBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.brokenRibs && (
        <BrokenRibsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.sob || reqs.sobInterferingWithWork || reqs.sobWalkingFastOrIncline
          || reqs.sobWalkingOwnPaceLevel || reqs.sobWalkingWithOthers
          || reqs.sobWashingOrDressing) && (
        <SobBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.coughBloodyInLastMonth || reqs.coughProducingPhlegm || reqs.coughWakingEarly
          || reqs.coughWhenLying) && (
        <CoughBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.wheeze || reqs.wheezeInterferingWithWork) && (
        <WheezeBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.chestPainBreathingDeeply && (
        <ChestPainBlock
          errors={errors}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscChestInjuriesOrSurgeries && (
        <OtherChestInjuriesSurgeriesBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscLungProblems && (
        <OtherLungProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscLungSymptoms && (
        <OtherLungSymptomsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.heartMurmur && (
        <HeartMurmurBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.heartAttack && (
        <HeartAttackBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.stroke && (
        <StrokeBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.angina || reqs.chestPainDuringPhysicalActivity || reqs.chestPainFrequent
          || reqs.chestPainInterferingWithWork) && (
        <AnginaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.heartFailure || reqs.peripheralEdema) && (
        <HeartFailureBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.arrhythmia || reqs.skippedHeartbeatLastTwoYears) && (
        <ArrhythmiaBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.implantableCardiacDevice && (
        <ImplantableCardiacDevicesBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.hypertension || reqs.hypertensionMedications) && (
        <HypertensionBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.heartburn && (
        <HeartburnBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscHeartProblems && (
        <OtherHeartProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscHeartSymptoms && (
        <OtherHeartSymptomsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.miscLungMedications || reqs.miscHeartMedications) && (
        <MedicationsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.visionLoss && (
        <VisionLossBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.visionCorrection && (
        <VisionCorrectionBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.colorBlindness && (
        <ColorBlindnessBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscVisionProblems && (
        <OtherVisionProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.hearingLoss && (
        <HearingLossBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.brokenEardrum || reqs.miscEarInjuries) && (
        <EarInjuriesBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.hearingAids && (
        <HearingAidsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscHearingProblems && (
        <OtherHearingProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.backInjury || reqs.backPain) && (
        <BackProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.weaknessInExtremities || reqs.difficultyClimbingStairs25Pounds) && (
        <WeaknessBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {(reqs.difficultyMovingExtremities || reqs.painStiffnessBendingWaist
          || reqs.difficultyMovingHeadVertically || reqs.difficultyMovingHeadHorizontally
          || reqs.difficultyBendingKnees || reqs.difficultySquatting) && (
        <RangeOfMotionBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.miscMusculoskeletalProblems && (
        <OtherMusculoskeletalProblemsBlock
          errors={errors}
          reqs={reqs}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      {reqs.speakToProvider && (
        <SpeakToProviderBlock
          errors={errors}
          responses={responses}
          setResponse={setResponse}
        />
      )}
      <div className="flex w-full justify-center mb-8">
        <Button onClick={trySubmit}>
          <Translation data={{
            [Language.ENGLISH]: 'Continue',
            [Language.SPANISH]: 'Continuar',
          }} />
        </Button>
      </div>
    </Layout>
  );
}
