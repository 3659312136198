import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

import { DropdownDirection } from '../../lib/inputs';
import { TranslationData } from '../../types/general';
import { SelectOption } from '../../types/inputs';

import { Translation } from '../Translation';
import { ErrorMessage } from '../ErrorMessage';
import { Language } from '../../lib/general';

export function SelectField<T extends string | number>({
  error,
  className,
  label,
  onChange,
  opens = DropdownDirection.RIGHT,
  options,
  sublabel,
  tabIndex,
  value,
}: {
  className?: string;
  error?: TranslationData;
  label?: TranslationData;
  onChange?: (value: T | null) => void;
  opens?: DropdownDirection;
  options: SelectOption<T>[];
  sublabel?: TranslationData;
  tabIndex?: number;
  value: T | null;
}) {
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div className={className}>
      <Listbox
        onChange={(option) => {
          if (onChange !== undefined) {
            onChange(option === null ? null : option.value);
          }
        }}
        value={selectedOption}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className={`
                ${error ? 'text-red-600 has-error': 'text-gray-900'}
                block mb-2 font-medium leading-6
              `}>
                <Translation data={label} />
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button className={`
                ${error
                  ? 'text-red-900 ring-red-300 focus:ring-red-500'
                  : 'text-gray-900 ring-gray-300 focus:ring-primary'
                }
                relative w-full py-1.5 pl-3 pr-10 bg-white text-left rounded-md shadow-sm ring-1
                ring-inset
                focus:outline-none focus:ring-2 
              `} tabIndex={tabIndex}>
                <span className="block truncate">
                  {selectedOption !== null ? <Translation data={selectedOption.label} /> : '\u00a0'}
                </span>
                <span className={`
                  absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none
                `}>
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className={`
                  ${opens === DropdownDirection.RIGHT ? 'left-0' : 'right-0'}
                  absolute mt-1 py-1 bg-white min-w-60 max-h-36 z-10 overflow-auto rounded-md
                  text-base shadow-lg ring-1 ring-black ring-opacity-5
                  focus:outline-none
                `}>
                  <SelectFieldOption key="_NULL" option={null} />
                  {options.map((option) => (
                    <SelectFieldOption key={option.value} option={option} />
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {sublabel && (
        <p className="mt-1 text-sm text-gray-500">
          <Translation data={sublabel} />
        </p>
      )}
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}

function SelectFieldOption<T extends string | number>({
  option,
}: {
  option: SelectOption<T> | null;
}) {
  return (
    <Listbox.Option as={Fragment} value={option}>
      {({ active, selected }) => (
        <li
          className={`
            ${active ? 'bg-primary text-white' : 'text-gray-900'}
            relative cursor-default select-none py-2 pl-3 pr-9
          `}
        >
          <span
            className={`
              ${selected ? 'font-medium' : 'font-normal'}
              block truncate
            `}
          >
            <Translation
              data={option === null
                ? {
                  [Language.ENGLISH]: '\u00a0',
                  [Language.SPANISH]: '\u00a0',
                }
                : option.label
              }
            />
          </span>
          {selected && (
            <span
              className={`
                ${active ? 'text-white': 'text-primary'}
                absolute inset-y-0 right-0 flex items-center pr-4
              `}
            >
              <CheckIcon aria-hidden="true" className="h-5 w-5" />
            </span>
          )}
        </li>
      )}
    </Listbox.Option>
  );
}
