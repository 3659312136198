import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function ChestPainBlock({
  errors,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Chest Pain',
      [Language.SPANISH]: 'Dolor en el Pecho',
    }}>
      <InlineRadioField<YesNo>
        error={errors.chestPainBreathingDeeply}
        label={{
          [Language.ENGLISH]: 'Do you currently have chest pain when you take deep breaths?',
          [Language.SPANISH]: '¿Tiene actualmente dolor en el pecho cuando respira profundamente?',
        }}
        onChange={setResponse('chestPainBreathingDeeply')}
        options={YES_NO_OPTIONS}
        value={responses.chestPainBreathingDeeply}
      />
    </Card>
  );
}
