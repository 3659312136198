export enum ArrhythmiaType {
  ATRIAL_FIBRILLATION_OR_FLUTTER = 'ATRIAL_FIBRILLATION_OR_FLUTTER',
  AV_BLOCK = 'AV_BLOCK',
  BUNDLE_BRANCH_BLOCK = 'BUNDLE_BRANCH_BLOCK',
  LONG_QT_SYNDROME = 'LONG_QT_SYNDROME',
  OTHER = 'OTHER',
  PREMATURE_VENTRICULAR_CONTRACTIONS = 'PREMATURE_VENTRICULAR_CONTRACTIONS',
  SINUS_NODE_DYSFUNCTION = 'SINUS_NODE_DYSFUNCTION',
  SUPRAVENTRICULAR_TACHYCARDIA = 'SUPRAVENTRICULAR_TACHYCARDIA',
  UNKNOWN = 'UNKNOWN',
  VENTRICULAR_TACHYCARDIA = 'VENTRICULAR_TACHYCARDIA',
}

export enum FitTestType {
  QUALITATIVE = 'QUALITATIVE',
  QUANTITATIVE = 'QUANTITATIVE',
}

export enum GenderIdentity {
  MAN = 'MAN',
  NONBINARY = 'NONBINARY',
  PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
  TRANSGENDER = 'TRANSGENDER',
  WOMAN = 'WOMAN',
}

export enum HyposmiaSeverity {
  ANOSMIA = 'ANOSMIA',
  SMELL_MOST_THINGS = 'SMELL_MOST_THINGS',
  SMELL_NOXIOUS_THINGS_ONLY = 'SMELL_NOXIOUS_THINGS_ONLY',
}

export enum ImplantableCardiacDeviceType {
  AICD = 'AICD',
  OTHER = 'OTHER',
  PACEMAKER = 'PACEMAKER',
}

export enum Language {
  ENGLISH = 'en',
  SPANISH = 'es',
}

export enum NameSuffix {
  II = 'II',
  III = 'III',
  IV = 'IV',
  JR = 'JR',
  SR = 'SR',
  V = 'V',
}

export enum NyhaFunctionalClassification {
  CLASS_I = 'CLASS_I',
  CLASS_II = 'CLASS_II',
  CLASS_III = 'CLASS_III',
  CLASS_IV = 'CLASS_IV',
}

export enum PhysicalExertionLevel {
  HEAVY = 'HEAVY',
  LIGHT = 'LIGHT',
  MODERATE = 'MODERATE',
}

export enum PpeType {
  EYE_PROTECTION = 'EYE_PROTECTION',
  FOOT_PROTECTION = 'FOOT_PROTECTION',
  FULL_BODY_COVERING = 'FULL_BODY_COVERING',
  FULL_BODY_POSITIVE_PRESSURE_SUIT = 'FULL_BODY_POSITIVE_PRESSURE_SUIT',
  HAND_PROTECTION = 'HAND_PROTECTION',
  HEAD_PROTECTION = 'HEAD_PROTECTION',
  HEARING_PROTECTION = 'HEARING_PROTECTION',
}

export enum PriorRespiratorUseProblemCause {
  RESPIRATOR = 'RESPIRATOR',
  UNKNOWN = 'UNKNOWN',
  WORK_ENVIRONMENT = 'WORK_ENVIRONMENT',
}

export enum QuestionnaireStage {
  START,
  PII,
  PROHIBITED,
  HEALTH_HISTORY,
  RESULT,
}

export enum RespiratorMedicalEvaluationStatus {
  APPROVED = 'APPROVED',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

export enum RespiratorType {
  AIR_PURIFYING_DISPOSABLE = 'AIR_PURIFYING_DISPOSABLE',
  AIR_PURIFYING_REUSABLE_FULL_FACE = 'AIR_PURIFYING_REUSABLE_FULL_FACE',
  AIR_PURIFYING_REUSABLE_PARTIAL_FACE = 'AIR_PURIFYING_REUSABLE_PARTIAL_FACE',
  POWERED_AIR_PURIFYING_FULL_FACE = 'POWERED_AIR_PURIFYING_FULL_FACE',
  POWERED_AIR_PURIFYING_PARTIAL_FACE = 'POWERED_AIR_PURIFYING_PARTIAL_FACE',
  SUPPLIED_AIR_HOSE_FULL_FACE = 'SUPPLIED_AIR_HOSE_FULL_FACE',
  SUPPLIED_AIR_HOSE_PARTIAL_FACE = 'SUPPLIED_AIR_HOSE_PARTIAL_FACE',
  SUPPLIED_AIR_SCBA = 'SUPPLIED_AIR_SCBA',
}

export enum RespiratorUsageDuration {
  ESCAPE_ONLY = 'ESCAPE_ONLY',
  LESS_THAN_TWO_HOURS = 'LESS_THAN_TWO_HOURS',
  MORE_THAN_FOUR_HOURS = 'MORE_THAN_FOUR_HOURS',
  TWO_TO_FOUR_HOURS = 'TWO_TO_FOUR_HOURS',
}

export enum RespiratorUsageScenario {
  ESCAPE = 'ESCAPE',
  FIREFIGHTING = 'FIREFIGHTING',
  RESCUE = 'RESCUE',
  RESCUE_OVERSIGHT = 'RESCUE_OVERSIGHT',
  ROUTINE = 'ROUTINE',
  SITUATIONAL = 'SITUATIONAL',
}

export enum RespiratoryHazard {
  CHEMICALS = 'CHEMICALS',
  DUST = 'DUST',
  FUMES = 'FUMES',
  INFECTIOUS_AGENTS = 'INFECTIOUS_AGENTS',
  OXYGEN_DEFICIENT = 'OXYGEN_DEFICIENT',
  OXYGEN_ENRICHED = 'OXYGEN_ENRICHED',
  SENSITIZERS = 'SENSITIZERS',
  SMOKE = 'SMOKE',
  VAPORS = 'VAPORS',
}

export enum Severity {
  MILD = 'MILD',
  MODERATE = 'MODERATE',
  SEVERE = 'SEVERE',
}

export enum Sex {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum TimeFrame {
  FIVE_TO_TEN_YEARS_AGO = 'FIVE_TO_TEN_YEARS_AGO',
  LESS_THAN_FIVE_YEARS_AGO = 'LESS_THAN_FIVE_YEARS_AGO',
  LESS_THAN_ONE_YEAR_AGO = 'LESS_THAN_ONE_YEAR_AGO',
  MORE_THAN_TEN_YEARS_AGO = 'MORE_THAN_TEN_YEARS_AGO',
  MORE_THAN_THREE_YEARS_AGO = 'MORE_THAN_THREE_YEARS_AGO',
  ONE_TO_THREE_YEARS_AGO = 'ONE_TO_THREE_YEARS_AGO',
}

export enum WorkEnvironment {
  CONFINED_SPACE = 'CONFINED_SPACE',
  EXTREME_COLD = 'EXTREME_COLD',
  EXTREME_HEAT = 'EXTREME_HEAT',
  EXTREME_HEIGHT = 'EXTREME_HEIGHT',
  EXTREME_HUMIDITY = 'EXTREME_HUMIDITY',
}

export enum YesNo {
  NO = 'NO',
  YES = 'YES',
}

export enum YesNoUnknown {
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
}
