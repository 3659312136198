import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HearingLossBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Hearing Loss',
      [Language.SPANISH]: 'Pérdida Auditiva',
    }}>
      <InlineRadioField<YesNo>
        error={errors.hearingLoss}
        label={{
          [Language.ENGLISH]: 'Do you currently have difficulty hearing?',
          [Language.SPANISH]: '¿Tiene actualmente dificultades auditivas?',
        }}
        onChange={setResponse('hearingLoss')}
        options={YES_NO_OPTIONS}
        value={responses.hearingLoss}
      />
      {reqs.hearingLossNeedsHearingForWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hearingLossNeedsHearingForWork}
            label={{
              [Language.ENGLISH]: 'Does your job require good hearing to be able to communicate orally with coworkers or hear danger?',
              [Language.SPANISH]: '¿Su trabajo requiere una buena audición para poder comunicarse oralmente con sus compañeros de trabajo o escuchar el peligro?',
            }}
            onChange={setResponse('hearingLossNeedsHearingForWork')}
            options={YES_NO_OPTIONS}
            value={responses.hearingLossNeedsHearingForWork}
          />
        </div>
      )}
      {reqs.hearingLossCanPerformWorkSafely && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hearingLossCanPerformWorkSafely}
            label={{
              [Language.ENGLISH]: 'Can you hear well enough to do your job safely, talk to coworkers, and hear any sounds that warn you of danger?',
              [Language.SPANISH]: '¿Puede oír lo suficientemente bien como para realizar su trabajo con seguridad, hablar con sus compañeros y oír cualquier sonido que le advierta de un peligro?',
            }}
            onChange={setResponse('hearingLossCanPerformWorkSafely')}
            options={YES_NO_OPTIONS}
            value={responses.hearingLossCanPerformWorkSafely}
          />
        </div>
      )}
    </Card>
  );
}
