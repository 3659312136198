import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function WheezeBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Wheezing',
      [Language.SPANISH]: 'Sibilancias',
    }}>
      <InlineRadioField<YesNo>
        error={errors.wheeze}
        label={{
          [Language.ENGLISH]: 'Do you currently experience wheezing?',
          [Language.SPANISH]: '¿Experimenta actualmente sibilancias?',
        }}
        onChange={setResponse('wheeze')}
        options={YES_NO_OPTIONS}
        value={responses.wheeze}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.wheezeInterferingWithWork}
          label={{
            [Language.ENGLISH]: 'Do you currently experience wheezing that interferes with your job?',
            [Language.SPANISH]: '¿Padece actualmente sibilancias que interfieren con su trabajo?',
          }}
          onChange={setResponse('wheezeInterferingWithWork')}
          options={YES_NO_OPTIONS}
          value={responses.wheezeInterferingWithWork}
        />
      </div>
      {reqs.wheezeWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your wheezing gotten worse in the last year?',
              [Language.SPANISH]: '¿Han empeorado sus sibilancias en el último año?',
            }}
            onChange={setResponse('wheezeWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeWorseLastYear}
          />
        </div>
      )}
      {reqs.wheezeSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.wheezeSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your wheezing is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es sus sibilancias?',
            }}
            onChange={setResponse('wheezeSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.wheezeSeverity}
          />
        </div>
      )}
      {reqs.wheezePriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezePriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your wheezing ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez sus sibilancias le han dificultado el uso de un respirador?',
            }}
            onChange={setResponse('wheezePriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.wheezePriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.wheezeFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your wheezing will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que sus sibilancias le dificultarán el uso de un respirador?',
            }}
            onChange={setResponse('wheezeFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.wheezeWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your wheezing?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por sus sibilancias?',
            }}
            onChange={setResponse('wheezeWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeWorkAccommodations}
          />
        </div>
      )}
      {reqs.wheezeAggravatorsAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeAggravatorsAtWork}
            label={{
              [Language.ENGLISH]: 'Will you be working with anything at your job that triggers your wheezing or makes your wheezing worse?',
              [Language.SPANISH]: '¿Trabajará con algo en su trabajo que le desencadene las sibilancias o empeore sus sibilancias?',
            }}
            onChange={setResponse('wheezeAggravatorsAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeAggravatorsAtWork}
          />
        </div>
      )}
      {reqs.wheezeMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('wheezeMedications')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeMedications}
          />
        </div>
      )}
      {reqs.wheezeMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('wheezeMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.wheezeHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your wheezing?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de sus sibilancias?',
            }}
            onChange={setResponse('wheezeHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.wheezeImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.wheezeImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your wheezing?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de sus sibilancias?',
            }}
            onChange={setResponse('wheezeImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.wheezeImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
