import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { HyposmiaSeverity, Language, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS , YES_NO_UNKNOWN_OPTIONS } from './options';

export function HyposmiaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Sense of Smell',
      [Language.SPANISH]: 'Sentido del Olfato',
    }}>
      <InlineRadioField<YesNo>
        error={errors.hyposmia}
        label={{
          [Language.ENGLISH]: 'Have you ever had trouble smelling odors (anosmia or hyposmia)?',
          [Language.SPANISH]: '¿Alguna vez ha tenido problemas para oler los olores (anosmia o hiposmia)?',
        }}
        onChange={setResponse('hyposmia')}
        options={YES_NO_OPTIONS}
        value={responses.hyposmia}
      />
      {reqs.hyposmiaCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hyposmiaCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still find it hard to smell things?',
              [Language.SPANISH]: '¿Todavía le cuesta oler las cosas?',
            }}
            onChange={setResponse('hyposmiaCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.hyposmiaCurrent}
          />
        </div>
      )}
      {reqs.hyposmiaSeverity && (
        <div className="mt-8">
          <StackedRadioField<HyposmiaSeverity>
            error={errors.hyposmiaSeverity}
            label={{
              [Language.ENGLISH]: 'How hard is it for you to smell things?',
              [Language.SPANISH]: '¿Qué tan difícil es oler las cosas?',
            }}
            onChange={setResponse('hyposmiaSeverity')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'I can smell most things',
                  [Language.SPANISH]: 'Puedo oler la mayoría de las cosas'
                },
                value: HyposmiaSeverity.SMELL_MOST_THINGS,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I can only smell really strong, bad smells',
                  [Language.SPANISH]: 'Sólo puedo oler olores realmente fuertes y malos',
                },
                value: HyposmiaSeverity.SMELL_NOXIOUS_THINGS_ONLY,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I can\'t smell anything',
                  [Language.SPANISH]: 'No puedo oler nada',
                },
                value: HyposmiaSeverity.ANOSMIA,
              },
            ]}
            value={responses.hyposmiaSeverity}
          />
        </div>
      )}
      {reqs.hyposmiaNeedsSmellForWork && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.hyposmiaNeedsSmellForWork}
            label={{
              [Language.ENGLISH]: 'Do you need to be able to smell dangers for your job?',
              [Language.SPANISH]: '¿Necesita ser capaz de oler los peligros para su trabajo?',
            }}
            onChange={setResponse('hyposmiaNeedsSmellForWork')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.hyposmiaNeedsSmellForWork}
          />
        </div>
      )}
      {reqs.hyposmiaCanSmellWorkDangers && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.hyposmiaCanSmellWorkDangers}
            label={{
              [Language.ENGLISH]: 'Can you smell the chemicals or dangers that are part of your work?',
              [Language.SPANISH]: '¿Puede oler los productos químicos o los peligros que forman parte de su trabajo?',
            }}
            onChange={setResponse('hyposmiaCanSmellWorkDangers')}
            options={YES_NO_OPTIONS}
            value={responses.hyposmiaCanSmellWorkDangers}
          />
        </div>
      )}
    </Card>
  );
}
