import { Language, Severity, YesNo, YesNoUnknown } from '../../lib/general';

export const FREEFORM_MAX_LENGTH = 500;

export const SEVERITY_OPTIONS = [
  {
    label: {
      [Language.ENGLISH]: 'Mild',
      [Language.SPANISH]: 'Leve',
    },
    value: Severity.MILD,
  },
  {
    label: {
      [Language.ENGLISH]: 'Moderate',
      [Language.SPANISH]: 'Moderado',
    },
    value: Severity.MODERATE,
  },
  {
    label: {
      [Language.ENGLISH]: 'Severe',
      [Language.SPANISH]: 'Severo',
    },
    value: Severity.SEVERE,
  },
];

export const YES_NO_OPTIONS = [
  {
    label: {
      [Language.ENGLISH]: 'Yes',
      [Language.SPANISH]: 'Sí',
    },
    value: YesNo.YES,
  },
  {
    label: {
      [Language.ENGLISH]: 'No',
      [Language.SPANISH]: 'No',
    },
    value: YesNo.NO,
  },
];

export const YES_NO_UNKNOWN_OPTIONS = [
  {
    label: {
      [Language.ENGLISH]: 'Yes',
      [Language.SPANISH]: 'Sí',
    },
    value: YesNoUnknown.YES,
  },
  {
    label: {
      [Language.ENGLISH]: 'No',
      [Language.SPANISH]: 'No',
    },
    value: YesNoUnknown.NO,
  },
  {
    label: {
      [Language.ENGLISH]: 'I don\'t know',
      [Language.SPANISH]: 'No sé',
    },
    value: YesNoUnknown.UNKNOWN,
  },
];
