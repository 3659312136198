import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import { HealthHistoryStageErrors, HealthHistoryStageResponses } from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function PriorRespiratorUseBlock({
  errors,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Prior Respirator Use',
      [Language.SPANISH]: 'Uso Previo del Respirador',
    }}>
      <InlineRadioField<YesNo>
        error={errors.priorRespiratorUse}
        label={{
          [Language.ENGLISH]: 'Have you used a respirator before?',
          [Language.SPANISH]: '¿Ha utilizado antes un respirador?',
        }}
        onChange={setResponse('priorRespiratorUse')}
        options={YES_NO_OPTIONS}
        value={responses.priorRespiratorUse}
      />
    </Card>
  );
}
