import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function ClaustrophobiaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Claustrophobia',
      [Language.SPANISH]: 'Claustrofobia',
    }}>
      <InlineRadioField<YesNo>
        error={errors.claustrophobia}
        label={{
          [Language.ENGLISH]: 'Have you ever had claustrophobia (fear of closed-in places)?',
          [Language.SPANISH]: '¿Ha padecido alguna vez claustrofobia (miedo a los lugares cerrados)?',
        }}
        onChange={setResponse('claustrophobia')}
        options={YES_NO_OPTIONS}
        value={responses.claustrophobia}
      />
      {reqs.claustrophobiaCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.claustrophobiaCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still have claustrophobia?',
              [Language.SPANISH]: '¿Sigue teniendo claustrofobia?',
            }}
            onChange={setResponse('claustrophobiaCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.claustrophobiaCurrent}
          />
        </div>
      )}
      {reqs.claustrophobiaMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.claustrophobiaMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any medicines for your claustrophobia?',
              [Language.SPANISH]: '¿Toma algún medicamento para su claustrofobia?',
            }}
            onChange={setResponse('claustrophobiaMedications')}
            options={YES_NO_OPTIONS}
            value={responses.claustrophobiaMedications}
          />
        </div>
      )}
      {reqs.claustrophobiaMedicationsAdverseEffects && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.claustrophobiaMedicationsAdverseEffects}
            label={{
              [Language.ENGLISH]: 'Do your claustrophobia medicines make you feel sleepy, mixed up, tired, or not able to think clearly?',
              [Language.SPANISH]: '¿Sus medicamentos para la claustrofobia lo hacen sentir somnoliento, confuso, cansado o no puede pensar con claridad?',
            }}
            onChange={setResponse('claustrophobiaMedicationsAdverseEffects')}
            options={YES_NO_OPTIONS}
            value={responses.claustrophobiaMedicationsAdverseEffects}
          />
        </div>
      )}
      {reqs.claustrophobiaWorkInConfinedSpaces && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.claustrophobiaWorkInConfinedSpaces}
            label={{
              [Language.ENGLISH]: 'Do you have to work in tight or closed spaces that could make your claustrophobia worse?',
              [Language.SPANISH]: '¿Tiene que trabajar en espacios estrechos o cerrados que podrían empeorar su claustrofobia?',
            }}
            onChange={setResponse('claustrophobiaWorkInConfinedSpaces')}
            options={YES_NO_OPTIONS}
            value={responses.claustrophobiaWorkInConfinedSpaces}
          />
        </div>
      )}
      {reqs.claustrophobiaPriorRespiratorUseOk && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.claustrophobiaPriorRespiratorUseOk}
            label={{
              [Language.ENGLISH]: 'Have you been OK with using a respirator in the past, or did it make you feel more scared of tight spaces?',
              [Language.SPANISH]: '¿Se ha sentido bien utilizando un respirador en el pasado, o le daba más miedo los espacios reducidos?',
            }}
            onChange={setResponse('claustrophobiaPriorRespiratorUseOk')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I was OK using a respirator',
                  [Language.SPANISH]: 'Sí, estaba bien usando un respirador',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, it made me feel more scared',
                  [Language.SPANISH]: 'No, me hizo sentir más miedo',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.claustrophobiaPriorRespiratorUseOk}
          />
        </div>
      )}
      {reqs.claustrophobiaFutureRespiratorUseExpectedOk && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.claustrophobiaFutureRespiratorUseExpectedOk}
            label={{
              [Language.ENGLISH]: 'Do you think you can use a respirator, or would it be too hard for you because of your fear of tight spaces?',
              [Language.SPANISH]: '¿Cree que puede utilizar un respirador, o le resultaría demasiado difícil debido a su miedo a los espacios reducidos?',
            }}
            onChange={setResponse('claustrophobiaFutureRespiratorUseExpectedOk')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I think I can use a respirator',
                  [Language.SPANISH]: 'Sí, creo que puedo usar un respirador',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I think using a respirator will be too difficult',
                  [Language.SPANISH]: 'No, creo que usar un respirador será demasiado difícil',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.claustrophobiaFutureRespiratorUseExpectedOk}
          />
        </div>
      )}
    </Card>
  );
}
