import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import { HealthHistoryStageErrors, HealthHistoryStageRequirements, HealthHistoryStageResponses } from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function SmokingHistoryBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Smoking History',
      [Language.SPANISH]: 'Antecedentes de Tabaquismo',
    }}>
      <InlineRadioField<YesNo>
        error={errors.smoker}
        label={{
          [Language.ENGLISH]: 'Do you currently smoke tobacco, or have you smoked tobacco in the last month?',
          [Language.SPANISH]: '¿Fuma tabaco actualmente o ha fumado tabaco en el último mes?',
        }}
        onChange={setResponse('smoker')}
        options={YES_NO_OPTIONS}
        value={responses.smoker}
      />
      {reqs.smokerTroubleBreathing && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.smokerTroubleBreathing}
            label={{
              [Language.ENGLISH]: 'Do you have trouble breathing because of your smoking habit?',
              [Language.SPANISH]: '¿Tiene problemas para respirar debido a su hábito de fumar?',
            }}
            onChange={setResponse('smokerTroubleBreathing')}
            options={YES_NO_OPTIONS}
            value={responses.smokerTroubleBreathing}
          />
        </div>
      )}
    </Card>
  );
}
