import { FinalizeQuestionnaireResponse } from '../../types/api';
import { HealthHistoryStageResponses } from '../../types/general';

export async function finalizeQuestionnaire({
  questionnairePrn,
  responses,
}: {
  questionnairePrn: string;
  responses: HealthHistoryStageResponses;
}): Promise<FinalizeQuestionnaireResponse> {
  const result = await fetch(
    `${import.meta.env.VITE_API_BASE_URL}/questionnaires/${questionnairePrn}/finalize`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        responses,
      }),
    },
  );

  if (result.status !== 200) {
    throw new Error();
  }

  return await result.json() as FinalizeQuestionnaireResponse;
}
