import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function PriorRespiratorUseFatigueBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Fatigue',
      [Language.SPANISH]: 'Fatiga',
    }}>
      <InlineRadioField<YesNo>
        error={errors.priorRespiratorUseFatigue}
        label={{
          [Language.ENGLISH]: 'Has using a respirator ever caused you any general weakness or fatigue?',
          [Language.SPANISH]: '¿El uso de un respirador le ha causado alguna vez debilidad general o fatiga?',
        }}
        onChange={setResponse('priorRespiratorUseFatigue')}
        options={YES_NO_OPTIONS}
        value={responses.priorRespiratorUseFatigue}
      />
      {reqs.priorRespiratorUseFatigueCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseFatigueCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still feel weak or tired when you use a respirator?',
              [Language.SPANISH]: '¿Sigue sintiéndose débil o cansado cuando utiliza un respirador?',
            }}
            onChange={setResponse('priorRespiratorUseFatigueCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseFatigueCurrent}
          />
        </div>
      )}
      {reqs.priorRespiratorUseFatigueCausedByHealthProblem && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseFatigueCausedByHealthProblem}
            label={{
              [Language.ENGLISH]: 'Is the feeling of being weak or tired because of an illness or medical condition?',
              [Language.SPANISH]: '¿La sensación de debilidad o cansancio se debe a una enfermedad o afección médica?',
            }}
            onChange={setResponse('priorRespiratorUseFatigueCausedByHealthProblem')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseFatigueCausedByHealthProblem}
          />
        </div>
      )}
      {reqs.priorRespiratorUseFatigueCausedByPoorFitness && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseFatigueCausedByPoorFitness}
            label={{
              [Language.ENGLISH]: 'Do you feel weak or tired because you\'re not in good physical shape?',
              [Language.SPANISH]: '¿Se siente débil o cansado porque no está en buena forma física?',
            }}
            onChange={setResponse('priorRespiratorUseFatigueCausedByPoorFitness')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseFatigueCausedByPoorFitness}
          />
        </div>
      )}
      {reqs.priorRespiratorUseFatigueImpactsWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseFatigueImpactsWork}
            label={{
              [Language.ENGLISH]: 'Does your weakness or fatigue make it hard for you to do your job or to keep up with your coworkers?',
              [Language.SPANISH]: '¿Su debilidad o fatiga le dificultan hacer su trabajo o seguir el ritmo de sus compañeros?',
            }}
            onChange={setResponse('priorRespiratorUseFatigueImpactsWork')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseFatigueImpactsWork}
          />
        </div>
      )}
      {reqs.priorRespiratorUseFatigueAdditionalSx && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseFatigueAdditionalSx}
            label={{
              [Language.ENGLISH]: 'When you felt weak or tired with a respirator on, did you also have chest pain, trouble breathing, or feel dizzy?',
              [Language.SPANISH]: 'Cuando se sintió débil o cansado con el respirador puesto, ¿tuvo también dolor en el pecho, problemas para respirar o se sintió mareado?',
            }}
            onChange={setResponse('priorRespiratorUseFatigueAdditionalSx')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseFatigueAdditionalSx}
          />
        </div>
      )}
      {reqs.priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your weakness or fatigue will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su debilidad o fatiga le dificultarán el uso de un respirador?',
            }}
            onChange={setResponse('priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
    </Card>
  );
}
