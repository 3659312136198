import { useId } from 'react';

import { TranslationData } from '../../types/general';

import { InputWrapper } from './InputWrapper';

export function DateField({
  error,
  label,
  onChange,
  tabIndex,
  value,
}: {
  error?: TranslationData;
  label?: TranslationData;
  onChange?: (value: string) => void;
  tabIndex?: number;
  value: string;
}) {
  const id = useId();

  return (
    <InputWrapper error={error} id={id} label={label}>
      <input
        className={`
          ${error
            ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
            : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary'
          }
          block w-full border-0 py-1.5 shadow-sm ring-1 ring-inset rounded-md
          focus:ring-2 focus:ring-inset
        `}
        id={id}
        onChange={(evt) => {
          if (onChange !== undefined) {
            onChange(evt.target.value);
          }
        }}
        tabIndex={tabIndex}
        type="date"
        value={value}
      />
    </InputWrapper>
  );
}
