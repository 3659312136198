import { TranslationData } from '../types/general';

import { Translation } from './Translation';

export function ErrorMessage({
  error,
}: {
  error: TranslationData;
}) {
  return (
    <p className="mt-1 text-sm text-red-600">
      <Translation data={error} />
    </p>
  );
}
