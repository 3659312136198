import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function CoughBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Cough',
      [Language.SPANISH]: 'Tos'
    }}>
      <InlineRadioField<YesNo>
        error={errors.coughProducingPhlegm}
        label={{
          [Language.ENGLISH]: 'Do you currently have a cough that produces phlegm (thick sputum)?',
          [Language.SPANISH]: '¿Tiene actualmente una tos que produce flema (esputo espeso)?',
        }}
        onChange={setResponse('coughProducingPhlegm')}
        options={YES_NO_OPTIONS}
        value={responses.coughProducingPhlegm}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.coughWakingEarly}
          label={{
            [Language.ENGLISH]: 'Do you currently have a cough that wakes you early in the morning?',
            [Language.SPANISH]: '¿Tiene actualmente una tos que lo despierta temprano por la mañana?',
          }}
          onChange={setResponse('coughWakingEarly')}
          options={YES_NO_OPTIONS}
          value={responses.coughWakingEarly}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.coughWhenLying}
          label={{
            [Language.ENGLISH]: 'Do you currently have a cough that occurs mostly when you are lying down?',
            [Language.SPANISH]: '¿Tiene actualmente una tos que se produce sobre todo cuando está tumbado?',
          }}
          onChange={setResponse('coughWhenLying')}
          options={YES_NO_OPTIONS}
          value={responses.coughWhenLying}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.coughBloodyInLastMonth}
          label={{
            [Language.ENGLISH]: 'Have you coughed up blood in the last month?',
            [Language.SPANISH]: '¿Ha tosido sangre en el último mes?',
          }}
          onChange={setResponse('coughBloodyInLastMonth')}
          options={YES_NO_OPTIONS}
          value={responses.coughBloodyInLastMonth}
        />
      </div>
      {reqs.coughSeverity && (
        <div className="mt-8">
          <StackedRadioField<Severity>
            error={errors.coughSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you think your cough is?',
              [Language.SPANISH]: '¿Qué tan grave cree que es su tos?',
            }}
            onChange={setResponse('coughSeverity')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Mild: I only cough a little',
                  [Language.SPANISH]: 'Leve: Sólo toso un poco',
                },
                value: Severity.MILD,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Moderate: My cough is noticeable but not too bad',
                  [Language.SPANISH]: 'Moderada: Mi tos es notable pero no demasiado fuerte',
                },
                value: Severity.MODERATE,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Severe: My cough is really strong and bothers me a lot',
                  [Language.SPANISH]: 'Severa: Mi tos es muy fuerte y me molesta mucho',
                },
                value: Severity.SEVERE,
              },
            ]}
            value={responses.coughSeverity}
          />
        </div>
      )}
      {reqs.coughFitsDifficultToBreatheMoreThan10Seconds && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.coughFitsDifficultToBreatheMoreThan10Seconds}
            label={{
              [Language.ENGLISH]: 'Do you ever have really bad coughing fits that make it hard to breathe or last for more than 10 seconds?',
              [Language.SPANISH]: '¿Alguna vez ha tenido ataques de tos realmente fuertes que le dificulten la respiración o que duren más de 10 segundos?',
            }}
            onChange={setResponse('coughFitsDifficultToBreatheMoreThan10Seconds')}
            options={YES_NO_OPTIONS}
            value={responses.coughFitsDifficultToBreatheMoreThan10Seconds}
          />
        </div>
      )}
      {reqs.coughPredictsDifficultyUsingRespirator && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.coughPredictsDifficultyUsingRespirator}
            label={{
              [Language.ENGLISH]: 'Do you think your cough or phlegm will make it hard to use a respirator?',
              [Language.SPANISH]: '¿Cree que su tos o sus flemas dificultarán el uso de un respirador?',
            }}
            onChange={setResponse('coughPredictsDifficultyUsingRespirator')}
            options={YES_NO_OPTIONS}
            value={responses.coughPredictsDifficultyUsingRespirator}
          />
        </div>
      )}
      {reqs.coughMedicationRequiredForFits && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.coughMedicationRequiredForFits}
            label={{
              [Language.ENGLISH]: 'Do you need to take medicine, like an inhaler, to stop coughing fits?',
              [Language.SPANISH]: '¿Necesita tomar medicamentos, como un inhalador, para detener los ataques de tos?',
            }}
            onChange={setResponse('coughMedicationRequiredForFits')}
            options={YES_NO_OPTIONS}
            value={responses.coughMedicationRequiredForFits}
          />
        </div>
      )}
      {reqs.coughMedicationAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.coughMedicationAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'Will you be able to get to your medicine and take it while you\'re at work?',
              [Language.SPANISH]: '¿Podrá conseguir su medicina y tomarla mientras está en el trabajo?',
            }}
            onChange={setResponse('coughMedicationAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.coughMedicationAccessibleAtWork}
          />
        </div>
      )}
    </Card>
  );
}
