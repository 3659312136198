import { useContext, useState } from 'react';

import { appContext } from '../contexts/appContext';
import { Language, QuestionnaireStage } from '../lib/general';

import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { Layout } from '../components/Layout';
import { Translation } from '../components/Translation';
import { Title } from '../components/Title';

export function StartPage() {
  const { initializer, setDrawerContent, setStage } = useContext(appContext);
  const [agree, setAgree] = useState(false);

  const employerName = initializer.employer.name;
  const spName = initializer.serviceProvider !== null
    ? initializer.serviceProvider.name
    : null;

  return (
    <Layout>
      <Title>
        <Translation data={{
          [Language.ENGLISH]: 'Welcome',
          [Language.SPANISH]: 'Bienvenido',
        }} />
      </Title>
      <Card>
        <p className="mb-8">
          <Translation data={{
            [Language.ENGLISH]: `
              Welcome to the ${employerName} Respirator Medical Evaluation
              Questionnaire${
                spName !== null ? `, administered by ${spName}` : ''
              }. ${employerName} is required to evaluate your health using this
              questionnaire prior to using a respirator at work.  
            `,
            [Language.SPANISH]: `
              Bienvenido al Cuestionario de Evaluación Médica para un Respirador
              de ${employerName}${
                spName !== null ? `, administrado por ${spName}` : ''
              }. Se requiere que ${employerName} evalúe su salud utilizando este
              cuestionario antes de utilizar un respirador en el trabajo.
            `,
          }} />
        </p>
        <h3 className="mb-2 text-lg font-medium">
          <Translation data={{
            [Language.ENGLISH]: 'Before You Begin',
            [Language.SPANISH]: 'Antes de Empezar',
          }} />
        </h3>
        <p className="mb-2">
          <Translation data={{
            [Language.ENGLISH]: 'You will need the following information before you proceed past this page:',
            [Language.SPANISH]: 'Necesitará la siguiente información antes de pasar de esta página:'
          }} />
        </p>
        <ol className="list-decimal ml-8">
          <li>
            <Translation data={{
              [Language.ENGLISH]: 'Your job role',
              [Language.SPANISH]: 'Su función laboral',
            }} />
          </li>
          <li>
            <Translation data={{
              [Language.ENGLISH]: 'Details about any illnesses, injuries, or conditions you may have',
              [Language.SPANISH]: 'Detalles sobre cualquier enfermedad, lesión o afección que pueda padecer',
            }} />
          </li>
          <li>
            <Translation data={{
              [Language.ENGLISH]: '15 minutes to complete the form',
              [Language.SPANISH]: '15 minutos para rellenar el formulario',
            }} />
          </li>
        </ol>
      </Card>
      <Card>
        <h3 className="mb-2 text-lg font-medium">
          <Translation data={{
            [Language.ENGLISH]: 'Terms and Privacy',
            [Language.SPANISH]: 'Términos y Privacidad',
          }} />
        </h3>
        <p className="mb-8">
          <Translation data={{
            [Language.ENGLISH]: 'You must read and agree to the ',
            [Language.SPANISH]: 'Debe leer y aceptar las ',
          }} />
          <a className="text-primary hover:underline cursor-pointer" onClick={() => {
            setDrawerContent('tou');
          }}>
            <Translation data={{
              [Language.ENGLISH]: 'Terms of Use',
              [Language.SPANISH]: 'Condiciones de Uso',
            }} />
          </a>
          <Translation data={{
            [Language.ENGLISH]: ' and ',
            [Language.SPANISH]: ' y la ',
          }} />
          <a className="text-primary hover:underline cursor-pointer" onClick={() => {
            setDrawerContent('privacy');
          }}>
            <Translation data={{
              [Language.ENGLISH]: 'Privacy Policy',
              [Language.SPANISH]: 'Política de Privacidad',
            }} />
          </a>
          <Translation data={{
            [Language.ENGLISH]: ' of this site before beginning.',
            [Language.SPANISH]: ' de este sitio antes de comenzar.',
          }} />
        </p>
        <fieldset>
          <div className="space-y-5">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  checked={agree}
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  id="agreeToTou"
                  name="agreeToTou"
                  onChange={(evt) => {
                    setAgree(evt.target.checked);
                  }}
                  type="checkbox"
                />
              </div>
              <div className="ml-3 leading-6 mb-4">
                <label
                  htmlFor="agreeToTou"
                  className="font-medium text-gray-900 cursor-pointer"
                >
                  <Translation data={{
                    [Language.ENGLISH]: 'I have read and agree to the terms of use and privacy policy of this site.',
                    [Language.SPANISH]: 'Leí y acepto las condiciones de uso y la política de privacidad de este sitio.',
                  }} />
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </Card>
      <div className="flex w-full justify-center mb-8">
        <Button disabled={!agree} onClick={() => {
          if (agree) {
            setStage(QuestionnaireStage.PII);
          }
        }}>
          <Translation data={{
            [Language.ENGLISH]: 'Continue',
            [Language.SPANISH]: 'Continuar',
          }} />
        </Button>
      </div>
    </Layout>
  );
}
