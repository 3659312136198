import { useContext } from 'react';
import Markdown from 'markdown-to-jsx';

import { appContext } from '../contexts/appContext';
import { TranslationData } from '../types/general';

export function Translation({
  data,
  withMarkdown = false,
}: {
  data: TranslationData;
  withMarkdown?: boolean;
}) {
  const { language } = useContext(appContext);

  if (withMarkdown) {
    return <Markdown>{data[language]}</Markdown>;
  } else {
    return data[language];
  }
}
