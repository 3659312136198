import { useState } from 'react';

import { TranslationData } from '../../types/general';

import { TextField } from './TextField';

export function NumberTextField({
  error,
  label,
  max,
  min,
  name,
  onChange,
  rightAddOn,
}: {
  error?: TranslationData;
  label?: TranslationData;
  max?: number;
  min?: number;
  name: string;
  onChange: (val: number | null) => void;
  rightAddOn?: TranslationData;
}) {
  const [value, setValue] = useState('');

  const maxLength = max?.toString().length;

  return (
    <TextField
      error={error}
      inputMode="numeric"
      label={label}
      maxLength={maxLength}
      name={name}
      onChange={(v) => {
        v = v.replace(/[^0-9]/g, '');
        setValue(v);

        if (v.trim() === '') {
          onChange(null);
        } else if (/^[1-9][0-9]*$/.test(v)) {
          const num = parseInt(v);

          if (max !== undefined) {
            if (num > max) {
              onChange(NaN);
              return;
            }
          }

          if (min !== undefined) {
            if (num < min) {
              onChange(NaN);
              return;
            }
          }

          onChange(num);
        } else {
          onChange(NaN)
        }
      }}
      rightAddOn={rightAddOn}
      value={value}
    />
  );
}
