import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function HeartburnBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Heartburn',
      [Language.SPANISH]: 'Acidez',
    }}>
      <InlineRadioField<YesNo>
        error={errors.heartburn}
        label={{
          [Language.ENGLISH]: 'Have you ever had heartburn or indigestion that is not related to eating?',
          [Language.SPANISH]: '¿Ha tenido alguna vez acidez o indigestión que no esté relacionada con la comida?',
        }}
        onChange={setResponse('heartburn')}
        options={YES_NO_OPTIONS}
        value={responses.heartburn}
      />
      {reqs.heartburnRelievedByAntacids && (
        <div className="mt-8">
          <StackedRadioField<YesNoUnknown>
            error={errors.heartburnRelievedByAntacids}
            label={{
              [Language.ENGLISH]: 'Does your heartburn feel better when you take antacids (like Tums®) or heartburn medicines like proton pump inhibitors (PPIs, for example omeprazole)?',
              [Language.SPANISH]: '¿Siente mejor la acidez estomacal cuando toma antiácidos (como Tums®) o medicamentos para la acidez estomacal como los inhibidores de la bomba de protones (PPIs, por sus siglas en inglés; por ejemplo omeprazol)?',
            }}
            onChange={setResponse('heartburnRelievedByAntacids')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, antacids or heartburn medicines help my heartburn',
                  [Language.SPANISH]: 'Sí, los antiácidos o medicamentos para la acidez estomacal ayudan con mi acidez estomacal',
                },
                value: YesNoUnknown.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, antacids or heartburn medicines do not help my heartburn',
                  [Language.SPANISH]: 'No, los antiácidos o medicamentos para la acidez estomacal no ayudan con mi acidez estomacal',
                },
                value: YesNoUnknown.NO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t know / I haven\'t tried',
                  [Language.SPANISH]: 'No lo sé / No lo he intentado',
                },
                value: YesNoUnknown.UNKNOWN,
              },
            ]}
            value={responses.heartburnRelievedByAntacids}
          />
        </div>
      )}
      {reqs.heartburnCardiac && (
        <div className="mt-8">
          <StackedRadioField<YesNoUnknown>
            error={errors.heartburnCardiac}
            label={{
              [Language.ENGLISH]: 'What does your doctor say is the cause of your heartburn?',
              [Language.SPANISH]: '¿Cuál dice su médico que es la causa de su acidez estomacal?',
            }}
            onChange={setResponse('heartburnCardiac')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'My doctor said my heartburn is because of my heart',
                  [Language.SPANISH]: 'Mi médico dice que mi acidez se debe a mi corazón',
                },
                value: YesNoUnknown.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'My doctor said my heartburn is NOT because of my heart',
                  [Language.SPANISH]: 'Mi médico dice que mi acidez NO se debe a mi corazón',
                },
                value: YesNoUnknown.NO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'A doctor has not checked my heartburn',
                  [Language.SPANISH]: 'Un médico no ha revisado mi acidez',
                },
                value: YesNoUnknown.UNKNOWN,
              },
            ]}
            value={responses.heartburnCardiac}
          />
        </div>
      )}
    </Card>
  );
}
