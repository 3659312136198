import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS, YES_NO_UNKNOWN_OPTIONS } from './options';

export function VisionLossBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Vision Loss',
      [Language.SPANISH]: 'Pérdida de Visión',
    }}>
      <InlineRadioField<YesNo>
        error={errors.visionLoss}
        label={{
          [Language.ENGLISH]: 'Have you ever lost vision in either eye (temporarily or permanently)?',
          [Language.SPANISH]: '¿Ha perdido alguna vez la visión en alguno de sus ojos (temporal o permanentemente)?',
        }}
        onChange={setResponse('visionLoss')}
        options={YES_NO_OPTIONS}
        value={responses.visionLoss}
      />
      {reqs.visionLossResolved && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.visionLossResolved}
            label={{
              [Language.ENGLISH]: 'Has your vision returned?',
              [Language.SPANISH]: '¿Recuperó la visión?',
            }}
            onChange={setResponse('visionLossResolved')}
            options={YES_NO_OPTIONS}
            value={responses.visionLossResolved}
          />
        </div>
      )}
      {reqs.visionLossCanPerformWorkSafely && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.visionLossCanPerformWorkSafely}
            label={{
              [Language.ENGLISH]: 'Can you see well enough to safely do your job and notice any visual dangers like spills, warning signs, or steps?',
              [Language.SPANISH]: '¿Puede ver lo suficientemente bien como para realizar su trabajo con seguridad y advertir cualquier peligro visual como derrames, señales de advertencia o escalones?',
            }}
            onChange={setResponse('visionLossCanPerformWorkSafely')}
            options={YES_NO_OPTIONS}
            value={responses.visionLossCanPerformWorkSafely}
          />
        </div>
      )}
      {reqs.visionLossPriorScbaOrFullFaceUse && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.visionLossPriorScbaOrFullFaceUse}
            label={{
              [Language.ENGLISH]: 'Have you used an SCBA or full-face respirator before?',
              [Language.SPANISH]: '¿Ha utilizado antes un ERA o un respirador de cara completa?',
            }}
            onChange={setResponse('visionLossPriorScbaOrFullFaceUse')}
            options={YES_NO_OPTIONS}
            value={responses.visionLossPriorScbaOrFullFaceUse}
          />
        </div>
      )}
      {reqs.visionLossPriorScbaOrFullFaceUseSuccessful && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.visionLossPriorScbaOrFullFaceUseSuccessful}
            label={{
              [Language.ENGLISH]: 'Could you see well enough with the SCBA or full-face respirator to do your job safely?',
              [Language.SPANISH]: '¿Podría ver lo suficientemente bien con el ERA o el respirador de cara completa para hacer su trabajo con seguridad?',
            }}
            onChange={setResponse('visionLossPriorScbaOrFullFaceUseSuccessful')}
            options={YES_NO_OPTIONS}
            value={responses.visionLossPriorScbaOrFullFaceUseSuccessful}
          />
        </div>
      )}
      {reqs.visionLossExpectsSuccessfulScbaOrFullFaceUse && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.visionLossExpectsSuccessfulScbaOrFullFaceUse}
            label={{
              [Language.ENGLISH]: 'When you eventually use an SCBA or full-face respirator, do you think you can see well enough to do your job safely?',
              [Language.SPANISH]: 'Cuando finalmente utilice un ERA o un respirador de cara completa, ¿cree que podrá ver lo suficientemente bien como para realizar su trabajo con seguridad?',
            }}
            onChange={setResponse('visionLossExpectsSuccessfulScbaOrFullFaceUse')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.visionLossExpectsSuccessfulScbaOrFullFaceUse}
          />
        </div>
      )}
    </Card>
  );
}
