import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function EarInjuriesBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Ear Injuries',
      [Language.SPANISH]: 'Lesiones de Oído',
    }}>
      <InlineRadioField<YesNo>
        error={errors.brokenEardrum}
        label={{
          [Language.ENGLISH]: 'Have you ever had a broken eardrum?',
          [Language.SPANISH]: '¿Alguna vez se ha roto el tímpano?',
        }}
        onChange={setResponse('brokenEardrum')}
        options={YES_NO_OPTIONS}
        value={responses.brokenEardrum}
      />
      {reqs.brokenEardrumResolved && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.brokenEardrumResolved}
            label={{
              [Language.ENGLISH]: 'Has your broken eardrum fully healed or been surgically repaired?',
              [Language.SPANISH]: '¿Su tímpano roto sanó completamente o ha sido reparado quirúrgicamente?',
            }}
            onChange={setResponse('brokenEardrumResolved')}
            options={YES_NO_OPTIONS}
            value={responses.brokenEardrumResolved}
          />
        </div>
      )}
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.miscEarInjuries}
          label={{
            [Language.ENGLISH]: 'Have you ever had any injury to your ears other than a broken eardrum?',
            [Language.SPANISH]: '¿Ha tenido alguna lesión en los oídos que no sea una rotura de tímpano?',
          }}
          onChange={setResponse('miscEarInjuries')}
          options={YES_NO_OPTIONS}
          value={responses.miscEarInjuries}
        />
      </div>
      {reqs.miscEarInjuriesLastingComplications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.miscEarInjuriesLastingComplications}
            label={{
              [Language.ENGLISH]: 'Do you have any lasting complications from your ear injury?',
              [Language.SPANISH]: '¿Tiene alguna complicación duradera de su lesión de oído?',
            }}
            onChange={setResponse('miscEarInjuriesLastingComplications')}
            options={YES_NO_OPTIONS}
            value={responses.miscEarInjuriesLastingComplications}
          />
        </div>
      )}
      {reqs.miscEarInjuriesLastingComplicationsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscEarInjuriesLastingComplicationsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about the lasting complications from your ear injury:',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre las complicaciones duraderas de su lesión de oído:',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscEarInjuriesLastingComplicationsDescription"
            onChange={setResponse('miscEarInjuriesLastingComplicationsDescription')}
            value={responses.miscEarInjuriesLastingComplicationsDescription}
          />
        </div>
      )}
    </Card>
  );
}
