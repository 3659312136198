import { Fragment, useContext } from 'react';

import { Translation } from './Translation';
import { appContext } from '../contexts/appContext';
import {
  Language,
  RespiratorType,
  RespiratorUsageScenario,
  RespiratoryHazard,
} from '../lib/general';

const respiratorTypeLabels = {
  [RespiratorType.AIR_PURIFYING_DISPOSABLE]: {
    [Language.ENGLISH]: 'Disposable Respirator (N95, P100, etc.)',
    [Language.SPANISH]: 'Respirador Desechable (N95, P100, etc.)',
  },
  [RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE]: {
    [Language.ENGLISH]: 'Full-Face Air Purifying (APR)',
    [Language.SPANISH]: 'Purificador de Aire (APR, por sus siglas en inglés) de Cara Completa',
  },
  [RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE]: {
    [Language.ENGLISH]: 'Partial-Face Air Purifying (APR)',
    [Language.SPANISH]: 'Purificador de Aire (APR, por sus siglas en inglés) de Cara Parcial',
  },
  [RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE]: {
    [Language.ENGLISH]: 'Full-Face Powered Air Purifying (PAPR)',
    [Language.SPANISH]: 'Purificador de Aire Motorizado (PAPR, por sus siglas en inglés) de Cara Completa',
  },
  [RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE]: {
    [Language.ENGLISH]: 'Partial-Face Powered Air Purifying (PAPR)',
    [Language.SPANISH]: 'Purificador de Aire Motorizado (PAPR, por sus siglas en inglés) de Cara Parcial',
  },
  [RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE]: {
    [Language.ENGLISH]: 'Full-Face Supplied Air (SAR)',
    [Language.SPANISH]: 'Suministro de Aire (SAR, por sus siglas en inglés) de Cara Completa',
  },
  [RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE]: {
    [Language.ENGLISH]: 'Partial-Face Supplied Air (SAR)',
    [Language.SPANISH]: 'Suministro de Aire (SAR, por sus siglas en inglés) de Cara Parcial',
  },
  [RespiratorType.SUPPLIED_AIR_SCBA]: {
    [Language.ENGLISH]: 'Self-Contained Breathing Apparatus (SCBA)',
    [Language.SPANISH]: 'Equipo de Respiración Autónoma (ERA o SCBA, por sus siglas en inglés)',
  },
};

const usageScenarioLabels = {
  [RespiratorUsageScenario.ESCAPE]: {
    [Language.ENGLISH]: 'Emergency Escape',
    [Language.SPANISH]: 'Escape de Emergencia',
  },
  [RespiratorUsageScenario.FIREFIGHTING]: {
    [Language.ENGLISH]: 'Firefighting',
    [Language.SPANISH]: 'Lucha Contra Incendios',
  },
  [RespiratorUsageScenario.RESCUE]: {
    [Language.ENGLISH]: 'Rescue Team (Rescue Role)',
    [Language.SPANISH]: 'Equipo de Rescate (Función de Rescate)',
  },
  [RespiratorUsageScenario.RESCUE_OVERSIGHT]: {
    [Language.ENGLISH]: 'Rescue Team (Oversight Role)',
    [Language.SPANISH]: 'Equipo de Rescate (Función de Supervisión)',
  },
  [RespiratorUsageScenario.ROUTINE]: {
    [Language.ENGLISH]: 'Routine Use',
    [Language.SPANISH]: 'Uso Rutinario',
  },
  [RespiratorUsageScenario.SITUATIONAL]: {
    [Language.ENGLISH]: 'Situational Use',
    [Language.SPANISH]: 'Uso Situacional',
  },
};

const respiratoryHazardLabels = {
  [RespiratoryHazard.CHEMICALS]: {
    [Language.ENGLISH]: 'Chemicals',
    [Language.SPANISH]: 'Productos Químicos',
  },
  [RespiratoryHazard.DUST]: {
    [Language.ENGLISH]: 'Dust',
    [Language.SPANISH]: 'Polvo',
  },
  [RespiratoryHazard.FUMES]: {
    [Language.ENGLISH]: 'Fumes',
    [Language.SPANISH]: 'Humos',
  },
  [RespiratoryHazard.INFECTIOUS_AGENTS]: {
    [Language.ENGLISH]: 'Infectious Agents',
    [Language.SPANISH]: 'Agentes Infecciosos',
  },
  [RespiratoryHazard.OXYGEN_DEFICIENT]: {
    [Language.ENGLISH]: 'Oxygen-Deficient',
    [Language.SPANISH]: 'Deficiencia de Oxígeno',
  },
  [RespiratoryHazard.OXYGEN_ENRICHED]: {
    [Language.ENGLISH]: 'Oxygen-Enriched',
    [Language.SPANISH]: 'Enriquecido de Oxígeno',
  },
  [RespiratoryHazard.SENSITIZERS]: {
    [Language.ENGLISH]: 'Sensitizers',
    [Language.SPANISH]: 'Sensibilizantes',
  },
  [RespiratoryHazard.SMOKE]: {
    [Language.ENGLISH]: 'Smoke',
    [Language.SPANISH]: 'Humo',
  },
  [RespiratoryHazard.VAPORS]: {
    [Language.ENGLISH]: 'Vapors',
    [Language.SPANISH]: 'Vapores',
  },
};

export function JobRoleSummary({
  locationName,
}: {
  locationName?: string;
}) {
  const { initializer } = useContext(appContext);

  const rmeModule = initializer.jobRole.modules.respiratorMedicalEvaluation;

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-x-6 gap-y-4 border-t-2 border-gray-100 pt-4 sm:grid-cols-2">
        <div>
          <h3 className="text-lg font-bold">
            <Translation data={{
              [Language.ENGLISH]: 'Job Role',
              [Language.SPANISH]: 'Función Laboral',
            }} />
          </h3>
          {' '}
          {initializer.jobRole.name}
        </div>
        {locationName !== undefined && (
          <div>
            <h3 className="text-lg font-bold">
              <Translation data={{
                [Language.ENGLISH]: 'Work Location',
                [Language.SPANISH]: 'Ubicación de Trabajo',
              }} />
            </h3>
            {' '}
            {locationName}
          </div>
        )}
      </div>
      <div className="border-t-2 border-gray-100 pt-4">
        <h3 className="text-lg font-bold">
          <Translation data={{
            [Language.ENGLISH]: 'Respirators',
            [Language.SPANISH]: 'Respiradores',
          }} />
        </h3>
        {rmeModule?.respiratorTypeUsages.map((typeUsage) => (
          <div className="mt-4" key={typeUsage.respiratorType}>
            <div className="font-bold">
              <Translation data={respiratorTypeLabels[typeUsage.respiratorType]} />
            </div>
            <div className="ml-4 flex flex-row flex-wrap gap-x-2">
              {typeUsage.usageScenarios.map((usageScenario, i) => (
                <Fragment key={usageScenario}>
                  <span>
                    <Translation data={usageScenarioLabels[usageScenario]} />
                  </span>
                  {i !== typeUsage.usageScenarios.length - 1 && (
                    <span>&bull;</span>
                  )}
                </Fragment>
              ))}
            </div>
            <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-400">
              {typeUsage.respiratoryHazards.map((respiratoryHazard, i) => (
                <Fragment key={respiratoryHazard}>
                  <span>
                    <Translation data={respiratoryHazardLabels[respiratoryHazard]} />
                  </span>
                  {i !== typeUsage.respiratoryHazards.length - 1 && (
                    <span>&bull;</span>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
