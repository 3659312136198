import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, TimeFrame, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function PneumothoraxBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Pneumothorax',
      [Language.SPANISH]: 'Neumotórax',
    }}>
      <InlineRadioField<YesNo>
        error={errors.pneumothorax}
        label={{
          [Language.ENGLISH]: 'Have you ever had a pneumothorax (collapsed lung)?',
          [Language.SPANISH]: '¿Ha sufrido alguna vez un neumotórax (colapso pulmonar)?',
        }}
        onChange={setResponse('pneumothorax')}
        options={YES_NO_OPTIONS}
        value={responses.pneumothorax}
      />
      {reqs.pneumothoraxMostRecent && (
        <div className="mt-8">
          <StackedRadioField<TimeFrame>
            error={errors.pneumothoraxMostRecent}
            label={{
              [Language.ENGLISH]: 'How long ago was your most recent pneumothorax?',
              [Language.SPANISH]: '¿Hace cuánto fue su neumotórax más reciente?',
            }}
            onChange={setResponse('pneumothoraxMostRecent')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Less than one year ago',
                  [Language.SPANISH]: 'Hace menos de un año',
                },
                value: TimeFrame.LESS_THAN_ONE_YEAR_AGO,
              },
              {
                label: {
                  [Language.ENGLISH]: '1 to 3 years ago',
                  [Language.SPANISH]: 'Hace de 1 a 3 años',
                },
                value: TimeFrame.ONE_TO_THREE_YEARS_AGO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'More than 3 years ago',
                  [Language.SPANISH]: 'Hace más de 3 años',
                },
                value: TimeFrame.MORE_THAN_THREE_YEARS_AGO,
              },
            ]}
            value={responses.pneumothoraxMostRecent}
          />
        </div>
      )}
      {reqs.pneumothoraxKnowsCause && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.pneumothoraxKnowsCause}
            label={{
              [Language.ENGLISH]: 'Do you know what caused the pneumothorax (for example, an injury or surgery)?',
              [Language.SPANISH]: '¿Sabe cuál fue la causa del neumotórax (por ejemplo, una lesión o una cirugía)?',
            }}
            onChange={setResponse('pneumothoraxKnowsCause')}
            options={YES_NO_OPTIONS}
            value={responses.pneumothoraxKnowsCause}
          />
        </div>
      )}
      {reqs.pneumothoraxMultiple && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.pneumothoraxMultiple}
            label={{
              [Language.ENGLISH]: 'Have you had more than one pneumothorax?',
              [Language.SPANISH]: '¿Ha tenido más de un neumotórax?',
            }}
            onChange={setResponse('pneumothoraxMultiple')}
            options={YES_NO_OPTIONS}
            value={responses.pneumothoraxMultiple}
          />
        </div>
      )}
      {reqs.pneumothoraxCurrentProblems && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.pneumothoraxCurrentProblems}
            label={{
              [Language.ENGLISH]: 'Do you still have any problems from your pneumothorax, such as chest pain or shortness of breath?',
              [Language.SPANISH]: '¿Sigue teniendo algún problema derivado del neumotórax, como dolor en el pecho o dificultad para respirar?',
            }}
            onChange={setResponse('pneumothoraxCurrentProblems')}
            options={YES_NO_OPTIONS}
            value={responses.pneumothoraxCurrentProblems}
          />
        </div>
      )}
    </Card>
  );
}
