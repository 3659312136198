import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function WeaknessBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Weakness',
      [Language.SPANISH]: 'Debilidad',
    }}>
      <InlineRadioField<YesNo>
        error={errors.weaknessInExtremities}
        label={{
          [Language.ENGLISH]: 'Do you currently have weakness in any of your arms, hands, legs, or feet?',
          [Language.SPANISH]: '¿Tiene actualmente debilidad en alguno de sus brazos, manos, piernas o pies?',
        }}
        onChange={setResponse('weaknessInExtremities')}
        options={YES_NO_OPTIONS}
        value={responses.weaknessInExtremities}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.difficultyClimbingStairs25Pounds}
          label={{
            [Language.ENGLISH]: 'Would you have difficulty climbing a flight of stairs or a ladder carrying more than 25 pounds?',
            [Language.SPANISH]: '¿Tendría dificultades para subir un tramo de escaleras o una escalera cargando más de 25 libras?',
          }}
          onChange={setResponse('difficultyClimbingStairs25Pounds')}
          options={YES_NO_OPTIONS}
          value={responses.difficultyClimbingStairs25Pounds}
        />
      </div>
      {reqs.weaknessPredictsRespiratorWillExacerbate && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessPredictsRespiratorWillExacerbate}
            label={{
              [Language.ENGLISH]: 'Do you think the weight of a respirator would make your weakness worse?',
              [Language.SPANISH]: '¿Cree que el peso de un respirador empeoraría su debilidad?',
            }}
            onChange={setResponse('weaknessPredictsRespiratorWillExacerbate')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessPredictsRespiratorWillExacerbate}
          />
        </div>
      )}
      {reqs.weaknessImpactingWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessImpactingWork}
            label={{
              [Language.ENGLISH]: 'Does your weakness make it hard for you to do your job or to keep up with your coworkers?',
              [Language.SPANISH]: '¿Su debilidad le dificulta hacer su trabajo o seguir el ritmo de sus compañeros?',
            }}
            onChange={setResponse('weaknessImpactingWork')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessImpactingWork}
          />
        </div>
      )}
      {reqs.weaknessWorsening && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.weaknessWorsening}
            label={{
              [Language.ENGLISH]: 'Is your weakness getting worse over time, or has it stayed the same?',
              [Language.SPANISH]: '¿Su debilidad ha empeorado con el tiempo o se ha mantenido igual?',
            }}
            onChange={setResponse('weaknessWorsening')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'My weakness is getting worse over time',
                  [Language.SPANISH]: 'Mi debilidad empeora con el tiempo',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'My weakness has stayed the same',
                  [Language.SPANISH]: 'Mi debilidad sigue siendo la misma',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.weaknessWorsening}
          />
        </div>
      )}
      {reqs.weaknessWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your weakness?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su debilidad?',
            }}
            onChange={setResponse('weaknessWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessWorkAccommodations}
          />
        </div>
      )}
      {reqs.weaknessCanEscapeDanger && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessCanEscapeDanger}
            label={{
              [Language.ENGLISH]: 'If there\'s an emergency, would you be able to get out of a dangerous situation safely and quickly?',
              [Language.SPANISH]: 'En caso de emergencia, ¿sería capaz de salir de una situación peligrosa de forma segura y rápida?',
            }}
            onChange={setResponse('weaknessCanEscapeDanger')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessCanEscapeDanger}
          />
        </div>
      )}
      {reqs.weaknessUsedSarLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessUsedSarLast2Years}
            label={{
              [Language.ENGLISH]: 'Have you used a supplied-air respirator (SAR) or an SCBA in the last two (2) years?',
              [Language.SPANISH]: '¿Ha utilizado un respirador con suministro de aire (SAR) o un ERA en los últimos dos (2) años?',
            }}
            onChange={setResponse('weaknessUsedSarLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessUsedSarLast2Years}
          />
        </div>
      )}
      {reqs.weaknessPriorSarUseOkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessPriorSarUseOkLast2Years}
            label={{
              [Language.ENGLISH]: 'Were you able to wear the supplied-air respirator (SAR) or SCBA and do your job with it on without any problems?',
              [Language.SPANISH]: '¿Pudo llevar el respirador con suministro de aire (SAR) o el ERA y realizar su trabajo con él puesto sin problemas?',
            }}
            onChange={setResponse('weaknessPriorSarUseOkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessPriorSarUseOkLast2Years}
          />
        </div>
      )}
      {reqs.weaknessMakingCarryingEquipmentDifficult && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.weaknessMakingCarryingEquipmentDifficult}
            label={{
              [Language.ENGLISH]: 'Will your weakness make it hard to wear protective gear and carry up to 25 pounds of respirator equipment on your back while working?',
              [Language.SPANISH]: '¿Su debilidad le dificultará llevar el equipo de protección y cargar hasta 25 libras de equipo respiratorio a la espalda mientras trabaja?',
            }}
            onChange={setResponse('weaknessMakingCarryingEquipmentDifficult')}
            options={YES_NO_OPTIONS}
            value={responses.weaknessMakingCarryingEquipmentDifficult}
          />
        </div>
      )}
    </Card>
  );
}
