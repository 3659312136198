import { useContext } from 'react';

import { appContext } from '../contexts/appContext';
import { Language } from '../lib/general';

import { SelectField } from './inputs/SelectField';
import { DropdownDirection } from '../lib/inputs';

const LANGUAGE_OPTIONS = [
  {
    label: {
      [Language.ENGLISH]: 'English',
      [Language.SPANISH]: 'English',
    },
    value: Language.ENGLISH,
  },
  {
    label: {
      [Language.ENGLISH]: 'Español',
      [Language.SPANISH]: 'Español',
    },
    value: Language.SPANISH,
  },
];

export function LanguageSelector() {
  const { language, setLanguage } = useContext(appContext);

  return (
    <SelectField<Language>
      onChange={(newLanguage) => {
        if (newLanguage !== null) {  // Can't happen but make TS happy
          setLanguage(newLanguage);
        }
      }}
      opens={DropdownDirection.LEFT}
      options={LANGUAGE_OPTIONS}
      value={language}
    />
  );
}
