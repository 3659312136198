import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function OtherHeartSymptomsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Heart Symptoms',
      [Language.SPANISH]: 'Otros Síntomas Cardíacos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscHeartSymptoms}
        label={{
          [Language.ENGLISH]: 'Have you ever had any other symptoms not covered in the questions above that you think may be related to a heart or circulation problem?',
          [Language.SPANISH]: '¿Ha tenido algún otro síntoma no contemplado en las preguntas anteriores que crea que pueda estar relacionado con un problema cardíaco o circulatorio?',
        }}
        onChange={setResponse('miscHeartSymptoms')}
        options={YES_NO_OPTIONS}
        value={responses.miscHeartSymptoms}
      />
      {reqs.miscHeartSymptomsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscHeartSymptomsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your heart symptoms:',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su(s) síntoma(s) cardiaco(s):',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscHeartProblemsDescription"
            onChange={setResponse('miscHeartSymptomsDescription')}
            value={responses.miscHeartSymptomsDescription}
          />
        </div>
      )}
    </Card>
  );
}
