import { Card } from '../components/Card';
import { Layout } from '../components/Layout';
import { Title } from '../components/Title';
import { Translation } from '../components/Translation';
import { Language } from '../lib/general';

export function ProhibitedPage() {
  return (
    <Layout>
      <Title>
        <Translation data={{
          [Language.ENGLISH]: 'You May Not Proceed',
          [Language.SPANISH]: 'No Es Posible Proceder',
        }} />
      </Title>
      <Card>
        <Translation data={{
          [Language.ENGLISH]: `
            We recently received a questionnaire from you. Please contact
            Proxima Support at 
          `,
          [Language.SPANISH]: `
            Recientemente recibimos un cuestionario suyo. Póngase en contacto
            con el servicio de asistencia de Proxima en 
          `,
        }} />
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        <Translation data={{
          [Language.ENGLISH]: ' to continue your Respirator Medical Evaluation.',
          [Language.SPANISH]: ' para continuar con la evaluación médica de su respirador.',
        }} />
      </Card>
    </Layout>
  );
}
