import { ReactNode } from 'react';

import { TranslationData } from '../types/general';

import { Translation } from './Translation';

export function Card({
  children,
  title,
}: {
  children: ReactNode;
  title?: TranslationData;
}) {
  return (
    <>
      {title && (
        <h2 className="mb-2 text-lg font-display font-medium px-4 sm:px-0">
          <Translation data={title} />
        </h2>
      )}
      <div className="bg-white shadow sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:p-6">
          {children}
        </div>
      </div>
    </>
  );
}
