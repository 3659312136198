import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import { yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function AsthmaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Asthma',
      [Language.SPANISH]: 'Asma',
    }}>
      <InlineRadioField<YesNo>
        error={errors.asthma}
        label={{
          [Language.ENGLISH]: 'Have you ever had asthma?',
          [Language.SPANISH]: '¿Ha padecido alguna vez asma?',
        }}
        onChange={setResponse('asthma')}
        options={YES_NO_OPTIONS}
        value={responses.asthma}
      />
      {reqs.asthmaCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaCurrent}
            label={{
              [Language.ENGLISH]: 'Do you still have asthma?',
              [Language.SPANISH]: '¿Sigue teniendo asma?',
            }}
            onChange={setResponse('asthmaCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaCurrent}
          />
        </div>
      )}
      {reqs.asthmaSxDuringAdl && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaSxDuringAdl}
            label={{
              [Language.ENGLISH]: 'Does your asthma bother you when you do everyday things like chores, exercising, or walking up stairs?',
              [Language.SPANISH]: '¿Le molesta el asma cuando hace cosas cotidianas como tareas domésticas, hacer ejercicio o subir escaleras?',
            }}
            onChange={setResponse('asthmaSxDuringAdl')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaSxDuringAdl}
          />
        </div>
      )}
      {reqs.asthmaWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your asthma gotten worse in the last year?',
              [Language.SPANISH]: '¿Ha empeorado su asma en el último año?',
            }}
            onChange={setResponse('asthmaWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaWorseLastYear}
          />
        </div>
      )}
      {reqs.asthmaSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.asthmaSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your asthma is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es su asma?',
            }}
            onChange={setResponse('asthmaSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.asthmaSeverity}
          />
        </div>
      )}
      {reqs.asthmaPriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaPriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your asthma ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez su asma le ha dificultado el uso de un respirador?',
            }}
            onChange={setResponse('asthmaPriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaPriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.asthmaFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your asthma will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su asma le dificultará el uso de un respirador?',
            }}
            onChange={setResponse('asthmaFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.asthmaWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your asthma?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su asma?',
            }}
            onChange={setResponse('asthmaWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaWorkAccommodations}
          />
        </div>
      )}
      {reqs.asthmaAggravatorsAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaAggravatorsAtWork}
            label={{
              [Language.ENGLISH]: 'Will you be working with anything at your job that triggers your asthma or makes your asthma worse?',
              [Language.SPANISH]: '¿Trabajará con algo en su trabajo que le desencadene el asma o empeore su asma?',
            }}
            onChange={setResponse('asthmaAggravatorsAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaAggravatorsAtWork}
          />
        </div>
      )}
      {reqs.asthmaMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('asthmaMedications')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaMedications}
          />
        </div>
      )}
      {reqs.rescueInhalerUseAtLeast3PerWeek && yes(responses.asthmaMedications) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.rescueInhalerUseAtLeast3PerWeek}
            label={{
              [Language.ENGLISH]: 'Do you use your rescue inhaler three (3) or more times per week?',
              [Language.SPANISH]: '¿Utiliza su inhalador de rescate tres (3) o más veces por semana?',
            }}
            onChange={setResponse('rescueInhalerUseAtLeast3PerWeek')}
            options={YES_NO_OPTIONS}
            value={responses.rescueInhalerUseAtLeast3PerWeek}
          />
        </div>
      )}
      {reqs.asthmaMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('asthmaMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.asthmaHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your asthma?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de su asma?',
            }}
            onChange={setResponse('asthmaHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.asthmaImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.asthmaImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your asthma?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de su asma?',
            }}
            onChange={setResponse('asthmaImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.asthmaImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
