import { useId } from 'react';

import { TranslationData } from '../../types/general';

import { Translation } from '../Translation';

import { InputWrapper } from './InputWrapper';
import { TextFieldInput } from './TextFieldInput';

export function TextField({
  className,
  error,
  inputMode,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  sublabel,
  rightAddOn,
  tabIndex,
  type = 'text',
  value,
}: {
  className?: string;
  error?: TranslationData;
  inputMode?: 'numeric';
  label?: TranslationData;
  maxLength?: number;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  rightAddOn?: TranslationData;
  sublabel?: TranslationData;
  tabIndex?: number;
  type?: 'email' | 'text';
  value: string;
}) {
  const id = useId();

  return (
    <InputWrapper className={className} error={error} id={id} label={label}>
      <TextFieldInput
        hasError={error !== undefined}
        inputMode={inputMode}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rightAddOn={rightAddOn}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
      {sublabel && (
        <p className="mt-1 text-sm text-gray-500">
          <Translation data={sublabel} />
        </p>
      )}
    </InputWrapper>
  );
}
