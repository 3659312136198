import { ReactNode } from 'react';

import { TranslationData } from '../../types/general';

import { ErrorMessage } from '../ErrorMessage';
import { FormLabel } from '../FormLabel';

export function InputWrapper({
  children,
  className,
  error,
  id,
  label,
}: {
  children: ReactNode;
  className?: string;
  error?: TranslationData;
  id?: string;
  label?: TranslationData;
}) {
  return (
    <div className={className}>
      {label && (
        <FormLabel hasError={error !== undefined} id={id} label={label} />
      )}
      {children}
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}
