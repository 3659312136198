import { XMarkIcon } from '@heroicons/react/20/solid';
import { useContext } from 'react';

import { appContext } from '../contexts/appContext';
import { Language } from "../lib/general";

import { Title } from "./Title";
import { Translation } from "./Translation";

export function PrivacyPolicy() {
  const { setDrawerContent } = useContext(appContext);

  return (
    <div className="p-8">
      <div className="mx-auto max-w-3xl sm:px-6 lg:px-8 flex flex-col gap-4">
        <div className="text-right">
          <button onClick={() => {
            setDrawerContent(null);
          }} type="button" className="bg-white border-gray-400 border-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        <Title>
          <Translation data={{
            [Language.ENGLISH]: 'Privacy Policy',
            [Language.SPANISH]: 'Política de Privacidad',
          }} />
        </Title>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Protecting your private information is our priority. This
              Statement of Privacy applies to proximawork.com, and Proxima
              WorkTech, LLC (“Proxima”), and governs data collection and usage.
              For the purposes of this Privacy Policy, unless otherwise noted,
              all references to Proxima WorkTech, LLC or Proxima include
              proximawork.com. Proximawork.com is a website that provides
              Occupational Health and Safety Software and associated Services
              (the “Site”).
            `,
            [Language.SPANISH]: `
              Proteger su información privada es nuestra prioridad. Esta
              declaración de privacidad se aplica a proximawork.com, y a Proxima
              WorkTech, LLC ("Proxima"), y rige la recopilación y el uso de los
              datos. A los efectos de esta Política de Privacidad, a menos que
              se indique lo contrario, todas las referencias a Proxima WorkTech,
              LLC o Proxima incluyen proximawork.com. Proximawork.com es un
              sitio web que proporciona Software de Salud y Seguridad
              Ocupacional y Servicios asociados (el "Sitio").
            `,
          }} />
        </p>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              By using the Proxima Site, you consent to the data practices
              described in this Privacy Policy.
            `,
            [Language.SPANISH]: `
              Al utilizar el Sitio de Proxima, usted acepta las prácticas de
              datos descritas en esta Política de Privacidad.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Collection of Your Personal Information',
            [Language.SPANISH]: 'Recogida de Su Información Personal',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              In order to better provide you with products and services offered,
              Proxima may collect personally identifiable information about you.
              Proxima may also collect anonymous information, which is not
              unique to you.
            `,
            [Language.SPANISH]: `
              Con el fin de proporcionarle mejor los productos y servicios
              ofrecidos, Proxima podrá recopilar información personal
              identificable sobre usted. Proxima también puede recopilar
              información anónima, que no es exclusiva de usted.
            `,
          }} />
        </p>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services. These may include: (a) registering
              for an account; (b) entering a sweepstakes or contest sponsored by
              us or one of our partners; (c) signing up for special offers from
              selected third parties; (d) sending us an email message; (e)
              submitting your credit card or other payment information when
              ordering and purchasing products and services. To wit, we will use
              your information for, but not limited to, providing services to
              you and communicating with you in relation to services and/or
              products you have requested from us. We also may gather additional
              personal or non-personal information in the future.
            `,
            [Language.SPANISH]: `
              No recopilamos ninguna información personal sobre usted a menos
              que nos la proporcione voluntariamente. Sin embargo, es posible
              que se le solicite que nos proporcione cierta información
              personal cuando decida utilizar determinados productos o
              servicios. Estos pueden incluir: (a) registrarse para obtener una
              cuenta; (b) participar en un sorteo o concurso patrocinado por
              nosotros o uno de nuestros socios; (c) inscribirse para recibir
              ofertas especiales de terceros seleccionados; (d) enviarnos un
              mensaje de correo electrónico; (e) enviar su tarjeta de crédito u
              otra información de pago al realizar pedidos y comprar productos y
              servicios. A saber, utilizaremos su información para, entre otras
              cosas, prestarle servicios y comunicarnos con usted en relación
              con los servicios y/o productos que nos haya solicitado. También
              podremos recabar información personal o no personal adicional en
              el futuro.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Use of Your Personal Information',
            [Language.SPANISH]: 'Uso de Su Información Personal',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Proxima collects and uses your personal information to operate and
              deliver the services you have requested. Proxima will never
              release or share your personal information without your explicit
              consent.
            `,
            [Language.SPANISH]: `
              Proxima recaba y utiliza su información personal para operar y
              prestar los servicios que usted ha solicitado. Proxima nunca
              divulgará ni compartirá su información personal sin su
              consentimiento explícito.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Sharing Information with Third Parties',
            [Language.SPANISH]: 'Compartir Información con Terceros',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Proxima does not sell, rent or lease its customer lists to third
              parties. Proxima may share data with trusted partners to help
              perform statistical analysis, send you email or postal mail,
              provide customer support, or arrange for deliveries. All such
              third parties are prohibited from using your personal information
              except to provide these services to Proxima, and they are required
              to maintain the confidentiality of your information.
            `,
            [Language.SPANISH]: `
              Proxima no vende, alquila o arrienda su lista de clientes a
              terceras partes. Proxima sí puede compartir datos con socios
              confiables que ayudan y/o en los que delega el análisis
              estadístico, el envío de correo electrónico o postal, la atención
              al cliente, o los arreglos para la distribución y entrega. Todas
              estas terceras partes tienen expresamente prohibido el uso de la
              información personal que pueda identificarlo salvo para poder
              proveer estos servicios a Proxima, y están obligados a mantener la
              confidencialidad de su información.
            `,
          }} />
        </p>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Proxima may only disclose your personal information without notice
              if required to do so by law or in the good faith belief that such
              action is necessary to: (a) conform to the edicts of the law or
              comply with legal process served on Proxima or the site; (b)
              protect and defend the rights or property of Proxima; and/or (c)
              act under exigent circumstances to protect the personal safety of
              users of Proxima, or the public.
            `,
            [Language.SPANISH]: `
              Proxima sólo podrá revelar su información personal sin previo
              aviso si así lo exige la ley o si cree de buena fe que dicha
              acción es necesaria para: (a) ajustarse a los edictos de la ley o
              cumplir con procesos legales notificados a Proxima o al sitio; (b)
              proteger y defender los derechos o la propiedad de Proxima; y/o
              (c) actuar bajo circunstancias exigentes para proteger la
              seguridad personal de los usuarios de Proxima, o del público.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Tracking User Behavior',
            [Language.SPANISH]: 'Seguimiento del Comportamiento de los Usuarios',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Proxima may keep track of the websites and pages our users visit
              within Proxima, in order to improve the operation of the Proxima
              Site and/or the Proxima services.
            `,
            [Language.SPANISH]: `
              Proxima podrá realizar un seguimiento de los sitios web y las
              páginas que visitan nuestros usuarios dentro de Proxima, con el
              fin de mejorar el funcionamiento del Sitio de Proxima y/o los
              servicios de Proxima.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Automatically Collected Information',
            [Language.SPANISH]: 'Información Recopilada Automáticamente',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Information about your computer hardware and software may be
              automatically collected by Proxima. This information can include:
              your IP address, browser type, domain names, access times and
              referring website addresses. This information is used to operate
              the Site, to maintain quality of service, and to provide general
              statistics regarding use of the Proxima Site.
            `,
            [Language.SPANISH]: `
              Proxima puede recopilar automáticamente información sobre el
              hardware y el software de su computadora. Esta información puede
              incluir: su dirección IP, tipo de navegador, nombres de dominio,
              tiempos de acceso y direcciones de sitios web de referencia. Esta
              información se utiliza para operar el sitio, mantener la calidad
              del servicio y proporcionar estadísticas generales sobre el uso
              del sitio de Proxima.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Use of Cookies',
            [Language.SPANISH]: 'Uso de Cookies',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              The Proxima website may use "cookies" to help you personalize your
              online experience. A cookie is a text file that is placed on your
              hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you. One of the primary purposes
              of cookies is to provide a convenience feature to save you time.
              The purpose of a cookie is to tell the Web server that you have
              returned to a specific page. For example, if you personalize
              Proxima pages, or register with Proxima site or services, a cookie
              helps Proxima to recall your specific information on subsequent
              visits. This simplifies the process of recording your personal
              information, such as billing addresses, shipping addresses, and so
              on. When you return to the same Proxima website, the information
              you previously provided can be retrieved, so you can easily use
              the Proxima features that you customized.
            `,
            [Language.SPANISH]: `
              La página web de Proxima puede utilizar "cookies" para ayudarlo a
              personalizar su experiencia en línea. Una cookie es un archivo de
              texto que un servidor de páginas web coloca en su disco duro. Las
              cookies no pueden utilizarse para ejecutar programas o introducir
              virus en su ordenador. Las cookies se le asignan de forma
              exclusiva y sólo pueden ser leídas por un servidor web del dominio
              que le emitió la cookie. Uno de los principales propósitos de las
              cookies es proporcionar una función de comodidad para ahorrarle
              tiempo. La finalidad de una cookie es indicar al servidor web que
              usted ha regresado a una página específica. Por ejemplo, cuando
              Usted personaliza páginas en Proxima, o cuando se registra en el
              sitio Proxima, la cookie grabada permite al servidor Web de
              Proxima recuperar su información específica en las visitas
              posteriores. Esto simplifica el proceso de registrar su
              información personal, como direcciones de facturación, direcciones
              de envío, etcétera. Cuando regrese al mismo sitio Web de Proxima,
              la información que Usted suministró anteriormente puede ser
              recuperada desde la cookie, y así las opciones que Usted haya
              personalizado en su visita anterior a Proxima estarán disponibles
              sin necesidad de que deba escribirlas nuevamente.
            `,
          }} />
        </p>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              You may accept or decline cookies via the web browser you use to
              access the Proxima Site. Most Web browsers automatically accept
              cookies, but you can usually modify your browser setting to
              decline cookies if you prefer. If you choose to decline cookies,
              you may not be able to use the Proxima Site or services.
            `,
            [Language.SPANISH]: `
              Usted puede aceptar o rechazar las cookies a través del navegador
              web que utilice para acceder al Sitio de Proxima. La mayoría de
              los navegadores Web aceptan automáticamente las cookies, pero por
              lo general usted puede modificar la configuración de su navegador
              para rechazar las cookies si así lo prefiere. Si decide rechazar
              las cookies, es posible que no pueda utilizar el Sitio o los
              servicios de Proxima.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Links',
            [Language.SPANISH]: 'Enlaces',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              This website contains links to other sites. Please be aware that
              we are not responsible for the content or privacy practices of
              such other sites. We encourage our users to be aware when they
              leave our site and to read the privacy statements of any other
              site that collects personally identifiable information.
            `,
            [Language.SPANISH]: `
              Este sitio web contiene enlaces a otros sitios. Tenga en cuenta
              que no somos responsables del contenido ni de las prácticas de
              privacidad de esos otros sitios. Animamos a nuestros usuarios a
              que sean conscientes cuando salgan de nuestro sitio y a que lean
              las declaraciones de privacidad de cualquier otro sitio que
              recopile información personal identificable.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Security of Personal Information',
            [Language.SPANISH]: 'Seguridad de la Información Personal',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Proxima secures your personal information from unauthorized
              access, use, or disclosure using industry best practices and in
              compliance with applicable laws and regulations.
            `,
            [Language.SPANISH]: `
              Proxima asegura su información personal del acceso, uso o
              divulgación no autorizados utilizando las mejores prácticas de la
              industria y en cumplimiento con las leyes y regulaciones
              aplicables.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Email Communications',
            [Language.SPANISH]: 'Comunicaciones por Correo Electrónico',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              From time to time, Proxima may contact you via email for the
              purpose of providing receipts, announcements, promotional offers,
              alerts, confirmations, surveys, and/or other general
              communication.
            `,
            [Language.SPANISH]: `
              Proxima podrá ponerse en contacto con usted por correo electrónico
              periódicamente con el fin de proporcionarle recibos, anuncios,
              ofertas promocionales, alertas, confirmaciones, encuestas y/u
              otras comunicaciones generales.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'External Data Storage Sites',
            [Language.SPANISH]: 'Sitios Externos de Almacenamiento de Datos',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              We may store your data on servers provided by third party hosting
              vendors with whom we have contracted. Such data storage is subject
              to applicable laws and regulations regarding the security of your
              data.
            `,
            [Language.SPANISH]: `
              Podemos almacenar sus datos en servidores proporcionados por
              terceros proveedores de alojamiento con los tenemos contrato.
              Dicho almacenamiento de datos está sujeto a las leyes y normativas
              aplicables en materia de seguridad de sus datos.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Changes to this Statement',
            [Language.SPANISH]: 'Cambios en esta Declaración',
          }} />
        </h4>
        <p>
          <Translation data={{
            [Language.ENGLISH]: `
              Proxima reserves the right to change this Privacy Policy from time
              to time. We will notify you about significant changes in the way
              we treat personal information by sending a notice to the primary
              email address specified in your account, by placing a prominent
              notice on our website, and/or by updating any privacy information.
              Your continued use of the website and/or Services available after
              such modifications will constitute your: (a) acknowledgment of the
              modified Privacy Policy; and (b) agreement to abide and be bound
              by that Policy.
            `,
            [Language.SPANISH]: `
              Proxima se reserva el derecho de modificar esta Política de
              Privacidad cada cierto tiempo. Le notificaremos sobre cambios
              significativos en la forma en que tratamos la información personal
              enviándole un aviso a la dirección de correo electrónico principal
              especificada en su cuenta, colocando un aviso destacado en nuestro
              sitio web y/o actualizando cualquier información sobre privacidad.
              Su uso continuado del sitio web y/o de los Servicios disponibles
              después de dichas modificaciones constituirá su (a) Reconocimiento
              de la Política de Privacidad modificada; y (b) Acuerdo de acatar y
              estar obligado por dicha Política.
            `,
          }} />
        </p>
        <h4 className="text-lg font-semibold underline">
          <Translation data={{
            [Language.ENGLISH]: 'Contact Proxima',
            [Language.SPANISH]: 'Contactar a Proxima',
          }} />
        </h4>
        <p>
          contact@proximawork.com<br />
          +1 (888) 856-8288
        </p>
        <p>
          <Translation data={{
            [Language.ENGLISH]: 'Effective as of January 1, 2024',
            [Language.SPANISH]: 'En vigor a partir del 1 de enero de 2024',
          }} />
        </p>
      </div>
    </div>
  );
}
