import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedCheckboxField } from '../../components/inputs/StackedCheckboxField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { ArrhythmiaType, Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function ArrhythmiaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Arrhythmia',
      [Language.SPANISH]: 'Arritmia',
    }}>
      <InlineRadioField<YesNo>
        error={errors.arrhythmia}
        label={{
          [Language.ENGLISH]: 'Have you ever been diagnosed with an arrhythmia?',
          [Language.SPANISH]: '¿Le han diagnosticado alguna vez una arritmia?',
        }}
        onChange={setResponse('arrhythmia')}
        options={YES_NO_OPTIONS}
        value={responses.arrhythmia}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.skippedHeartbeatLastTwoYears}
          label={{
            [Language.ENGLISH]: 'In the past two (2) years, have you noticed yourself having an irregular heartbeat (heart skipping or missing a beat)?',
            [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha notado que tiene un ritmo cardiaco irregular (el corazón se salta o le falta un latido)?',
          }}
          onChange={setResponse('skippedHeartbeatLastTwoYears')}
          options={YES_NO_OPTIONS}
          value={responses.skippedHeartbeatLastTwoYears}
        />
      </div>
      {reqs.arrhythmiaOtherThanHeartMurmur && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.arrhythmiaOtherThanHeartMurmur}
            label={{
              [Language.ENGLISH]: `Do you have ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'an irregular heartbeat'} aside from your heart murmur?`,
              [Language.SPANISH]: `¿Tiene ${responses.arrhythmia === YesNo.YES ? 'arritmia' : 'latidos irregulares'} aparte de su soplo cardíaco?`,
            }}
            onChange={setResponse('arrhythmiaOtherThanHeartMurmur')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: `Yes, I have ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'an irregular heartbeat'} aside from my heart murmur`,
                  [Language.SPANISH]: `Sí, tengo ${responses.arrhythmia === YesNo.YES ? 'arritmia' : 'un latido irregular'} aparte de mi soplo cardíaco`,
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: `No, my ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'} is only caused by my heart murmur and nothing else`,
                  [Language.SPANISH]: `No, ${responses.arrhythmia === YesNo.YES ? 'mi arritmia' : 'mis latidos irregulares'} sólo están causados por mi soplo cardíaco y nada más`,
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.arrhythmiaOtherThanHeartMurmur}
          />
        </div>
      )}
      {reqs.arrhythmiaTypes && (
        <div className="mt-8">
          <StackedCheckboxField<ArrhythmiaType>
            error={errors.arrhythmiaTypes}
            label={{
              [Language.ENGLISH]: `What type(s) of ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'} do you have? Select all that apply:`,
              [Language.SPANISH]: `¿Qué tipo(s) de ${responses.arrhythmia === YesNo.YES ? 'arritmia' : 'latido irregular'} tiene? Seleccione todos los que correspondan:`,
            }}
            notaValue={ArrhythmiaType.UNKNOWN}
            onChange={setResponse('arrhythmiaTypes')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Atrial Fibrillation or Flutter',
                  [Language.SPANISH]: 'Fibrilación o Aleteo Auricular',
                },
                value: ArrhythmiaType.ATRIAL_FIBRILLATION_OR_FLUTTER,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Bundle-Branch Block',
                  [Language.SPANISH]: 'Bloqueo de Rama',
                },
                value: ArrhythmiaType.BUNDLE_BRANCH_BLOCK,
              },
              {
                label: {
                  [Language.ENGLISH]: 'AV Block',
                  [Language.SPANISH]: 'Bloqueo Auriculoventricular',
                },
                value: ArrhythmiaType.AV_BLOCK,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Ventricular Tachycardia',
                  [Language.SPANISH]: 'Taquicardia Ventricular',
                },
                value: ArrhythmiaType.VENTRICULAR_TACHYCARDIA,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Premature Ventricular Contractions (PVCs)',
                  [Language.SPANISH]: 'Contracciones Ventriculares Prematuras (PVCs, por sus siglas en inglés)',
                },
                value: ArrhythmiaType.PREMATURE_VENTRICULAR_CONTRACTIONS,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Supraventricular Tachycardia',
                  [Language.SPANISH]: 'Taquicardia Supraventricular',
                },
                value: ArrhythmiaType.SUPRAVENTRICULAR_TACHYCARDIA,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Long QT Syndrome',
                  [Language.SPANISH]: 'Síndrome de QT Largo',
                },
                value: ArrhythmiaType.LONG_QT_SYNDROME,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Sinus Node Dysfunction',
                  [Language.SPANISH]: 'Disfunción del Nódulo Sinusal',
                },
                value: ArrhythmiaType.SINUS_NODE_DYSFUNCTION,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Other',
                  [Language.SPANISH]: 'Otro',
                },
                value: ArrhythmiaType.OTHER,
              },
              {
                label: {
                  [Language.ENGLISH]: `I am unsure what kind of ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'} I have`,
                  [Language.SPANISH]: `No estoy seguro de qué tipo de ${responses.arrhythmia === YesNo.YES ? 'arritmia' : 'latido irregular'} tengo`,
                },
                value: ArrhythmiaType.UNKNOWN,
              },
            ]}
            value={responses.arrhythmiaTypes}
          />
        </div>
      )}
      {reqs.arrhythmiaWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.arrhythmiaWorkAccommodations}
            label={{
              [Language.ENGLISH]: `Do you have any, or will you be requesting any, medical restrictions or accommodations for your ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'}?`,
              [Language.SPANISH]: `¿Tiene o va a solicitar alguna restricción o adaptación médica por su ${responses.arrhythmia === YesNo.YES ? 'arritmia' : 'latido irregular'}?`,
            }}
            onChange={setResponse('arrhythmiaWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.arrhythmiaWorkAccommodations}
          />
        </div>
      )}
    </Card>
  );
}
