import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function StrokeBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Strokes',
      [Language.SPANISH]: 'Derrame Cerebral',
    }}>
      <InlineRadioField<YesNo>
        error={errors.stroke}
        label={{
          [Language.ENGLISH]: 'Have you ever had a stroke?',
          [Language.SPANISH]: '¿Ha sufrido alguna vez un derrame cerebral (accidente cerebrovascular)?',
        }}
        onChange={setResponse('stroke')}
        options={YES_NO_OPTIONS}
        value={responses.stroke}
      />
      {reqs.strokeMostRecentMoreThan2YearsAgo && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.strokeMostRecentMoreThan2YearsAgo}
            label={{
              [Language.ENGLISH]: 'Was your most recent stroke more than two (2) years ago?',
              [Language.SPANISH]: '¿Su derrame cerebral más reciente fue hace más de dos (2) años?',
            }}
            onChange={setResponse('strokeMostRecentMoreThan2YearsAgo')}
            options={YES_NO_OPTIONS}
            value={responses.strokeMostRecentMoreThan2YearsAgo}
          />
        </div>
      )}
      {reqs.strokeLastingEffectsPreventingRespiratorUse && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.strokeLastingEffectsPreventingRespiratorUse}
            label={{
              [Language.ENGLISH]: 'Do you have any lasting complications from your stroke that would prevent you from wearing a tight-fitting respirator?',
              [Language.SPANISH]: '¿Tiene alguna complicación duradera de su derrame cerebral que le impida llevar un respirador ajustado?',
            }}
            onChange={setResponse('strokeLastingEffectsPreventingRespiratorUse')}
            options={YES_NO_OPTIONS}
            value={responses.strokeLastingEffectsPreventingRespiratorUse}
          />
        </div>
      )}
      {reqs.strokeSubsequentTia && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.strokeSubsequentTia}
            label={{
              [Language.ENGLISH]: 'Since your stroke, have you had any Transient Ischemic Attacks (TIAs or mini-strokes)?',
              [Language.SPANISH]: 'Desde su derrame cerebral, ¿ha tenido algún ataque isquémico transitorio (AIT o mini-ictus)?',
            }}
            onChange={setResponse('strokeSubsequentTia')}
            options={YES_NO_OPTIONS}
            value={responses.strokeSubsequentTia}
          />
        </div>
      )}
      {reqs.strokeWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.strokeWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your past stroke(s)?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su(s) derrame(s) cerebral(es) pasado(s)?',
            }}
            onChange={setResponse('strokeWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.strokeWorkAccommodations}
          />
        </div>
      )}
    </Card>
  );
}
