import { useState } from 'react';

import { TranslationData } from '../../types/general';

import { InputWrapper } from './InputWrapper';
import { TextFieldInput } from './TextFieldInput';

export function HeightField({
  className,
  error,
  feetAddOn,
  inchesAddOn,
  label,
  onChange,
}: {
  className?: string;
  error?: TranslationData;
  feetAddOn: TranslationData;
  inchesAddOn: TranslationData;
  label?: TranslationData;
  onChange: (val: number | null) => void;
}) {
  const [enteredFeet, setEnteredFeet] = useState('');
  const [enteredInches, setEnteredInches] = useState('');

  return (
    <InputWrapper className={className} error={error} label={label}>
      <div className="grid grid-cols-2 gap-x-6">
        <TextFieldInput
          hasError={error !== undefined}
          inputMode="numeric"
          maxLength={1}
          name="feet"
          onChange={(feet) => {
            feet = feet.replace(/[^0-9]/g, '');
            setEnteredFeet(feet);

            if (feet.trim() === '' && enteredInches.trim() === '') {
              onChange(null);
            } else if (/^[3-8]$/.test(feet)
                && /^(0|1|2|3|4|5|6|7|8|9|10|11)$/.test(enteredInches)) {
              const height = (parseInt(feet) * 12) + parseInt(enteredInches);
              onChange(height);
            } else {
              onChange(NaN);
            }
          }}
          rightAddOn={feetAddOn}
          value={enteredFeet}
        />
        <TextFieldInput
          hasError={error !== undefined}
          inputMode="numeric"
          maxLength={2}
          name="inches"
          onChange={(inches) => {
            inches = inches.replace(/[^0-9]/g, '');
            setEnteredInches(inches);

            if (inches.trim() === '' && enteredFeet.trim() === '') {
              onChange(null);
            } else if (/^[3-8]$/.test(enteredFeet)
                && /^(0|1|2|3|4|5|6|7|8|9|10|11)$/.test(inches)) {
              const height = (parseInt(enteredFeet) * 12) + parseInt(inches);
              onChange(height);
            } else {
              onChange(NaN);
            }
          }}
          rightAddOn={inchesAddOn}
          value={enteredInches}
        />
      </div>
    </InputWrapper>
  );
}
