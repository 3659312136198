import * as Sentry from '@sentry/react';
import { formatISO, sub } from 'date-fns';
import { useContext, useState } from 'react';

import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { JobRoleSummary } from '../components/JobRoleSummary';
import { Layout } from '../components/Layout';
import { Translation } from '../components/Translation';
import { Title } from '../components/Title';
import { DateField } from '../components/inputs/DateField';
import { InlineRadioField } from '../components/inputs/InlineRadioField';
import { SelectField } from '../components/inputs/SelectField';
import { TextField } from '../components/inputs/TextField';
import { appContext } from '../contexts/appContext';
import { initializeQuestionnaire } from '../lib/api/initializeQuestionnaire';
import { Language, NameSuffix, QuestionnaireStage, Sex } from '../lib/general';
import { GenderIdentity } from '../lib/general';
import { isValidDate } from '../lib/validation';
import { PiiStageErrors, PiiStageResponses } from '../types/general';
import { SubmitError } from '../components/SubmitError';
import { DropdownDirection } from '../lib/inputs';

export function PiiPage() {
  const {
    accessCode,
    initializer,
    responses: hhsResponses,
    setDrawerContent,
    setResponses: setHhsResponses,
    setQuestionnairePrn,
    setStage,
  } = useContext(appContext);

  const autoselectedLocation = initializer.locations.length === 1
    ? initializer.locations[0]
    : null;

  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<PiiStageErrors>({});
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState<PiiStageResponses>({
    dateOfBirth: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    location: autoselectedLocation !== null
      ? {
        prn: autoselectedLocation.prn,
      }
      : null,
    middleName: '',
    nameSuffix: null,
    phoneNumber: '',
    sex: null,
    ssnLast4: '',
  });

  async function trySubmit() {
    const _errors: PiiStageErrors = {};

    if (responses.firstName.trim().length === 0) {
      _errors.firstName = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (responses.firstName.includes('#')) {
      _errors.firstName = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.middleName.includes('#')) {
      _errors.middleName = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.lastName.trim().length === 0) {
      _errors.lastName = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (responses.lastName.includes('#')) {
      _errors.lastName = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    const today = new Date();
    const max = formatISO(sub(today, { years: 14 }), { representation: 'date' });
    const min = formatISO(sub(today, { years: 100 }), { representation: 'date' });

    if (!isValidDate({ date: responses.dateOfBirth, max, min })) {
      _errors.dateOfBirth = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.sex === null) {
      _errors.sex = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    }

    const ssnRegex = /^[0-9]{4}$/;

    if (responses.ssnLast4.trim().length === 0) {
      _errors.ssnLast4 = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (!ssnRegex.test(responses.ssnLast4) || responses.ssnLast4 === '0000') {
      _errors.ssnLast4 = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    if (responses.location === null) {
      _errors.location = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    }

    const emailRegex = /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

    if (responses.emailAddress.trim().length === 0) {
      _errors.emailAddress = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (!emailRegex.test(responses.emailAddress)) {
      _errors.emailAddress = {
        [Language.ENGLISH]: 'This field is invalid.',
        [Language.SPANISH]: 'Este campo es inválido.',
      };
    }

    const phoneRegex = /^[0-9]{10}$/;

    if (responses.phoneNumber.trim().length === 0) {
      _errors.phoneNumber = {
        [Language.ENGLISH]: 'This field is required.',
        [Language.SPANISH]: 'Este campo es obligatorio.',
      };
    } else if (!phoneRegex.test(responses.phoneNumber)) {
      _errors.phoneNumber = {
        [Language.ENGLISH]: 'Phone number is invalid (10 digits).',
        [Language.SPANISH]: 'Número de teléfono es inválido (10 dígitos).',
      };
    }

    setErrors(_errors);

    if (Object.keys(_errors).length === 0) {
      setError(false);
      setLoading(true);

      try {
        const response = await initializeQuestionnaire({
          accessCode,
          responses,
        });

        if (response.entryAllowed) {
          setQuestionnairePrn(response.questionnairePrn);
          setStage(QuestionnaireStage.HEALTH_HISTORY);
        } else {
          setStage(QuestionnaireStage.PROHIBITED);
          setLoading(false);
        }
      } catch (err) {
        Sentry.captureException(err);
        setError(true);
        setLoading(false);
      }
    } else {
      const errors = document.getElementsByClassName('has-error');

      if (errors.length > 0) {
        const firstError = errors[0] as HTMLElement;

        scrollTo({
          behavior: 'smooth',
          top: firstError.offsetTop - 74,
        });
      }
    }
  }

  const rmeModule = initializer.jobRole.modules.respiratorMedicalEvaluation;

  return (
    <Layout>
      <Title>
        <Translation data={{
          [Language.ENGLISH]: 'Your Personal Information',
          [Language.SPANISH]: 'Su Información Personal',
        }} />
      </Title>
      <div className="mb-8 px-4 sm:px-0">
        <Translation data={{
          [Language.ENGLISH]: 'Please provide your personal information below so that we may identify you and distinguish you from other employees. Your information is never shared with third parties without your consent. ',
          [Language.SPANISH]: 'Por favor, facilítenos a continuación su información personal para que podamos identificarle y distinguirle de otros empleados. Su información nunca se comparte con terceros sin su consentimiento. ',
        }} />
        <a className="text-primary hover:underline cursor-pointer" onClick={() => {
          setDrawerContent('privacy');
        }}>
          <Translation data={{
            [Language.ENGLISH]: 'Click here',
            [Language.SPANISH]: 'Haga click aquí',
          }} />
        </a>
        <Translation data={{
          [Language.ENGLISH]: ' to view our Privacy Policy.',
          [Language.SPANISH]: ' para consultar nuestra Política de Privacidad.',
        }} />
      </div>
      <Card title={{
        [Language.ENGLISH]: 'Your Job Role & Respirators',
        [Language.SPANISH]: 'Su Función Laboral y Los Respiradores',
      }}>
        <div className="flex flex-col gap-4">
          <Translation data={{
            [Language.ENGLISH]: `The following is a summary of the type${rmeModule !== undefined && rmeModule.respiratorTypeUsages.length > 1 ? 's' : ''} of respirator you are being medically evaluated for today. If this information does not look correct, please contact your supervisor or hiring point of contact.`,
            [Language.SPANISH]: `A continuación, encontrará un resumen ${rmeModule !== undefined && rmeModule.respiratorTypeUsages.length > 1 ? 'de los tipos de respirador para los' : 'del tipo de respirador para lo'} que está siendo evaluado médicamente hoy. Si esta información no le parece correcta, póngase en contacto con su supervisor o punto de contacto de contratación.`,
          }} />
          <JobRoleSummary locationName={autoselectedLocation?.name} />
        </div>
      </Card>
      <Card title={{
        [Language.ENGLISH]: 'Basic Details',
        [Language.SPANISH]: 'Detalles Básicos',
      }}>
        <div className="grid grid-cols-3 gap-x-6 gap-y-8 mb-8 sm:grid-cols-11">
          <TextField
            className="col-span-3"
            error={errors.firstName}
            label={{
              [Language.ENGLISH]: 'First Name',
              [Language.SPANISH]: 'Nombre',
            }}
            maxLength={64}
            name="firstName"
            onChange={(firstName) => {
              setResponses({ ...responses, firstName });
            }}
            tabIndex={0}
            value={responses.firstName}
          />
          <TextField
            className="col-span-3"
            error={errors.middleName}
            label={{
              [Language.ENGLISH]: 'Middle Name',
              [Language.SPANISH]: 'Segundo Nombre',
            }}
            maxLength={64}
            name="middleName"
            onChange={(middleName) => {
              setResponses({ ...responses, middleName });
            }}
            tabIndex={0}
            value={responses.middleName}
          />
          <TextField
            className="col-span-2 sm:col-span-3"
            error={errors.lastName}
            label={{
              [Language.ENGLISH]: 'Last Name',
              [Language.SPANISH]: 'Apellido',
            }}
            maxLength={64}
            name="lastName"
            onChange={(lastName) => {
              setResponses({ ...responses, lastName });
            }}
            tabIndex={0}
            value={responses.lastName}
          />
          <SelectField<NameSuffix>
            className="sm:col-span-2"
            label={{
              [Language.ENGLISH]: 'Suffix',
              [Language.SPANISH]: 'Sufijo',
            }}
            onChange={(nameSuffix) => {
              setResponses({ ...responses, nameSuffix });
            }}
            opens={DropdownDirection.LEFT}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Jr.',
                  [Language.SPANISH]: 'Jr.',
                },
                value: NameSuffix.JR,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Sr.',
                  [Language.SPANISH]: 'Sr.',
                },
                value: NameSuffix.SR,
              },
              {
                label: {
                  [Language.ENGLISH]: 'II',
                  [Language.SPANISH]: 'II',
                },
                value: NameSuffix.II,
              },
              {
                label: {
                  [Language.ENGLISH]: 'III',
                  [Language.SPANISH]: 'III',
                },
                value: NameSuffix.III,
              },
              {
                label: {
                  [Language.ENGLISH]: 'IV',
                  [Language.SPANISH]: 'IV',
                },
                value: NameSuffix.IV,
              },
              {
                label: {
                  [Language.ENGLISH]: 'V',
                  [Language.SPANISH]: 'V',
                },
                value: NameSuffix.V,
              },
            ]}
            value={responses.nameSuffix}
          />
        </div>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 mb-8 sm:grid-cols-3">
          <DateField
            error={errors.dateOfBirth}
            label={{
              [Language.ENGLISH]: 'Date of Birth',
              [Language.SPANISH]: 'Fecha de Nacimiento',
            }}
            onChange={(dateOfBirth) => {
              setResponses({ ...responses, dateOfBirth });
            }}
            value={responses.dateOfBirth}
          />
          <TextField
            error={errors.ssnLast4}
            inputMode="numeric"
            label={{
              [Language.ENGLISH]: 'SSN (Last 4 Digits)',
              [Language.SPANISH]: 'SSN (4 Últimos Dígitos)',
            }}
            maxLength={4}
            name="ssnLast4"
            onChange={(ssnLast4) => {
              ssnLast4 = ssnLast4.trim().replace(/[^0-9]/g, '');
              setResponses({ ...responses, ssnLast4 });
            }}
            tabIndex={0}
            value={responses.ssnLast4}
          />
        </div>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
          <InlineRadioField<Sex>
            error={errors.sex}
            label={{
              [Language.ENGLISH]: 'Sex Assigned at Birth',
              [Language.SPANISH]: 'Sexo Asignado al Nacer',
            }}
            onChange={(sex) => {
              setResponses({ ...responses, sex });
            }}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Male',
                  [Language.SPANISH]: 'Hombre',
                },
                value: Sex.MALE,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Female',
                  [Language.SPANISH]: 'Mujer',
                },
                value: Sex.FEMALE,
              },
            ]}
            value={responses.sex}
          />
          <SelectField<GenderIdentity>
            label={{
              [Language.ENGLISH]: 'Gender Identity',
              [Language.SPANISH]: 'Identidad de Género',
            }}
            onChange={(genderIdentity) => {
              setHhsResponses({ ...hhsResponses, genderIdentity });
            }}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Man',
                  [Language.SPANISH]: 'Hombre',
                },
                value: GenderIdentity.MAN,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Woman',
                  [Language.SPANISH]: 'Mujer',
                },
                value: GenderIdentity.WOMAN,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Transgender',
                  [Language.SPANISH]: 'Transgénero',
                },
                value: GenderIdentity.TRANSGENDER,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Non-binary / Non-conforming',
                  [Language.SPANISH]: 'No binario / No conforme',
                },
                value: GenderIdentity.NONBINARY,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Prefer not to answer',
                  [Language.SPANISH]: 'Prefiero no contestar',
                },
                value: GenderIdentity.PREFER_NOT_TO_ANSWER,
              },
            ]}
            value={hhsResponses.genderIdentity}
          />
        </div>
      </Card>
      {autoselectedLocation === null && (
        <Card title={{
          [Language.ENGLISH]: 'Location',
          [Language.SPANISH]: 'Ubicación',
        }}>
          <SelectField<string>
            error={errors.location}
            label={{
              [Language.ENGLISH]: 'Your Work Location',
              [Language.SPANISH]: 'Ubicación de su Trabajo:',
            }}
            onChange={(locationPrn) => {
              setResponses({
                ...responses,
                location: locationPrn !== null
                  ? {
                    prn: locationPrn,
                  }
                  : null,
              });
            }}
            options={initializer.locations.map((location) => ({
              label: {
                [Language.ENGLISH]: location.name,
                [Language.SPANISH]: location.name,
              },
              value: location.prn,
            }))}
            value={responses.location?.prn ?? null}
          />
        </Card>
      )}
      <Card title={{
        [Language.ENGLISH]: 'Contact Info',
        [Language.SPANISH]: 'Información de Contaco',
      }}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
          <TextField
            error={errors.emailAddress}
            label={{
              [Language.ENGLISH]: 'Email Address',
              [Language.SPANISH]: 'Dirección de Correo Electrónico',
            }}
            name="emailAddress"
            onChange={(emailAddress) => {
              setResponses({ ...responses, emailAddress });
            }}
            value={responses.emailAddress}
          />
          <TextField
            error={errors.phoneNumber}
            inputMode="numeric"
            label={{
              [Language.ENGLISH]: 'Phone Number',
              [Language.SPANISH]: 'Número de Teléfono',
            }}
            maxLength={10}
            name="phoneNumber"
            onChange={(phoneNumber) => {
              setResponses({ ...responses, phoneNumber });
            }}
            value={responses.phoneNumber}
          />
        </div>
      </Card>
      {error && <SubmitError />}
      <div className="flex w-full justify-center mb-8">
        <Button disabled={loading} loading={loading} onClick={() => {
          void trySubmit();
        }}>
          <Translation data={{
            [Language.ENGLISH]: loading ? 'Loading Questionnaire...' : 'Continue',
            [Language.SPANISH]: loading ? 'Cargando Cuestionario...' : 'Continuar',
          }} />
        </Button>
      </div>
    </Layout>
  );
}
