import { AppInitializer, InitializeAppResponse } from '../../types/api';

export async function initializeApp(accessCode: string): Promise<AppInitializer | null> {
  const result = await fetch(
    `${import.meta.env.VITE_API_BASE_URL}/app-initializer/${accessCode}`,
  );

  if (result.status === 404) {
    return null;
  } else if (result.status !== 200) {
    throw new Error();
  }

  return (await result.json() as InitializeAppResponse).appInitializer;
}
