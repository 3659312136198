import { ReactNode } from 'react';

export function Title({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <h1 className="font-display text-3xl mb-8 px-4 sm:px-0">
      {children}
    </h1>
  )
}
