import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import { no } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function MedicationsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Medications',
      [Language.SPANISH]: 'Medicamentos',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscLungMedications}
        label={{
          [Language.ENGLISH]: 'Do you currently take any medicines for breathing or lung problems that were not covered in the questions above?',
          [Language.SPANISH]: '¿Toma actualmente algún medicamento para problemas respiratorios o pulmonares que no se hayan incluido en las preguntas anteriores?',
        }}
        onChange={setResponse('miscLungMedications')}
        options={YES_NO_OPTIONS}
        value={responses.miscLungMedications}
      />
      {reqs.miscLungMedicationsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscLungMedicationsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your lung problem that wasn\'t addressed in earlier questions:',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su problema pulmonar que no se hayan tratado en las preguntas anteriores:',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscLungMedicationsDescription"
            onChange={setResponse('miscLungMedicationsDescription')}
            value={responses.miscLungMedicationsDescription}
          />
        </div>
      )}
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.miscHeartMedications}
          label={{
            [Language.ENGLISH]: 'Do you currently take any medicines for heart problems that were not covered in the questions above?',
            [Language.SPANISH]: '¿Toma actualmente algún medicamento para problemas cardíacos que no se haya incluido en las preguntas anteriores?',
          }}
          onChange={setResponse('miscHeartMedications')}
          options={YES_NO_OPTIONS}
          value={responses.miscHeartMedications}
        />
      </div>
      {reqs.miscHeartMedicationsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscHeartMedicationsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your heart problem that wasn\'t addressed in earlier questions:',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su problema cardíaco que no se hayan tratado en las preguntas anteriores:',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscHeartMedicationsDescription"
            onChange={setResponse('miscHeartMedicationsDescription')}
            value={responses.miscHeartMedicationsDescription}
          />
        </div>
      )}
      {reqs.miscMedicationsAdverseEffects && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.miscMedicationsAdverseEffects}
            label={{
              [Language.ENGLISH]: `Do your ${no(responses.miscHeartMedications) ? 'lung' : no(responses.miscLungMedications) ? 'heart' : 'lung or heart'} medicines make you feel sleepy, confused, or stop you from doing your job safely?`,
              [Language.SPANISH]: `¿Sus medicamentos para ${no(responses.miscHeartMedications) ? 'los pulmones' : no(responses.miscLungMedications) ? 'el corazón' : 'los pulmones o el corazón'} le hacen sentir somnoliento, confuso o le impiden realizar su trabajo con seguridad?`,
            }}
            onChange={setResponse('miscMedicationsAdverseEffects')}
            options={YES_NO_OPTIONS}
            value={responses.miscMedicationsAdverseEffects}
          />
        </div>
      )}
    </Card>
  );
}
