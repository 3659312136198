import * as Sentry from '@sentry/react';
import { CSSProperties, ReactNode, useCallback, useEffect, useState } from 'react';

import { appContext } from './contexts/appContext';
import { initializeApp } from './lib/api/initializeApp';
import { Language, QuestionnaireStage } from './lib/general';
import { HealthHistoryPage } from './pages/HealthHistoryPage';
import { LoadingPage } from './pages/LoadingPage';
import { PiiPage } from './pages/PiiPage';
import { ProhibitedPage } from './pages/ProhibitedPage';
import { ResultPage } from './pages/ResultPage';
import { StartPage } from './pages/StartPage';
import { AppInitializer, RespiratorMedicalEvaluationResult } from './types/api';
import { HealthHistoryStageResponses } from './types/general';
import { ErrorPage } from './pages/ErrorPage';

export function App() {
  const [error, setError] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [appInitializer, setAppInitializer] = useState<AppInitializer | null>(null);

  useEffect(() => {
    async function init() {
      const queryString = window.location.search;

      // Only accept a perfectly-formed querystring
      if (!/^\?accessCode=[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(queryString)) {
        setError(true);
        return;
      }

      const accessCode = queryString.substring(12);  // Only the UUID portion
      setAccessCode(accessCode);

      try {
        const initializer = await initializeApp(accessCode);

        if (initializer === null) {
          // Initializer request succeeded but access code was bad
          setError(true);
        } else {
          setAppInitializer(initializer);
        }
      } catch (err) {
        // Initializer request failed
        Sentry.captureException(err);
        setError(true);
      }
    }

    void init();
  }, []);

  if (error) {
    return <ErrorPage />;
  }

  if (appInitializer === null) {
    return <LoadingPage />;
  }

  return <InitializedApp accessCode={accessCode} initializer={appInitializer} />;
}

function InitializedApp({
  accessCode,
  initializer,
}: {
  accessCode: string;
  initializer: AppInitializer;
}) {
  const [drawerContent, setDrawerContent] = useState<'attestation' | 'tou' | 'privacy' | null>(null);
  const [language, setLanguage] = useState(Language.ENGLISH);
  const [questionnairePrn, setQuestionnairePrn] = useState('');
  const [result, setResult] = useState<RespiratorMedicalEvaluationResult | null>(null);
  const [stage, setStage] = useState(QuestionnaireStage.START);
  const [submitting, setSubmitting] = useState(false);
  const [responses, setResponses] = useState<HealthHistoryStageResponses>({
    allergies: null,
    allergiesAnaphylaxis: null,
    allergiesAtWorkEasilyTreatable: null,
    allergiesHadAtWork: null,
    allergiesKnowsTriggers: null,
    allergiesMakesRespiratorUseUnsafe: null,
    allergiesMedications: null,
    allergiesMedicationsAdverseEffects: null,
    allergiesPrescribedEpipen: null,
    allergiesTriggersAtWork: null,
    angina: null,
    anginaAssessedByDoctor: null,
    anginaCardiac: null,
    anginaControlled: null,
    anginaLastYear: null,
    anginaPredictable: null,
    anginaRescueMedUseLastYear: null,
    anginaRestingOrDuringLightActivity: null,
    anginaWorkAccommodations: null,
    arrhythmia: null,
    arrhythmiaOtherThanHeartMurmur: null,
    arrhythmiaTypes: [],
    arrhythmiaWorkAccommodations: null,
    asbestosis: null,
    asbestosisFutureRespiratorUseExpectedNotOk: null,
    asbestosisHospitalStayLast2Years: null,
    asbestosisImpactedWorkLast2Years: null,
    asbestosisMedications: null,
    asbestosisMedicationsAccessibleAtWork: null,
    asbestosisPriorRespiratorUseNotOk: null,
    asbestosisSeverity: null,
    asbestosisSxDuringAdl: null,
    asbestosisWorkAccommodations: null,
    asbestosisWorseLastYear: null,
    asthma: null,
    asthmaAggravatorsAtWork: null,
    asthmaCurrent: null,
    asthmaFutureRespiratorUseExpectedNotOk: null,
    asthmaHospitalStayLast2Years: null,
    asthmaImpactedWorkLast2Years: null,
    asthmaMedications: null,
    asthmaMedicationsAccessibleAtWork: null,
    asthmaPriorRespiratorUseNotOk: null,
    asthmaSeverity: null,
    asthmaSxDuringAdl: null,
    asthmaWorkAccommodations: null,
    asthmaWorseLastYear: null,
    backInjury: null,
    backInjuryResolved: null,
    backPain: null,
    backPainPredictsRespiratorWillExacerbate: null,
    brokenEardrum: null,
    brokenEardrumResolved: null,
    brokenRibs: null,
    brokenRibsCanExerciseWithoutSob: null,
    brokenRibsCanPerformWorkFully: null,
    brokenRibsResolved: null,
    implantableCardiacDevice: null,
    implantableCardiacDeviceFixedArrhythmia: null,
    implantableCardiacDeviceType: null,
    implantableCardiacDeviceWorksAroundStrongMagneticFields: null,
    implantableCardiacDeviceWorksWithPossibilityOfCollisions: null,
    chestPainBreathingDeeply: null,
    chestPainDuringPhysicalActivity: null,
    chestPainFrequent: null,
    chestPainInterferingWithWork: null,
    chronicBronchitis: null,
    chronicBronchitisFutureRespiratorUseExpectedNotOk: null,
    chronicBronchitisHospitalStayLast2Years: null,
    chronicBronchitisImpactedWorkLast2Years: null,
    chronicBronchitisMedications: null,
    chronicBronchitisMedicationsAccessibleAtWork: null,
    chronicBronchitisPriorRespiratorUseNotOk: null,
    chronicBronchitisSeverity: null,
    chronicBronchitisSxDuringAdl: null,
    chronicBronchitisWorkAccommodations: null,
    chronicBronchitisWorseLastYear: null,
    claustrophobia: null,
    claustrophobiaCurrent: null,
    claustrophobiaFutureRespiratorUseExpectedOk: null,
    claustrophobiaMedications: null,
    claustrophobiaPriorRespiratorUseOk: null,
    claustrophobiaWorkInConfinedSpaces: null,
    claustrophobiaMedicationsAdverseEffects: null,
    colorBlindness: null,
    colorBlindnessHasTroubleSeeingWarnings: null,
    colorBlindnessWillHaveHelpSelectingColorCodedParts: null,
    coughBloodyInLastMonth: null,
    coughFitsDifficultToBreatheMoreThan10Seconds: null,
    coughMedicationAccessibleAtWork: null,
    coughMedicationRequiredForFits: null,
    coughPredictsDifficultyUsingRespirator: null,
    coughProducingPhlegm: null,
    coughSeverity: null,
    coughWakingEarly: null,
    coughWhenLying: null,
    diabetes: null,
    diabetesCurrent: null,
    diabetesMedications: null,
    diabetesSyncopeAtWork: null,
    diabetesWarningSigns: null,
    diabetesWorkAccommodations: null,
    difficultyBendingKnees: null,
    difficultyClimbingStairs25Pounds: null,
    difficultyMovingHeadHorizontally: null,
    difficultyMovingExtremities: null,
    difficultyMovingHeadVertically: null,
    difficultyMovingPredictsRespiratorWillExacerbate: null,
    difficultyMovingSeverity: null,
    difficultySquatting: null,
    emphysema: null,
    emphysemaFutureRespiratorUseExpectedNotOk: null,
    emphysemaHospitalStayLast2Years: null,
    emphysemaImpactedWorkLast2Years: null,
    emphysemaMedications: null,
    emphysemaMedicationsAccessibleAtWork: null,
    emphysemaPriorRespiratorUseNotOk: null,
    emphysemaSeverity: null,
    emphysemaSxDuringAdl: null,
    emphysemaWorkAccommodations: null,
    emphysemaWorseLastYear: null,
    genderIdentity: null,
    hearingAids: null,
    hearingAidsCanUseAtWork: null,
    hearingAidsNeedsForWork: null,
    hearingLoss: null,
    hearingLossCanPerformWorkSafely: null,
    hearingLossNeedsHearingForWork: null,
    heartAttack: null,
    heartAttackCardiacHospitalVisitLastYear: null,
    heartAttackCoronaryArteryProcedureLast6Months: null,
    heartAttackHadStressTest: null,
    heartAttackLessThan3YearsAgo: null,
    heartAttackRestrictionsRemoved: null,
    heartAttackStressTestFailed: null,
    heartAttackStressTestShowedArrhythmiaOrIschemia: null,
    heartAttackWorkAccommodations: null,
    heartCondition150MinutesActivityPerWeek: null,
    heartConditionAdditionalSxLast2Years: null,
    heartConditionHighCholesterol: null,
    heartConditionRespiratorUseExpectedOk: null,
    heartFailure: null,
    heartFailureFunctionalClassification: null,
    heartFailureWorkAccommodations: null,
    heartMurmur: null,
    heartMurmurAdditionalSx: null,
    heartMurmurCheckedByDoctor: null,
    heartMurmurClearedByDoctor: null,
    heartburn: null,
    heartburnCardiac: null,
    heartburnRelievedByAntacids: null,
    height: null,
    hypertension: null,
    hypertensionCausedProblemsAtWork: null,
    hypertensionCurrent: null,
    hypertensionDoctorVisitLastYear: null,
    hypertensionMedications: null,
    hypertensionMedicationsAdverseEffects: null,
    hypertensionRestrictions: null,
    hypertensionTypicalBpDiastolic: null,
    hypertensionTypicalBpSystolic: null,
    hypertensionWorriesAboutWork: null,
    hyposmia: null,
    hyposmiaCanSmellWorkDangers: null,
    hyposmiaCurrent: null,
    hyposmiaNeedsSmellForWork: null,
    hyposmiaSeverity: null,
    lungCancer: null,
    lungCancerCurrentProblemsMakeRespiratorUseDifficult: null,
    lungCancerInRemission: null,
    lungCancerInRemissionAtLeastFiveYears: null,
    lungCancerProblemsWithSar: null,
    lungCancerUsedSarAtWorkSinceRemission: null,
    lungCancerWorkAccommodations: null,
    miscChestInjuriesOrSurgeries: null,
    miscChestInjuriesOrSurgeriesCanExerciseWithoutSob: null,
    miscChestInjuriesOrSurgeriesCanPerformWorkFully: null,
    miscChestInjuriesOrSurgeriesResolved: null,
    miscEarInjuries: null,
    miscEarInjuriesLastingComplications: null,
    miscEarInjuriesLastingComplicationsDescription: '',
    miscHearingProblems: null,
    miscHearingProblemsDescription: '',
    miscHeartMedications: null,
    miscHeartMedicationsDescription: '',
    miscHeartProblems: null,
    miscHeartProblemsDescription: '',
    miscHeartSymptoms: null,
    miscHeartSymptomsDescription: '',
    miscLungMedications: null,
    miscLungMedicationsDescription: '',
    miscLungProblems: null,
    miscLungProblemsDescription: '',
    miscLungSymptoms: null,
    miscLungSymptomsDescription: '',
    miscMedicationsAdverseEffects: null,
    miscMusculoskeletalProblems: null,
    miscMusculoskeletalProblemsDescription: '',
    miscVisionProblems: null,
    miscVisionProblemsDescription: '',
    painStiffnessBendingWaist: null,
    peripheralEdema: null,
    peripheralEdemaCurrent: null,
    pneumonia: null,
    pneumoniaResolved: null,
    pneumothorax: null,
    pneumothoraxCurrentProblems: null,
    pneumothoraxKnowsCause: null,
    pneumothoraxMostRecent: null,
    pneumothoraxMultiple: null,
    priorRespiratorUse: null,
    priorRespiratorUseAnxiety: null,
    priorRespiratorUseAnxietyCause: null,
    priorRespiratorUseAnxietyCurrent: null,
    priorRespiratorUseAnxietyCurrentlyWorksWithCause: null,
    priorRespiratorUseAnxietyTookOffRespiratorUnsafely: null,
    priorRespiratorUseAnxietyAbleToUseAlternateRespirator: null,
    priorRespiratorUseEyeIrritation: null,
    priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator: null,
    priorRespiratorUseEyeIrritationAggravatorsAtWork: null,
    priorRespiratorUseEyeIrritationCause: null,
    priorRespiratorUseEyeIrritationCurrent: null,
    priorRespiratorUseEyeIrritationImpactsWork: null,
    priorRespiratorUseFatigue: null,
    priorRespiratorUseFatigueAdditionalSx: null,
    priorRespiratorUseFatigueCausedByHealthProblem: null,
    priorRespiratorUseFatigueCausedByPoorFitness: null,
    priorRespiratorUseFatigueCurrent: null,
    priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk: null,
    priorRespiratorUseFatigueImpactsWork: null,
    priorRespiratorUseMiscProblems: null,
    priorRespiratorUseMiscProblemsDescription: '',
    priorRespiratorUseSkinProblems: null,
    priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator: null,
    priorRespiratorUseSkinProblemsAggravatorsAtWork: null,
    priorRespiratorUseSkinProblemsCanUsePpe: null,
    priorRespiratorUseSkinProblemsCause: null,
    priorRespiratorUseSkinProblemsCurrent: null,
    rescueInhalerUseAtLeast3PerWeek: null,
    seizures: null,
    seizuresControlled: null,
    seizuresHadAtWork: null,
    seizuresMedications: null,
    seizuresMedicationsAdverseEffects: null,
    seizuresMostRecent: null,
    seizuresMultiple: null,
    seizuresWarningSigns: null,
    seizuresWorkAccommodations: null,
    seizuresWorksAlone: null,
    silicosis: null,
    silicosisFutureRespiratorUseExpectedNotOk: null,
    silicosisHospitalStayLast2Years: null,
    silicosisImpactedWorkLast2Years: null,
    silicosisMedications: null,
    silicosisMedicationsAccessibleAtWork: null,
    silicosisPriorRespiratorUseNotOk: null,
    silicosisSeverity: null,
    silicosisSxDuringAdl: null,
    silicosisWorkAccommodations: null,
    silicosisWorseLastYear: null,
    skippedHeartbeatLastTwoYears: null,
    smoker: null,
    smokerTroubleBreathing: null,
    speakToProvider: null,
    sob: null,
    sobFutureRespiratorUseExpectedNotOk: null,
    sobHospitalStayLast2Years: null,
    sobImpactedWorkLast2Years: null,
    sobInterferingWithWork: null,
    sobMedications: null,
    sobMedicationsAccessibleAtWork: null,
    sobPriorRespiratorUseNotOk: null,
    sobSeverity: null,
    sobWalkingFastOrIncline: null,
    sobWalkingOwnPaceLevel: null,
    sobWalkingWithOthers: null,
    sobWashingOrDressing: null,
    sobWorkAccommodations: null,
    sobWorseLastYear: null,
    stroke: null,
    strokeLastingEffectsPreventingRespiratorUse: null,
    strokeMostRecentMoreThan2YearsAgo: null,
    strokeSubsequentTia: null,
    strokeWorkAccommodations: null,
    tuberculosis: null,
    tuberculosisCurrentMedications: null,
    tuberculosisCurrentMedicationsForLatentTbOnly: null,
    tuberculosisResolved: null,
    visionCorrection: null,
    visionCorrectionCanWearAndSeeAtWork: null,
    visionLoss: null,
    visionLossCanPerformWorkSafely: null,
    visionLossExpectsSuccessfulScbaOrFullFaceUse: null,
    visionLossPriorScbaOrFullFaceUse: null,
    visionLossPriorScbaOrFullFaceUseSuccessful: null,
    visionLossResolved: null,
    weaknessCanEscapeDanger: null,
    weaknessImpactingWork: null,
    weaknessInExtremities: null,
    weaknessMakingCarryingEquipmentDifficult: null,
    weaknessPredictsRespiratorWillExacerbate: null,
    weaknessPriorSarUseOkLast2Years: null,
    weaknessUsedSarLast2Years: null,
    weaknessWorkAccommodations: null,
    weaknessWorsening: null,
    weight: null,
    wheeze: null,
    wheezeAggravatorsAtWork: null,
    wheezeFutureRespiratorUseExpectedNotOk: null,
    wheezeHospitalStayLast2Years: null,
    wheezeImpactedWorkLast2Years: null,
    wheezeInterferingWithWork: null,
    wheezeMedications: null,
    wheezeMedicationsAccessibleAtWork: null,
    wheezePriorRespiratorUseNotOk: null,
    wheezeSeverity: null,
    wheezeWorkAccommodations: null,
    wheezeWorseLastYear: null,
  });

  const handleBeforeUnload = useCallback((evt: BeforeUnloadEvent) => {
    if (![
      QuestionnaireStage.START,
      QuestionnaireStage.PROHIBITED,
      QuestionnaireStage.RESULT,
    ].includes(stage)) {
      evt.preventDefault();
      evt.returnValue = '';
    }
  }, [stage]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  let page: ReactNode;

  switch (stage) {
    case QuestionnaireStage.START:
      page = <StartPage />;
      break;
    case QuestionnaireStage.PII:
      page = <PiiPage />;
      break;
    case QuestionnaireStage.PROHIBITED:
      page = <ProhibitedPage />;
      break;
    case QuestionnaireStage.HEALTH_HISTORY:
      page = <HealthHistoryPage />;
      break;
    case QuestionnaireStage.RESULT:
      page = <ResultPage />;
      break;
  }

  useEffect(() => {
    scrollTo(0, 0);
  }, [stage]);

  return (
    <appContext.Provider value={{
      accessCode,
      drawerContent,
      initializer,
      language,
      questionnairePrn,
      responses,
      result,
      setDrawerContent,
      setLanguage,
      setQuestionnairePrn,
      setResult,
      setResponses,
      setStage,
      setSubmitting,
      stage,
      submitting,
    }}>
      <div style={{
        '--primary-color': initializer.branding.primaryColor,
      } as CSSProperties}>
        {page}
      </div>
    </appContext.Provider>
  );
}
