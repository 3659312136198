import { Card } from '../../components/Card';
import { HeightField } from '../../components/inputs/HeightField';
import { NumberTextField } from '../../components/inputs/NumberTextField';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';
import { Language } from '../../lib/general';

export function HeightWeightBlock({
  errors,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Height & Weight',
      [Language.SPANISH]: 'Altura & Peso',
    }}>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
        <HeightField
          className="col-span-2"
          error={errors.height}
          feetAddOn={{
            [Language.ENGLISH]: 'ft',
            [Language.SPANISH]: 'pies',
          }}
          inchesAddOn={{
            [Language.ENGLISH]: 'in',
            [Language.SPANISH]: 'pulg',
          }}
          label={{
            [Language.ENGLISH]: 'Height',
            [Language.SPANISH]: 'Altura',
          }}
          onChange={setResponse('height')}
        />
        <NumberTextField
          error={errors.weight}
          label={{
            [Language.ENGLISH]: 'Weight',
            [Language.SPANISH]: 'Peso',
          }}
          max={999}
          min={10}
          name="weight"
          onChange={setResponse('weight')}
          rightAddOn={{
            [Language.ENGLISH]: 'lbs',
            [Language.SPANISH]: 'lbs',
          }}
        />
      </div>
    </Card>
  );
}
