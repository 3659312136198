import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function OtherLungSymptomsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Lung Symptoms',
      [Language.SPANISH]: 'Otros Síntomas Pulmonares',
    }}>
      <InlineRadioField<YesNo>
        error={errors.miscLungSymptoms}
        label={{
          [Language.ENGLISH]: 'Do you currently have any other symptoms not covered in the questions above that may be related to a lung problem?',
          [Language.SPANISH]: '¿Ha tenido algún otro síntoma no contemplado en las preguntas anteriores que pueda estar relacionado con un problema pulmonar?',
        }}
        onChange={setResponse('miscLungSymptoms')}
        options={YES_NO_OPTIONS}
        value={responses.miscLungSymptoms}
      />
      {reqs.miscLungSymptomsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.miscLungSymptomsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about your lung symptoms:',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre su(s) síntoma(s) pulmonar(es):',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="miscLungSymptomsDescription"
            onChange={setResponse('miscLungSymptomsDescription')}
            value={responses.miscLungSymptomsDescription}
          />
        </div>
      )}
    </Card>
  );
}
