import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, YesNo } from '../../lib/general';
import { no, yes } from '../../lib/validation';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS } from './options';

export function AnginaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Angina',
      [Language.SPANISH]: 'Angina',
    }}>
      <InlineRadioField<YesNo>
        error={errors.angina}
        label={{
          [Language.ENGLISH]: 'Have you ever had angina (chest pain caused by your heart)?',
          [Language.SPANISH]: '¿Ha tenido alguna vez una angina de pecho (dolor en el pecho causado por el corazón)?',
        }}
        onChange={setResponse('angina')}
        options={YES_NO_OPTIONS}
        value={responses.angina}
      />
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.chestPainFrequent}
          label={{
            [Language.ENGLISH]: 'Have you ever had frequent pain or tightness in your chest?',
            [Language.SPANISH]: '¿Ha tenido con frecuencia dolor u opresión en el pecho?',
          }}
          onChange={setResponse('chestPainFrequent')}
          options={YES_NO_OPTIONS}
          value={responses.chestPainFrequent}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.chestPainDuringPhysicalActivity}
          label={{
            [Language.ENGLISH]: 'Have you ever had pain or tightness in your chest during physical activity?',
            [Language.SPANISH]: '¿Ha tenido alguna vez dolor u opresión en el pecho durante la actividad física?',
          }}
          onChange={setResponse('chestPainDuringPhysicalActivity')}
          options={YES_NO_OPTIONS}
          value={responses.chestPainDuringPhysicalActivity}
        />
      </div>
      <div className="mt-8">
        <InlineRadioField<YesNo>
          error={errors.chestPainInterferingWithWork}
          label={{
            [Language.ENGLISH]: 'Have you ever had pain or tightness in your chest that interferes with your job?',
            [Language.SPANISH]: '¿Ha tenido alguna vez dolor u opresión en el pecho que interfiera con su trabajo?',
          }}
          onChange={setResponse('chestPainInterferingWithWork')}
          options={YES_NO_OPTIONS}
          value={responses.chestPainInterferingWithWork}
        />
      </div>
      {reqs.anginaAssessedByDoctor && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaAssessedByDoctor}
            label={{
              [Language.ENGLISH]: 'Has your chest pain been checked by a doctor?',
              [Language.SPANISH]: '¿Le ha examinado un médico su dolor en el pecho?',
            }}
            onChange={setResponse('anginaAssessedByDoctor')}
            options={YES_NO_OPTIONS}
            value={responses.anginaAssessedByDoctor}
          />
        </div>
      )}
      {reqs.anginaCardiac && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.anginaCardiac}
            label={{
              [Language.ENGLISH]: 'What did your doctor say was the cause of your chest pain?',
              [Language.SPANISH]: '¿Cuál dijo su médico que era la causa de su dolor en el pecho?',
            }}
            onChange={setResponse('anginaCardiac')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'My doctor said my chest pain is related to my heart',
                  [Language.SPANISH]: 'Mi médico dice que mi dolor en el pecho está relacionado con mi corazón',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'My doctor said my chest pain is NOT related to my heart',
                  [Language.SPANISH]: 'Mi médico dice que mi dolor en el pecho NO está relacionado con mi corazón'
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.anginaCardiac}
          />
        </div>
      )}
      {reqs.anginaLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaLastYear}
            label={{
              [Language.ENGLISH]: 'Have you had chest pain within the last twelve (12) months?',
              [Language.SPANISH]: '¿Ha tenido dolor en el pecho en los últimos doce (12) meses?',
            }}
            onChange={setResponse('anginaLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.anginaLastYear}
          />
        </div>
      )}
      {reqs.anginaRescueMedUseLastYear && !yes(responses.heartAttack)
          && (no(responses.anginaAssessedByDoctor) || yes(responses.anginaCardiac)) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaRescueMedUseLastYear}
            label={{
              [Language.ENGLISH]: 'Have you had to take medicine for sudden chest pain, like nitroglycerin or beta blockers, in the last year?',
              [Language.SPANISH]: '¿Ha tenido que tomar medicamentos para el dolor repentino en el pecho, como nitroglicerina o betabloqueantes, en el último año?',
            }}
            onChange={setResponse('anginaRescueMedUseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.anginaRescueMedUseLastYear}
          />
        </div>
      )}
      {reqs.heartConditionAdditionalSxLast2Years && !yes(responses.heartAttack)
          && (no(responses.anginaAssessedByDoctor) || yes(responses.anginaCardiac)) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionAdditionalSxLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you ever had trouble breathing or felt really tired while working?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido alguna vez problemas para respirar o se ha sentido muy cansado mientras trabajaba?',
            }}
            onChange={setResponse('heartConditionAdditionalSxLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionAdditionalSxLast2Years}
          />
        </div>
      )}
      {reqs.heartCondition150MinutesActivityPerWeek && !yes(responses.heartAttack)
          && (no(responses.anginaAssessedByDoctor) || yes(responses.anginaCardiac)) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartCondition150MinutesActivityPerWeek}
            label={{
              [Language.ENGLISH]: 'Do you get at least 150 minutes of physical activity per week?',
              [Language.SPANISH]: '¿Realiza al menos 150 minutos de actividad física a la semana?',
            }}
            onChange={setResponse('heartCondition150MinutesActivityPerWeek')}
            options={YES_NO_OPTIONS}
            value={responses.heartCondition150MinutesActivityPerWeek}
          />
        </div>
      )}
      {reqs.heartConditionHighCholesterol && !yes(responses.heartAttack)
          && (no(responses.anginaAssessedByDoctor) || yes(responses.anginaCardiac)) && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.heartConditionHighCholesterol}
            label={{
              [Language.ENGLISH]: 'Do you have high cholesterol?',
              [Language.SPANISH]: '¿Tiene el colesterol alto?',
            }}
            onChange={setResponse('heartConditionHighCholesterol')}
            options={YES_NO_OPTIONS}
            value={responses.heartConditionHighCholesterol}
          />
        </div>
      )}
      {reqs.anginaRestingOrDuringLightActivity && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaRestingOrDuringLightActivity}
            label={{
              [Language.ENGLISH]: 'Do you ever get chest pain when you\'re resting or doing light activity?',
              [Language.SPANISH]: '¿Alguna vez le duele el pecho cuando está descansando o realizando una actividad ligera?',
            }}
            onChange={setResponse('anginaRestingOrDuringLightActivity')}
            options={YES_NO_OPTIONS}
            value={responses.anginaRestingOrDuringLightActivity}
          />
        </div>
      )}
      {reqs.anginaPredictable && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaPredictable}
            label={{
              [Language.ENGLISH]: 'Is your chest pain predictable, for example only happening when you do some physical activity or exercise?',
              [Language.SPANISH]: '¿Su dolor en el pecho es predecible, por ejemplo, sólo le ocurre cuando realiza alguna actividad física o ejercicio?',
            }}
            onChange={setResponse('anginaPredictable')}
            options={YES_NO_OPTIONS}
            value={responses.anginaPredictable}
          />
        </div>
      )}
      {reqs.anginaControlled && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaControlled}
            label={{
              [Language.ENGLISH]: 'Do both you and your doctor think your chest pain is under control and well-managed?',
              [Language.SPANISH]: '¿Piensan tanto usted como su médico que su dolor en el pecho está bajo control y bien gestionado?',
            }}
            onChange={setResponse('anginaControlled')}
            options={YES_NO_OPTIONS}
            value={responses.anginaControlled}
          />
        </div>
      )}
      {reqs.anginaWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.anginaWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your chest pain?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su dolor en el pecho?',
            }}
            onChange={setResponse('anginaWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.anginaWorkAccommodations}
          />
        </div>
      )}
      {reqs.heartConditionRespiratorUseExpectedOk && !yes(responses.heartAttack)
          && yes(responses.anginaCardiac) && (
        <div className="mt-8">
          <StackedRadioField<YesNo>
            error={errors.heartConditionRespiratorUseExpectedOk}
            label={{
              [Language.ENGLISH]: 'Do you think you can use a respirator, or will it be too difficult because it makes breathing harder and you have a heart condition?',
              [Language.SPANISH]: '¿Cree que puede usar un respirador, o le resultará demasiado difícil porque le dificulta la respiración y tiene una afección cardiaca?',
            }}
            onChange={setResponse('heartConditionRespiratorUseExpectedOk')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I think I can use a respirator',
                  [Language.SPANISH]: 'Sí, creo que puedo usar un respirador',
                },
                value: YesNo.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, I think using a respirator will be too difficult',
                  [Language.SPANISH]: 'No, creo que usar un respirador será demasiado difícil',
                },
                value: YesNo.NO,
              },
            ]}
            value={responses.heartConditionRespiratorUseExpectedOk}
          />
        </div>
      )}
    </Card>
  );
}
