import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { StackedRadioField } from '../../components/inputs/StackedRadioField';
import { Language, PriorRespiratorUseProblemCause, YesNo, YesNoUnknown } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { YES_NO_OPTIONS, YES_NO_UNKNOWN_OPTIONS } from './options';

export function PriorRespiratorUseEyeIrritationBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Eye Irritation',
      [Language.SPANISH]: 'Irritación Ocular',
    }}>
      <InlineRadioField<YesNo>
        error={errors.priorRespiratorUseEyeIrritation}
        label={{
          [Language.ENGLISH]: 'Has using a respirator ever caused you eye irritation?',
          [Language.SPANISH]: '¿El uso de un respirador le ha causado alguna vez irritación ocular?',
        }}
        onChange={setResponse('priorRespiratorUseEyeIrritation')}
        options={YES_NO_OPTIONS}
        value={responses.priorRespiratorUseEyeIrritation}
      />
      {reqs.priorRespiratorUseEyeIrritationCurrent && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseEyeIrritationCurrent}
            label={{
              [Language.ENGLISH]: 'Do your eyes still get irritated when you use a respirator?',
              [Language.SPANISH]: '¿Se le siguen irritando los ojos cuando utiliza un respirador?',
            }}
            onChange={setResponse('priorRespiratorUseEyeIrritationCurrent')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseEyeIrritationCurrent}
          />
        </div>
      )}
      {reqs.priorRespiratorUseEyeIrritationImpactsWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.priorRespiratorUseEyeIrritationImpactsWork}
            label={{
              [Language.ENGLISH]: 'Does your eye irritation ever make it hard for you to see and cause safety issues at work?',
              [Language.SPANISH]: '¿Su irritación ocular le dificulta alguna vez la visión y le causa problemas de seguridad en el trabajo?',
            }}
            onChange={setResponse('priorRespiratorUseEyeIrritationImpactsWork')}
            options={YES_NO_OPTIONS}
            value={responses.priorRespiratorUseEyeIrritationImpactsWork}
          />
        </div>
      )}
      {reqs.priorRespiratorUseEyeIrritationCause && (
        <div className="mt-8">
          <StackedRadioField<PriorRespiratorUseProblemCause>
            error={errors.priorRespiratorUseEyeIrritationCause}
            label={{
              [Language.ENGLISH]: 'What was the cause of your eye irritation?',
              [Language.SPANISH]: '¿Cuál fue la causa de su irritación ocular?',
            }}
            onChange={setResponse('priorRespiratorUseEyeIrritationCause')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'The respirator itself',
                  [Language.SPANISH]: 'El propio respirador',
                },
                value: PriorRespiratorUseProblemCause.RESPIRATOR,
              },
              {
                label: {
                  [Language.ENGLISH]: 'Something at my work',
                  [Language.SPANISH]: 'Algo en mi trabajo',
                },
                value: PriorRespiratorUseProblemCause.WORK_ENVIRONMENT,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t know',
                  [Language.SPANISH]: 'No sé',
                },
                value: PriorRespiratorUseProblemCause.UNKNOWN,
              },
            ]}
            value={responses.priorRespiratorUseEyeIrritationCause}
          />
        </div>
      )}
      {reqs.priorRespiratorUseEyeIrritationAggravatorsAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNoUnknown>
            error={errors.priorRespiratorUseEyeIrritationAggravatorsAtWork}
            label={{
              [Language.ENGLISH]: 'Will you still be working around the thing that causes your eye irritation?',
              [Language.SPANISH]: '¿Seguirá trabajando alrededor de la cosa que le irrita los ojos?',
            }}
            onChange={setResponse('priorRespiratorUseEyeIrritationAggravatorsAtWork')}
            options={YES_NO_UNKNOWN_OPTIONS}
            value={responses.priorRespiratorUseEyeIrritationAggravatorsAtWork}
          />
        </div>
      )}
      {reqs.priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator && (
        <div className="mt-8">
          <StackedRadioField<YesNoUnknown>
            error={errors.priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator}
            label={{
              [Language.ENGLISH]: 'In your current job, are you able to use a style of respirator that does not cause you any eye irritation?',
              [Language.SPANISH]: 'En su trabajo actual, ¿puede utilizar un estilo de respirador que no le irrite los ojos?',
            }}
            onChange={setResponse('priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator')}
            options={[
              {
                label: {
                  [Language.ENGLISH]: 'Yes, I can use a respirator that is comfortable and does not cause eye irritation',
                  [Language.SPANISH]: 'Sí, puedo utilizar un respirador que me resulte cómodo y no me irrite los ojos',
                },
                value: YesNoUnknown.YES,
              },
              {
                label: {
                  [Language.ENGLISH]: 'No, the only respirator I can use causes eye irritation',
                  [Language.SPANISH]: 'No, el único respirador que puedo utilizar me irrita los ojos',
                },
                value: YesNoUnknown.NO,
              },
              {
                label: {
                  [Language.ENGLISH]: 'I don\'t know if the respirators I will use at my job will cause eye irritation',
                  [Language.SPANISH]: 'No sé si los respiradores que utilizaré en mi trabajo me irritarán los ojos',
                },
                value: YesNoUnknown.UNKNOWN,
              },
            ]}
            value={responses.priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator}
          />
        </div>
      )}
    </Card>
  );
}
