import { RadioGroup } from '@headlessui/react';

import { TranslationData } from '../../types/general';
import { SelectOption } from '../../types/inputs';

import { Translation } from '../Translation';
import { ErrorMessage } from '../ErrorMessage';

export function StackedRadioField<T extends string>({
  error,
  label,
  onChange,
  options,
  value,
}: {
  error?: TranslationData;
  label?: TranslationData;
  onChange?: (value: T) => void;
  options: SelectOption<T>[];
  value: T | null;
}) {
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div>
      <RadioGroup onChange={(option) => {
        if (onChange !== undefined && option !== null) {
          onChange(option.value);
        }
      }} value={selectedOption}>
        <>
          {label && (
            <RadioGroup.Label className={`
              ${error ? 'text-red-600 has-error' : 'text-gray-900'}
              block mb-2 font-medium leading-6
            `}>
              <Translation data={label} />
            </RadioGroup.Label>
          )}
          <div className="space-y-4">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) => `
                  ${checked ? 'border-primary ring-2 ring-primary' : 'border-gray-300'}
                  relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm
                  focus:outline-none sm:flex sm:justify-between
                `}
              >
                <RadioGroup.Label as="span">
                  <Translation data={option.label} />
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </>
      </RadioGroup>
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}
