import Drawer from '@mui/material/Drawer';
import { ReactNode, useContext } from 'react';

import { appContext } from '../contexts/appContext';

import { Attestation } from './Attestation';
import { LanguageSelector } from './LanguageSelector';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfUse } from './TermsOfUse';

export function Layout({
  children,
}: {
  children: ReactNode;
}) {
  const { drawerContent, initializer, setDrawerContent, submitting } = useContext(appContext);

  return (
    <>
      <nav className="fixed bg-white shadow w-full z-50">
        <div className="mx-auto max-w-3xl h-16 px-6 lg:px-8 flex flex-row items-center gap-x-4">
          <div className="grow">
            <img className="h-10 object-contain" src={initializer.branding.logoUrl} />
          </div>
          <LanguageSelector />
        </div>
      </nav>
      <div className="mx-auto max-w-3xl sm:px-6 lg:px-8 pt-28">
        {children}
      </div>
      <Drawer
        anchor="bottom"
        onClose={() => {
          if (!submitting) {
            setDrawerContent(null);
          }
        }}
        open={submitting || drawerContent !== null}
        sx={{
          ['& .MuiPaper-root']: {
            '--primary-color': initializer.branding.primaryColor,
            borderRadius: '10px 10px 0 0',
            maxHeight: '90vh',
          }
        }}
      >
        {drawerContent === 'tou'
          ? <TermsOfUse />
          : drawerContent === 'privacy'
            ? <PrivacyPolicy />
            : drawerContent === 'attestation'
               ? <Attestation />
               : null
        }
      </Drawer>
    </>
  );
}
