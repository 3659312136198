import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { Language, Severity, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { SEVERITY_OPTIONS, YES_NO_OPTIONS } from './options';

export function EmphysemaBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Emphysema',
      [Language.SPANISH]: 'Enfisema',
    }}>
      <InlineRadioField<YesNo>
        error={errors.emphysema}
        label={{
          [Language.ENGLISH]: 'Have you ever had emphysema?',
          [Language.SPANISH]: '¿Ha padecido alguna vez enfisema?',
        }}
        onChange={setResponse('emphysema')}
        options={YES_NO_OPTIONS}
        value={responses.emphysema}
      />
      {reqs.emphysemaSxDuringAdl && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaSxDuringAdl}
            label={{
              [Language.ENGLISH]: 'Does your emphysema bother you when you do everyday things like chores, exercising, or walking up stairs?',
              [Language.SPANISH]: '¿Le molesta la enfisema cuando hace cosas cotidianas como tareas domésticas, hacer ejercicio o subir escaleras?',
            }}
            onChange={setResponse('emphysemaSxDuringAdl')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaSxDuringAdl}
          />
        </div>
      )}
      {reqs.emphysemaWorseLastYear && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaWorseLastYear}
            label={{
              [Language.ENGLISH]: 'Has your emphysema gotten worse in the last year?',
              [Language.SPANISH]: '¿Ha empeorado su enfisema en el último año?',
            }}
            onChange={setResponse('emphysemaWorseLastYear')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaWorseLastYear}
          />
        </div>
      )}
      {reqs.emphysemaSeverity && (
        <div className="mt-8">
          <InlineRadioField<Severity>
            error={errors.emphysemaSeverity}
            label={{
              [Language.ENGLISH]: 'How bad do you and your doctor think your emphysema is?',
              [Language.SPANISH]: '¿Qué tan grave creen usted y su médico que es su enfisema?',
            }}
            onChange={setResponse('emphysemaSeverity')}
            options={SEVERITY_OPTIONS}
            value={responses.emphysemaSeverity}
          />
        </div>
      )}
      {reqs.emphysemaPriorRespiratorUseNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaPriorRespiratorUseNotOk}
            label={{
              [Language.ENGLISH]: 'Has your emphysema ever made it difficult for you to use a respirator?',
              [Language.SPANISH]: '¿Alguna vez su enfisema le ha dificultado el uso de un respirador?',
            }}
            onChange={setResponse('emphysemaPriorRespiratorUseNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaPriorRespiratorUseNotOk}
          />
        </div>
      )}
      {reqs.emphysemaFutureRespiratorUseExpectedNotOk && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaFutureRespiratorUseExpectedNotOk}
            label={{
              [Language.ENGLISH]: 'Do you think your emphysema will make it hard for you to use a respirator?',
              [Language.SPANISH]: '¿Cree que su enfisema le dificultará el uso de un respirador?',
            }}
            onChange={setResponse('emphysemaFutureRespiratorUseExpectedNotOk')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaFutureRespiratorUseExpectedNotOk}
          />
        </div>
      )}
      {reqs.emphysemaWorkAccommodations && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaWorkAccommodations}
            label={{
              [Language.ENGLISH]: 'Do you have any, or will you be requesting any, medical restrictions or accommodations for your emphysema?',
              [Language.SPANISH]: '¿Tiene o va a solicitar alguna restricción o adaptación médica por su enfisema?',
            }}
            onChange={setResponse('emphysemaWorkAccommodations')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaWorkAccommodations}
          />
        </div>
      )}
      {reqs.emphysemaMedications && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaMedications}
            label={{
              [Language.ENGLISH]: 'Do you take any rescue medicines like albuterol when you have symptoms?',
              [Language.SPANISH]: '¿Toma algún medicamento de rescate como el albuterol cuando tiene síntomas?',
            }}
            onChange={setResponse('emphysemaMedications')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaMedications}
          />
        </div>
      )}
      {reqs.emphysemaMedicationsAccessibleAtWork && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaMedicationsAccessibleAtWork}
            label={{
              [Language.ENGLISH]: 'When you\'re at work, can you get to your medicines easily and take them whenever you need to?',
              [Language.SPANISH]: 'Cuando está en el trabajo, ¿puede acceder fácilmente a sus medicamentos y tomarlos siempre que lo necesite?',
            }}
            onChange={setResponse('emphysemaMedicationsAccessibleAtWork')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaMedicationsAccessibleAtWork}
          />
        </div>
      )}
      {reqs.emphysemaHospitalStayLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaHospitalStayLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to stay in the hospital because of your emphysema?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que permanecer en el hospital a causa de su enfisema?',
            }}
            onChange={setResponse('emphysemaHospitalStayLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaHospitalStayLast2Years}
          />
        </div>
      )}
      {reqs.emphysemaImpactedWorkLast2Years && (
        <div className="mt-8">
          <InlineRadioField<YesNo>
            error={errors.emphysemaImpactedWorkLast2Years}
            label={{
              [Language.ENGLISH]: 'In the last two (2) years, have you had to leave work or stop doing a task early because of your emphysema?',
              [Language.SPANISH]: 'En los últimos dos (2) años, ¿ha tenido que dejar el trabajo o dejar de hacer una tarea antes de tiempo a causa de su enfisema?',
            }}
            onChange={setResponse('emphysemaImpactedWorkLast2Years')}
            options={YES_NO_OPTIONS}
            value={responses.emphysemaImpactedWorkLast2Years}
          />
        </div>
      )}
    </Card>
  );
}
