import { RadioGroup } from '@headlessui/react';

import { TranslationData } from '../../types/general';
import { SelectOption } from '../../types/inputs';

import { Translation } from '../Translation';
import { ErrorMessage } from '../ErrorMessage';

export function InlineRadioField<T extends string>({
  error,
  label,
  onChange,
  options,
  value,
}: {
  error?: TranslationData;
  label?: TranslationData;
  onChange?: (value: T) => void;
  options: SelectOption<T>[];
  value: T | null;
}) {
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div>
      <RadioGroup onChange={(option) => {
        if (onChange !== undefined && option !== null) {
          onChange(option.value);
        }
      }} value={selectedOption}>
        <>
          {label && (
            <RadioGroup.Label className={`
              ${error ? 'text-red-600 has-error' : 'text-gray-900'}
              block mb-2 font-medium leading-6
            `}>
              <Translation data={label} withMarkdown />
            </RadioGroup.Label>
          )}
          <div className="flex flex-row justify-start gap-3">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ active, checked }) => `
                  option-${option.value}
                  ${checked ? 'option-checked' : 'option-unchecked'}
                  cursor-pointer flex items-center justify-center rounded-md py-3 px-3 text-sm
                  font-medium uppercase
                  focus:outline-none
                  ${active ? 'ring-2 ring-primary ring-offset-2' : ''}
                  ${checked
                    ? `
                      bg-primary text-white 
                      hover:brightness-110
                    `
                    : `
                      ring-1 ring-inset ring-gray-300 bg-white text-gray-900 
                      hover:bg-gray-50
                    `
                  }
                `}
              >
                <RadioGroup.Label as="span">
                  <Translation data={option.label} />
                </RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </>
      </RadioGroup>
      {error && (
        <ErrorMessage error={error} />
      )}
    </div>
  );
}
