import { TranslationData } from '../../types/general';
import { SelectOption } from '../../types/inputs';

import { InputWrapper } from './InputWrapper';
import { Translation } from '../Translation';

export function StackedCheckboxField<T extends string>({
  error,
  label,
  notaValue,
  onChange,
  options,
  value,
}: {
  error?: TranslationData;
  label?: TranslationData;
  notaValue?: T;
  onChange: (value: T[]) => void;
  options: SelectOption<T>[];
  value: T[];
}) {
  return (
    <fieldset>
      <InputWrapper error={error} label={label}>
        <div className="space-y-4">
          {options.map((option) => {
            const checked = value.includes(option.value);

            return (
              <div
                className={`
                  ${checked ? 'border-primary ring-2 ring-primary' : 'border-gray-300'}
                  relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm
                  focus:outline-none sm:flex sm:justify-between
                `}
                key={option.value}
                onClick={() => {
                  if (option.value === notaValue) {
                    if (value.length === 1 && value[0] === notaValue) {
                      onChange([]);
                    } else {
                      onChange([option.value]);
                    }
                  } else if (!checked) {
                    onChange([...value, option.value].filter((val) => val !== notaValue));
                  } else {
                    onChange(value.filter((val) => val !== option.value));
                  }
                }}
              >
                <Translation data={option.label} />
              </div>
            );
          })}
        </div>
      </InputWrapper>
    </fieldset>
  );
}
