import { Card } from '../../components/Card';
import { InlineRadioField } from '../../components/inputs/InlineRadioField';
import { TextAreaField } from '../../components/inputs/TextAreaField';
import { Language, YesNo } from '../../lib/general';
import {
  HealthHistoryStageErrors,
  HealthHistoryStageRequirements,
  HealthHistoryStageResponses,
} from '../../types/general';

import { FREEFORM_MAX_LENGTH, YES_NO_OPTIONS } from './options';

export function PriorRespiratorUseOtherProblemsBlock({
  errors,
  reqs,
  responses,
  setResponse,
}: {
  errors: HealthHistoryStageErrors;
  reqs: HealthHistoryStageRequirements;
  responses: HealthHistoryStageResponses;
  setResponse: (
    key: keyof HealthHistoryStageResponses,
  ) => (value: string | string[] | number | null) => void;
}) {
  return (
    <Card title={{
      [Language.ENGLISH]: 'Other Respirator Problems',
      [Language.SPANISH]: 'Otros Problemas del Respirador',
    }}>
      <InlineRadioField<YesNo>
        error={errors.priorRespiratorUseMiscProblems}
        label={{
          [Language.ENGLISH]: 'Has using a respirator ever caused you any other problems not mentioned above?',
          [Language.SPANISH]: '¿El uso de un respirador le ha causado algún otro problema no mencionado anteriormente?',
        }}
        onChange={setResponse('priorRespiratorUseMiscProblems')}
        options={YES_NO_OPTIONS}
        value={responses.priorRespiratorUseMiscProblems}
      />
      {reqs.priorRespiratorUseMiscProblemsDescription && (
        <div className="mt-8">
          <TextAreaField
            error={errors.priorRespiratorUseMiscProblemsDescription}
            label={{
              [Language.ENGLISH]: 'Please provide more details about the problem(s) that you\'ve had while using a respirator:',
              [Language.SPANISH]: 'Por favor, proporcione más detalles sobre el/los problema(s) que ha tenido mientras utilizaba un respirador:',
            }}
            maxLength={FREEFORM_MAX_LENGTH}
            name="priorRespiratorUseMiscProblemsDescription"
            onChange={setResponse('priorRespiratorUseMiscProblemsDescription')}
            value={responses.priorRespiratorUseMiscProblemsDescription}
          />
        </div>
      )}
    </Card>
  );
}
